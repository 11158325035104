import React, { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth, firestore } from "../../firebase"; // Ensure correct imports
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const url = window.location.href; // Capture the full URL
      if (!isSignInWithEmailLink(auth, url)) {
        console.error("Invalid email verification link.");
        navigate("/login");
        return;
      }

      let email = localStorage.getItem("emailForSignIn");
      if (!email) {
        email = prompt("Please enter your email to verify:");
      }

      if (!email) {
        console.error("No email entered.");
        navigate("/login");
        return;
      }

      try {
        const result = await signInWithEmailLink(auth, email, url);
        localStorage.removeItem("emailForSignIn"); // Clean up

        // ✅ Check if user already exists in Firestore
        const userDocRef = doc(firestore, "users", result.user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // ✅ Register user if they don't exist
          await setDoc(userDocRef, {
            userId: result.user.uid,
            email: result.user.email,
            role: "student",
            createdAt: serverTimestamp(),
          });
        }

        console.log("Email verified and user signed in:", result.user);
        navigate("/user-dashboard/dashboard"); // Redirect after success
      } catch (error) {
        console.error("Email verification failed:", error);
        navigate("/login");
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <h1 className="text-xl font-bold">Verifying your email...</h1>
    </div>
  );
};

export default EmailVerification;
