import React from "react";
import { useNavigate } from "react-router-dom";

const ProfileUpdateDialog = ({ closeDialog }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center text-white">
        <h2 className="text-xl font-bold mb-4">Profile Update Required</h2>
        <p className="mb-6">
          Your profile information is incomplete. Please update your profile to
          continue using the platform.
        </p>

        <div className="flex justify-center">
          <button
            onClick={() => {
              closeDialog();
              navigate("/user-dashboard/profile");
            }}
            className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 w-full"
          >
            Update Profile Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdateDialog;
