import React, { memo } from "react";
const UniversityCard = ({ university, onClick }) => (
  <div
    className="bg-gray-800 rounded-md overflow-hidden cursor-pointer shadow-md hover:scale-105 transition duration-300"
    onClick={() => onClick(university.id)}
    style={{ maxWidth: "250px" }}
  >
    <div
      className="relative h-40"
      style={{
        backgroundImage: `url(${university.background_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex h-16 w-16 items-center bg-white bottom-3 left-3 content-center absolute rounded-md">
        <img
          src={university.logo_image || university.logo_url}
          className="max-w-full h-auto"
          alt={university.name || university.university_name}
        />
      </div>
    </div>
    <div className="p-3">
      <h3 className="text-sm font-semibold mb-1 text-white">
        {university.name || university.university_name}
      </h3>
      <p className="text-xs text-gray-300 mb-1">{university.location}</p>
    </div>
  </div>
);

export default memo(UniversityCard);
