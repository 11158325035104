import React from "react";
import {
  Briefcase,
  Home,
  Settings,
  PlusCircle,
  List,
  User,
  GraduationCap,
} from "lucide-react";
import { Link, useLocation, Outlet } from "react-router-dom";

const UniversityLayout = ({ children }) => {
  const location = useLocation();

  const menuItems = [
    {
      path: "/business/university/dashboard",
      icon: Home,
      label: "Dashboard",
      disabled: false,
    },
    {
      path: "/business/university/upload/info",
      icon: PlusCircle,
      label: "Upload Data",
      disabled: false,
    },
    {
      path: "/business/university/add/courses",
      icon: List,
      label: "Courses",
      disabled: false,
    },
    {
      path: "/business/university/profile",
      icon: User,
      label: "Profile",
      disabled: false,
    },
    {
      path: "/business/university/settings",
      icon: Settings,
      label: "Settings",
      disabled: false,
    },
  ];

  const renderMenuItem = ({ path, icon: Icon, label, disabled }) =>
    disabled ? (
      <div
        key={path}
        className={`flex items-center space-x-3 text-white p-2 rounded cursor-pointer no-underline opacity-50`}
      >
        <Icon size={20} />
        <span>{label}</span>
      </div>
    ) : (
      <Link
        to={path}
        key={path}
        className={`flex items-center space-x-3 text-white hover:bg-gray-700 p-2 rounded cursor-pointer no-underline ${
          location.pathname === path ? "bg-gray-700" : ""
        }`}
      >
        <Icon size={20} />
        <span>{label}</span>
      </Link>
    );

  return (
    <div className="flex bg-gradient-to-br from-gray-900 to-gray-800 min-h-screen text-white justify-center items-center p-8 w-full h-full">
      <div className="flex gap-8 w-full h-full">
        {/* Sidebar */}
        <div className="hidden lg:flex flex-col justify-between h-1/2 w-64 bg-gray-800 border-r border-gray-700 rounded-xl shadow-lg">
          <div className="p-6 space-y-6">
            <div className="flex items-center space-x-3 mb-8">
              <GraduationCap className="text-blue-400" size={24} />
              <h1 className="text-xl font-bold">Ambassador Menu</h1>
            </div>
            <nav className="space-y-2">{menuItems.map(renderMenuItem)}</nav>
          </div>
          <div className="p-6">
            <div className="bg-gray-700 rounded-lg p-4 text-center">
              <p className="text-sm mb-2">Need Help?</p>
              <button className="w-full bg-blue-600 hover:bg-blue-500 text-white py-2 rounded">
                Contact Support
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full h-full max-w-6xl  bg-gray-800 rounded-xl shadow-lg ">
          {/* {children} */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UniversityLayout;
