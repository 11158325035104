import React from "react";

const CustomDropdown = ({
  options = [],
  value,
  onChange,
  placeholder = "Select an option",
  disabledOptions = [],
  className = "",
  labelText = "Select an option",
}) => {
  return (
    <div className="relative w-full">
      {/* Label */}
      {/* <label
        className={`absolute left-3 top-1.5 text-xs text-gray-400 transition-all duration-200 
          ${
            value ? "top-0 text-xxs text-orange-500" : "top-1.5 text-xs"
          } peer-focus:top-0 peer-focus:text-xxs peer-focus:text-orange-500`}
      >
        {labelText}
      </label> */}

      {/* Dropdown */}
      <select
        value={value}
        onChange={onChange}
        className={`block w-full px-3 pt-2 pb-1 bg-gray-800 text-white text-sm border-2 border-orange-600 rounded-md 
          focus:outline-none focus:border-orange-500 transition peer appearance-none ${className}`}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option
            key={index}
            value={option}
            disabled={disabledOptions.includes(option)}
            className="text-white-200"
          >
            {option}
          </option>
        ))}
      </select>

      {/* Dropdown Arrow */}
      <div className="absolute right-2 top-2 pointer-events-none">▼</div>
    </div>
  );
};

export default CustomDropdown;
