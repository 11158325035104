import React, { useMemo } from "react";
import hiwipay from "../../../assets/img/hiwipay.png";
import bombaydreamz from "../../../assets/img/bombayDreamz.png";
import carnival from "../../../assets/img/carnival.png";
import angelsden from "../../../assets/img/angelsden.png";
import insalogo from "../../../assets/img/insalogo.png";
import Mdx from "../../../assets/img/Mdx.png";
import royal from "../../../assets/img/royal_holloway.png";
import haldiram from "../../../assets/img/haldirams-logo.svg";
import Iq from "../../../assets/img/Iq_accom.png";
const BrandLogos = () => {
  const brandLogos = useMemo(
    () => [
      {
        name: "Haldiram's",
        url: haldiram,
      },
      {
        name: "IQ Accommodation",
        url: "https://cdn.vev.design/cdn-cgi/image/f=auto,q=82/private/wdG2LOUIzDTnIMUgxImJ1osjCnp2/image/vOvua_hocA.png",
      },
      { name: "HiwiPay", url: hiwipay },
      { name: "BombayDreamz", url: bombaydreamz },
      { name: "Carnival", url: carnival },
      { name: "angelsden", url: angelsden },
      { name: "insalogo", url: insalogo },
      { name: "mdx", url: Mdx },
      { name: "royal holloway", url: royal },
    ],
    []
  );

  return (
    <div className="marquee">
      <div className="marquee-content">
        {brandLogos.concat(brandLogos).map((brand, index) => (
          <img
            key={index}
            src={brand.url}
            alt={brand.name}
            className="mx-8 h-16 object-contain"
          />
        ))}
      </div>
    </div>
  );
};

export default BrandLogos;
