import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const MID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
ReactGA.initialize(MID);

const extractEntityInfo = (path) => {
  const patterns = [
    { type: "university", regex: /\/user-dashboard\/universities\/([^/]+)/ },
    { type: "job", regex: /\/user-dashboard\/jobs\/([^/]+)/ },
    {
      type: "accommodation",
      regex: /\/user-dashboard\/accommodation\/([^/]+)/,
    },
    { type: "event", regex: /\/user-dashboard\/eventDetails\/([^/]+)/ }
  ];
  for (const { type, regex } of patterns) {
    const match = path.match(regex);
    if (match) return { entityType: type, entityId: match[1] };
  }
  return { entityType: null, entityId: null };
};

export function AnalyticsTracker() {
  const location = useLocation();
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.warn("🔒 User is not authenticated. Skipping analytics logging.");
      return;
    }
    const pagePath = location.pathname;
    ReactGA.send({ hitType: "pageview", page: pagePath });

    const { entityType, entityId } = extractEntityInfo(pagePath);

    console.log(`Tracking pageview for: ${pagePath}`);
    console.log(`Entity Type: ${entityType}`);
    console.log(`Entity ID: ${entityId}`);

    if (entityType === "university" && entityId) {
      const pageviewsRef = collection(
        db,
        `analytics/universitiesMeta/universities/${entityId}/pageviews`
      );

      addDoc(pageviewsRef, {
        pagePath,
        timestamp: new Date().toISOString(),
        referrer: document.referrer || "direct",
        userAgent: navigator.userAgent,
      })
        .then(() =>
          console.log(`✅ Pageview logged for university ${entityId}`)
        )
        .catch((err) =>
          console.error(
            `❌ Failed to log pageview for university ${entityId}:`,
            err
          )
        );
    }

    if (entityType === "event" && entityId) {
      const pageviewsRef = collection(
        db,
        `analytics/eventsMeta/events/${entityId}/pageviews`
      );

      addDoc(pageviewsRef, {
        pagePath,
        timestamp: new Date().toISOString(),
        referrer: document.referrer || "direct",
        userAgent: navigator.userAgent,
        deviceType: navigator.platform,
        userId: user.uid,
        sessionId: sessionStorage.getItem("sessionId") || 
          (sessionStorage.setItem("sessionId", crypto.randomUUID()),
          sessionStorage.getItem("sessionId"))
      })
        .then(() =>
          console.log(`✅ Pageview logged for event ${entityId}`)
        )
        .catch((err) =>
          console.error(
            `❌ Failed to log pageview for event ${entityId}:`,
            err
          )
        );
    }
  }, [location]);

  return null;
}
