import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../../../firebase';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  Eye,
  Users,
  Clock,
  Loader,
  ChevronDown,
  ChevronUp,
  ArrowUpDown,
  Ticket,
  CreditCard,
  Map,
  Globe,
  Monitor,
  User
} from 'lucide-react';
import { useAuth } from '../../../auth/userProvider/AuthProvider';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Analytics = () => {
  const { userData } = useAuth();
  const [eventId, setEventId] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({
    pageviews: [],
    ticketInteractions: [],
    purchaseInteractions: [],
    venueInteractions: [],
    navigationInteractions: [],
    eventDetails: null,
    totalViews: 0,
    totalTicketSelections: 0,
    totalPurchaseAttempts: 0,
    totalMapOpens: 0,
    deviceTypes: {},
    userLocations: {},
    userDemographics: {
      ageGroups: {},
      genders: {},
      countries: {},
      cities: {},
      languages: {}
    },
    purchaseStatus: {
      initiated: 0,
      success: 0,
      failed: 0
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchEventId = async () => {
      console.log('🔍 Fetching event for user:', userData?.userId);
      if (!userData?.userId) {
        console.warn('⚠️ No user data available');
        return;
      }

      try {
        const eventsQuery = query(
          collection(firestore, "events"),
          where("organizerId", "==", userData.userId)
        );

        const querySnapshot = await getDocs(eventsQuery);
        
        if (!querySnapshot.empty) {
          const eventDoc = querySnapshot.docs[0];
          const eventId = eventDoc.id;
          console.log('✅ Found event ID:', eventId);
          setEventId(eventId);
        } else {
          console.warn('⚠️ No events found for this user');
          setError('No events found for this user');
          setLoading(false);
        }
      } catch (err) {
        console.error('❌ Error fetching event:', err);
        setError('Failed to fetch event data: ' + err.message);
        setLoading(false);
      }
    };

    fetchEventId();
  }, [userData]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      console.log('🚀 Starting fetchAnalytics for eventId:', eventId);
      if (!eventId) {
        console.warn('⚠️ No eventId available, skipping fetch');
        return;
      }

      setLoading(true);
      try {
        const analyticsTypes = [
          'pageviews',
          'ticketInteractions',
          'purchaseInteractions',
          'venueInteractions',
          'navigationInteractions'
        ];

        console.log('📊 Attempting to fetch data for types:', analyticsTypes);
        const data = {};
        
        for (const type of analyticsTypes) {
          console.log(`📥 Fetching ${type}...`);
          const collectionPath = `analytics/eventsMeta/events/${eventId}/${type}`;
          console.log('Collection path:', collectionPath);
          
          const collectionRef = collection(firestore, collectionPath);
          const snapshot = await getDocs(collectionRef);
          
          data[type] = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          console.log(`✅ Fetched ${type}:`, {
            count: data[type].length,
            sample: data[type][0]
          });
        }

        console.log('🔄 Processing pageviews...');
        const deviceTypes = data.pageviews.reduce((acc, view) => {
          const device = view.deviceType || 'Unknown';
          acc[device] = (acc[device] || 0) + 1;
          return acc;
        }, {});
        console.log('📱 Device types:', deviceTypes);

        console.log('🔄 Processing user locations...');
        const userLocations = data.pageviews.reduce((acc, view) => {
          if (view.country) {
            acc[view.country] = (acc[view.country] || 0) + 1;
          }
          return acc;
        }, {});
        console.log('🌍 User locations:', userLocations);

        console.log('🔄 Processing user demographics...');
        const userDemographics = data.pageviews.reduce((acc, view) => {
          // Age groups
          if (view.age) {
            const ageGroup = getAgeGroup(view.age);
            acc.ageGroups[ageGroup] = (acc.ageGroups[ageGroup] || 0) + 1;
          }

          // Gender
          if (view.gender) {
            acc.genders[view.gender] = (acc.genders[view.gender] || 0) + 1;
          }

          // Countries
          if (view.country) {
            acc.countries[view.country] = (acc.countries[view.country] || 0) + 1;
          }

          // Cities
          if (view.city) {
            acc.cities[view.city] = (acc.cities[view.city] || 0) + 1;
          }

          // Languages
          if (view.language) {
            acc.languages[view.language] = (acc.languages[view.language] || 0) + 1;
          }

          return acc;
        }, {
          ageGroups: {},
          genders: {},
          countries: {},
          cities: {},
          languages: {}
        });

        console.log('🔄 Processing purchase interactions...');
        const purchaseStatus = data.purchaseInteractions.reduce((acc, interaction) => {
          if (interaction.status) {
            acc[interaction.status] = (acc[interaction.status] || 0) + 1;
          }
          return acc;
        }, { initiated: 0, success: 0, failed: 0 });
        console.log('💳 Purchase statuses:', purchaseStatus);

        const analyticsDataToSet = {
          ...data,
          deviceTypes,
          userLocations,
          userDemographics,
          purchaseStatus,
          totalViews: data.pageviews.length,
          totalTicketSelections: data.ticketInteractions.length,
          totalPurchaseAttempts: data.purchaseInteractions.length,
          totalMapOpens: data.venueInteractions.length
        };

        console.log('📊 Setting analytics data:', {
          totalViews: analyticsDataToSet.totalViews,
          totalTicketSelections: analyticsDataToSet.totalTicketSelections,
          totalPurchaseAttempts: analyticsDataToSet.totalPurchaseAttempts,
          totalMapOpens: analyticsDataToSet.totalMapOpens
        });

        setAnalyticsData(analyticsDataToSet);
        console.log('✅ Data fetch and processing complete');

      } catch (err) {
        console.error('❌ Error in fetchAnalytics:', err);
        console.error('Error details:', {
          message: err.message,
          code: err.code,
          stack: err.stack
        });
        setError('Failed to load analytics data: ' + err.message);
      } finally {
        console.log('🏁 Setting loading to false');
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [eventId]);

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (e) {
      return dateString;
    }
  };

  const getAgeGroup = (age) => {
    if (age < 18) return 'Under 18';
    if (age < 25) return '18-24';
    if (age < 35) return '25-34';
    if (age < 45) return '35-44';
    if (age < 55) return '45-54';
    return '55+';
  };

  const prepareChartData = () => {
    console.log('📈 Preparing chart data with:', {
      pageviewsCount: analyticsData.pageviews.length,
      ticketInteractionsCount: analyticsData.ticketInteractions.length
    });

    const dates = analyticsData.pageviews.map(view => 
      new Date(view.timestamp).toLocaleDateString()
    );
    const uniqueDates = [...new Set(dates)];
    const pageviewsByDate = uniqueDates.map(date => 
      dates.filter(d => d === date).length
    );

    const activityData = {
      labels: uniqueDates,
      datasets: [
        {
          label: 'Page Views',
          data: pageviewsByDate,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        },
        {
          label: 'Ticket Selections',
          data: uniqueDates.map(date => 
            analyticsData.ticketInteractions.filter(
              interaction => new Date(interaction.timestamp).toLocaleDateString() === date
            ).length
          ),
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1
        }
      ]
    };

    const purchaseData = {
      labels: ['Initiated', 'Successful', 'Failed'],
      datasets: [{
        data: [
          analyticsData.purchaseStatus.initiated,
          analyticsData.purchaseStatus.success,
          analyticsData.purchaseStatus.failed
        ],
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)'
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'
        ]
      }]
    };

    const deviceData = {
      labels: Object.keys(analyticsData.deviceTypes),
      datasets: [{
        data: Object.values(analyticsData.deviceTypes),
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(153, 102, 255, 1)'
        ]
      }]
    };

    return { activityData, purchaseData, deviceData };
  };

  console.log('🔄 Component render state:', {
    loading,
    error,
    dataAvailable: Boolean(analyticsData.pageviews.length),
    expandedSection
  });

  if (loading) {
    console.log('⌛ Rendering loading state');
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <div className="animate-spin">
          <Loader className="h-10 w-10 text-blue-500" />
        </div>
        <p className="text-gray-400">Loading analytics data...</p>
      </div>
    );
  }

  if (error) {
    console.log('❌ Rendering error state:', error);
    return <div className="text-red-500 p-4">{error}</div>;
  }

  const { activityData, purchaseData, deviceData } = prepareChartData();

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-8 text-gray-200">Event Analytics</h1>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        <div className="bg-gray-800 rounded-lg p-4 flex items-center space-x-4">
          <div className="p-3 bg-blue-900 rounded-full">
            <Eye className="h-6 w-6 text-blue-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Total Views</p>
            <p className="text-2xl font-bold text-white">{analyticsData.totalViews}</p>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 flex items-center space-x-4">
          <div className="p-3 bg-green-900 rounded-full">
            <Ticket className="h-6 w-6 text-green-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Ticket Selections</p>
            <p className="text-2xl font-bold text-white">{analyticsData.totalTicketSelections}</p>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 flex items-center space-x-4">
          <div className="p-3 bg-yellow-900 rounded-full">
            <CreditCard className="h-6 w-6 text-yellow-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Purchase Attempts</p>
            <p className="text-2xl font-bold text-white">{analyticsData.totalPurchaseAttempts}</p>
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 flex items-center space-x-4">
          <div className="p-3 bg-purple-900 rounded-full">
            <Map className="h-6 w-6 text-purple-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Map Opens</p>
            <p className="text-2xl font-bold text-white">{analyticsData.totalMapOpens}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-200">Activity Over Time</h2>
          <Line data={activityData} options={{
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                grid: { color: 'rgba(255, 255, 255, 0.1)' },
                ticks: { color: 'rgba(255, 255, 255, 0.7)' }
              },
              x: {
                grid: { color: 'rgba(255, 255, 255, 0.1)' },
                ticks: { color: 'rgba(255, 255, 255, 0.7)' }
              }
            },
            plugins: {
              legend: {
                labels: { color: 'rgba(255, 255, 255, 0.7)' }
              }
            }
          }} />
        </div>

        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-200">Purchase Status Distribution</h2>
          <Pie data={purchaseData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
                labels: { color: 'rgba(255, 255, 255, 0.7)' }
              }
            }
          }} />
        </div>

        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-4 text-gray-200">Device Distribution</h2>
          <Pie data={deviceData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
                labels: { color: 'rgba(255, 255, 255, 0.7)' }
              }
            }
          }} />
        </div>
      </div>

      <div className="space-y-8">

        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-700 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-200">Purchase Attempts</h2>
            <button 
              onClick={() => setExpandedSection(expandedSection === 'purchases' ? null : 'purchases')}
              className="text-gray-400 hover:text-white"
            >
              {expandedSection === 'purchases' ? <ChevronUp /> : <ChevronDown />}
            </button>
          </div>
          
          {expandedSection === 'purchases' && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-gray-700">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Timestamp
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {analyticsData.purchaseInteractions.slice(0, 10).map((purchase, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {formatDate(purchase.timestamp)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${purchase.status === 'success' ? 'bg-green-100 text-green-800' : 
                            purchase.status === 'failed' ? 'bg-red-100 text-red-800' : 
                            'bg-yellow-100 text-yellow-800'}`}>
                          {purchase.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        £{purchase.totalAmount || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;