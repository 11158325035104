export const courses = [
  {
    "id": 1,
    "title": "SAT Prep: Test Overview and Study Tips",
    "instructor": "Khan Academy",
    "rating": 4.7,
    "price": 0.00,
    "category": "Test Preparation",
    "image": "https://i.ytimg.com/vi/TnWG6709flU/hq720.jpg?sqp=-oaymwEnCNAFEJQDSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLBL9lxnqmO8yTdyIGCtmzchlTMsAA",
    "videoUrl": "https://www.youtube.com/watch?v=TnWG6709flU",
    "description": "Comprehensive SAT preparation covering all sections, strategies, and time management tips.",
    "resources": [
      { "name": "SAT Official Practice", "url": "https://collegereadiness.collegeboard.org/sat/practice" },
      { "name": "SAT Study Guide", "url": "https://www.khanacademy.org/test-prep/sat" }
    ],
    "reviews": [
      { "user": "Alice", "comment": "Very detailed and helpful!", "rating": 5 },
      { "user": "Bob", "comment": "Great tips for time management.", "rating": 4 }
    ],
    "duration": "10 hours",
    "level": "Beginner",
    "tags": ["SAT", "Test Preparation", "College Admission"]
  },
  {
    "id": 2,
    "title": "GRE Verbal Reasoning: Tips for Success",
    "instructor": "Magoosh",
    "rating": 4.6,
    "price": 0.00,
    "category": "Test Preparation",
    "image": "https://i.ytimg.com/vi/9E3USLcAAFo/hq720.jpg?sqp=-oaymwEnCNAFEJQDSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLCzHnQFx3QSjqDl6I5lxvZIQGJJNg",
    "videoUrl": "https://www.youtube.com/watch?v=9E3USLcAAFo",
    "description": "Strategies and tips to ace the GRE Verbal Reasoning section, including reading comprehension and vocabulary building.",
    "resources": [
      { "name": "GRE Official Guide", "url": "https://www.ets.org/gre/test-takers/general-test/prepare.html" },
      { "name": "Magoosh GRE Prep", "url": "https://magoosh.com/gre/" }
    ],
    "reviews": [
      { "user": "Charlie", "comment": "Helped me boost my score!", "rating": 5 },
      { "user": "David", "comment": "Concise and effective strategies.", "rating": 4 }
    ],
    "duration": "8 hours",
    "level": "Intermediate",
    "tags": ["GRE", "Test Preparation", "Graduate Admission"]
  },
  {
    "id": 3,
    "title": "IELTS Speaking: How to Improve Your Fluency",
    "instructor": "BBC Learning English",
    "rating": 4.8,
    "price": 0.00,
    "category": "Test Preparation",
    "image": "https://i.ytimg.com/vi/oZY3YP9qZ4U/hq720.jpg?sqp=-oaymwEnCNAFEJQDSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLCegfWgZSWP8R_ezPUfDdjugDZ6Kw",
    "videoUrl": "https://www.youtube.com/watch?v=oZY3YP9qZ4U",
    "description": "Learn fluency techniques and strategies for achieving a high band score in the IELTS Speaking test.",
    "resources": [
      { "name": "IELTS Official Website", "url": "https://www.ielts.org/" },
      { "name": "Cambridge IELTS Practice Tests", "url": "https://www.cambridgeenglish.org/exams-and-tests/ielts/preparation/" }
    ],
    "reviews": [
      { "user": "Emma", "comment": "Really helped with fluency!", "rating": 5 },
      { "user": "Frank", "comment": "Great practice exercises.", "rating": 4 }
    ],
    "duration": "12 hours",
    "level": "Advanced",
    "tags": ["IELTS", "Test Preparation", "English Proficiency"]
  },
  {
    "id": 4,
    "title": "TOEFL Writing: Integrated Task Tips",
    "instructor": "ETS Official",
    "rating": 4.5,
    "price": 0.00,
    "category": "Test Preparation",
    "image": "https://i.ytimg.com/vi/sv0xWV-_5u0/hq720.jpg?sqp=-oaymwEnCNAFEJQDSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLD1qR1Jq_BPkAAd4yGXVsDpNbfLtQ",
    "videoUrl": "https://www.youtube.com/watch?v=sv0xWV-_5u0",
    "description": "Step-by-step guide to mastering the TOEFL Writing Integrated Task with sample answers.",
    "resources": [
      { "name": "TOEFL Official Guide", "url": "https://www.ets.org/toefl/test-takers/ibt/prepare.html" },
      { "name": "TOEFL Sample Questions", "url": "https://www.ets.org/toefl/test-takers/ibt/prepare/sample-questions.html" }
    ],
    "reviews": [
      { "user": "George", "comment": "Easy-to-follow explanations!", "rating": 5 },
      { "user": "Helen", "comment": "Made writing much easier.", "rating": 4 }
    ],
    "duration": "6 hours",
    "level": "Intermediate",
    "tags": ["TOEFL", "Test Preparation", "English Proficiency"]
  },
  {
    "id": 5,
    "title": "UCAS Application Process | How to Apply to UK Universities",
    "instructor": "Study in UK",
    "rating": 4.8,
    "price": 0.00,
    "category": "Study Abroad Essentials",
    "image": "https://i.ytimg.com/vi/JTcbMgHrkHk/maxresdefault.jpg",
    "videoUrl": "https://www.youtube.com/watch?v=JTcbMgHrkHk",
    "description": "A comprehensive walkthrough of the UCAS application process for prospective students aiming to study in the UK.",
    "resources": [
      { "name": "UCAS Official Guide", "url": "https://www.ucas.com/undergraduate/applying-to-university" },
      { "name": "Personal Statement Tips", "url": "https://www.ucas.com/undergraduate/applying-university/how-write-ucas-undergraduate-personal-statement" }
    ],
    "reviews": [
      { "user": "Alice", "comment": "This video made the application process so much clearer!", "rating": 5 },
      { "user": "Bob", "comment": "Very detailed and easy to follow.", "rating": 4 }
    ],
    "duration": "5 hours",
    "level": "Beginner",
    "tags": ["UCAS", "Study Abroad", "University Application"]
  },
  {
    "id": 6,
    "title": "Step-by-Step UK Student Visa Application Process",
    "instructor": "Visa and Immigration",
    "rating": 4.7,
    "price": 0.00,
    "category": "Study Abroad Essentials",
    "image": "https://i.ytimg.com/vi/QozUBMfFYKY/hqdefault.jpg?sqp=-oaymwEnCOADEI4CSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLAP3R2STlFi694_Rh99BZBMCHZotA",
    "videoUrl": "https://www.youtube.com/watch?v=QozUBMfFYKY",
    "description": "Detailed guidance on navigating the UK student visa application, from document preparation to submission.",
    "resources": [
      { "name": "UK Government Student Visa Information", "url": "https://www.gov.uk/student-visa" },
      { "name": "UKCISA: Student Visa Advice", "url": "https://www.ukcisa.org.uk/Information--Advice/Visas-and-Immigration/Student-route" }
    ],
    "reviews": [
      { "user": "Charlie", "comment": "Helped me successfully get my visa without any issues.", "rating": 5 },
      { "user": "David", "comment": "Clear instructions and very informative.", "rating": 4 }
    ],
    "duration": "4 hours",
    "level": "Beginner",
    "tags": ["Visa", "Study Abroad", "Immigration"]
  },
  {
    "id": 7,
    "title": "How to Prepare for a UK Job Interview",
    "instructor": "Career Coach",
    "rating": 4.6,
    "price": 0.00,
    "category": "Job Readiness & Career Development",
    "image": "https://i.ytimg.com/vi/9akrD0AxAME/maxresdefault.jpg",
    "videoUrl": "https://www.youtube.com/watch?v=9akrD0AxAME",
    "description": "Essential tips and strategies to excel in UK job interviews, covering common questions and cultural nuances.",
    "resources": [
      { "name": "National Careers Service: Interview Tips", "url": "https://nationalcareers.service.gov.uk/careers-advice/interview-advice" },
      { "name": "Indeed: Interview Preparation Guide", "url": "https://uk.indeed.com/career-advice/interviewing" }
    ],
    "reviews": [
      { "user": "Emma", "comment": "Gave me the confidence to ace my interview!", "rating": 5 },
      { "user": "Frank", "comment": "Very practical advice, highly recommend.", "rating": 4 }
    ],
    "duration": "3 hours",
    "level": "Intermediate",
    "tags": ["Job Interview", "Career Development", "Job Readiness"]
  },
  {
    "id": 8,
    "title": "How to Write a CV for the UK Job Market",
    "instructor": "JobSearch Guru",
    "rating": 4.7,
    "price": 0.00,
    "category": "Job Readiness & Career Development",
    "image": "https://i.ytimg.com/vi/U9t4lREXbY0/hqdefault.jpg?sqp=-oaymwEnCOADEI4CSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLBx9SXBIeXefRBACEmU9gCs2osGIw",
    "videoUrl": "https://www.youtube.com/watch?v=U9t4lREXbY0",
    "description": "Learn how to craft a compelling CV tailored for UK employers, highlighting key sections and formatting tips.",
    "resources": [
      { "name": "Prospects: CV Writing Tips", "url": "https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters" },
      { "name": "Reed: How to Write a CV", "url": "https://www.reed.co.uk/career-advice/how-to-write-a-cv/" }
    ],
    "reviews": [
      { "user": "Grace", "comment": "This video helped me revamp my CV and land interviews.", "rating": 5 },
      { "user": "Henry", "comment": "Clear guidelines and very practical advice.", "rating": 4 }
    ],
    "duration": "2 hours",
    "level": "Beginner",
    "tags": ["CV Writing", "Career Development", "Job Readiness"]
  },
  {
    "id": 9,
    "title": "Understanding UK Workplace Culture",
    "instructor": "Workplace Insights",
    "rating": 4.5,
    "price": 0.00,
    "category": "Cultural Adaptation",
    "image": "https://i.ytimg.com/vi/C0NKDAyZ7Oo/hqdefault.jpg?sqp=-oaymwEnCOADEI4CSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLCcr3OAKJsf0eP-V9FawqQ26FhBvA",
    "videoUrl": "https://www.youtube.com/watch?v=C0NKDAyZ7Oo",
    "description": "An overview of the key aspects of workplace culture in the UK, including communication styles, hierarchy, and etiquette.",
    "resources": [
      { "name": "Expatica: Working in the UK", "url": "https://www.expatica.com/uk/working/employment-basics/working-in-the-uk-106166/" },
      { "name": "British Council: Workplace Etiquette", "url": "https://learnenglish.britishcouncil.org/business-english/youre-hired/episode-01/workplace-etiquette" }
    ],
    "reviews": [
      { "user": "Ivy", "comment": "Gave me a solid understanding of what to expect in UK offices.", "rating": 5 },
      { "user": "Jack", "comment": "Very informative, helped me adjust smoothly.", "rating": 4 }
    ],
    "duration": "3 hours",
    "level": "Intermediate",
    "tags": ["Workplace Culture", "Cultural Adaptation", "Career Development"]
  },
  {
    "id": 10,
    "title": "Artificial Intelligence: Principles and Techniques",
    "instructor": "Stanford University",
    "rating": 4.9,
    "price": 0.00,
    "category": "Artificial Intelligence",
    "image": "https://online.stanford.edu/sites/default/files/styles/widescreen_large/public/2022-11/Artificial-Intelligence-Principles-and-Techniques_CS221.jpg?h=2c365730&itok=NBD0tGDq",
    "videoUrl": "https://www.youtube.com/watch?v=aircAruvnKk",
    "description": "This course provides a comprehensive introduction to the foundational concepts and techniques of Artificial Intelligence, including search algorithms, knowledge representation, and machine learning.",
    "resources": [
      {"name": "Course Page", "url": "https://online.stanford.edu/courses/cs221-artificial-intelligence-principles-and-techniques"},
      {"name": "Lecture Slides", "url": "https://see.stanford.edu/materials/aimlcs229/handouts.aspx"}
    ],
    "reviews": [
      {"user": "Sophia", "comment": "An excellent course that covers both theoretical and practical aspects of AI.", "rating": 5 },
      {"user": "Liam", "comment": "The instructors explain complex topics in an accessible manner.", "rating": 4 }
    ],
    "duration": "10 weeks",
    "level": "Advanced",
    "tags": ["Artificial Intelligence", "Machine Learning", "Stanford University"]
  },
];