import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, firestore } from "../../../firebase";
// Import from your AuthProvider
import { useAuth } from "../../../auth/userProvider/AuthProvider";
// Animations
import gsap from "gsap";
import lottie from "lottie-web";
import animationData from "../../../assets/lotties/students.json";
// Icons / Images
import googleSvg from "../../../assets/img/googleSvg.svg";
import linkedin from "../../../assets/img/linkedin.svg";
import meta from "../../../assets/img/meta.svg";
import LoginVector from "../../../assets/img/Authentication-rafiki 1.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
// Firebase
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  RecaptchaVerifier,
} from "firebase/auth";
import {
  getDoc,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
// Utils
import {
  validateEmail,
  validatePasswordStrength,
} from "../../../utils/validators";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const Login_Test = () => {
  // We can destructure from our useAuth hook
  const {
    currentUser,
    userRole,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword: signInFn,
    signInWithGoogle: signInGoogleFn,
    resetPassword,
    logout,
    loading,
    signInWithPhone,
    verifyOTP,
    sendEmailVerificationLink,
    linkEmailToPhoneAccount,
    checkPhoneNumberExists,
  } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const container = useRef(null);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  // Local state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignUpForm, setShowSignUpForm] = useState(
    location.state?.signup || true
  );
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Add these near your other state declarations
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState("email"); // Default to email verification
  const [isEmailSent, setIsEmailSent] = useState(false); // Track email verification status

  // For sign-up
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    showPassword: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOTPsent, setIsOTPsent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false); // ✅ Track OTP verification status
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [loginOTP, setLoginOTP] = useState("");
  const recaptchaContainerRef = useRef(null);

  // Animate Lottie on mount
  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });

    gsap.fromTo(
      container.current,
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 1, ease: "power3.out", delay: 0.5 }
    );

    return () => {
      animation.destroy();
    };
  }, []);
  useEffect(() => {
    if (location.state?.signup) {
      setShowSignUpForm(true);
    }
  }, [location]);
  // Check role after login or signup
  useEffect(() => {
    if (currentUser && userRole) {
      if (userRole === "admin") {
        // Admin is not allowed on user login
        logout();
        toast.error("Admin login is not allowed here.");
      } else if (userRole === "student") {
        toast.success("Login successful!");
        navigate("/user-dashboard/dashboard");
      }
    }
  }, [currentUser, userRole, navigate, logout]);

  // Toggle sign-up / forgot-password
  const toggleSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
    setShowForgotPasswordForm(false);
    if (window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      } catch (error) {
        console.warn("Error cleaning up reCAPTCHA:", error);
      }
    }
  };

  const toggleForgotPasswordForm = () => {
    setShowForgotPasswordForm(!showForgotPasswordForm);
    setShowSignUpForm(false);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility_s = () => {
    setFormData((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  // Handle text input changes for sign-up
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // --- AUTH HANDLERS ---

  // LOGIN
  // Update the handleLogin function
  const handleLogin = async () => {
    setIsLoginLoading(true);
    try {
      // Check if input is a phone number (starts with + followed by numbers)
      const isPhoneNumber = /^\+[1-9]\d{1,14}$/.test(email);

      if (isPhoneNumber) {
        // For phone number login, first find the associated email
        const usersRef = collection(firestore, "users");
        const q = query(usersRef, where("phone", "==", email), limit(1));

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          toast.error("No account found with this phone number");
          return;
        }

        // Get the email associated with this phone number
        const userDoc = querySnapshot.docs[0].data();
        const userEmail = userDoc.email;

        // Login with the found email and provided password
        await signInFn(userEmail, password);
      } else {
        // Direct email login
        await signInFn(email, password);
      }
    } catch (error) {
      console.error("Login Error:", error);
      if (error.code === "auth/invalid-credential") {
        toast.error("Invalid credentials. Please try again.");
      } else if (error.code === "auth/user-not-found") {
        toast.error("No account found with these credentials.");
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many failed attempts. Please try again later.");
      } else {
        toast.error("Login failed. Please try again.");
      }
    } finally {
      setIsLoginLoading(false);
    }
  };

  // LOGIN with Google
  const handleGoogleLogin = async () => {
    try {
      const result = await signInGoogleFn();
      const user = result.user;
      const userDocRef = doc(firestore, "users", user.uid);

      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          userId: user.uid,
          role: "student",
          email: user.email,
          name: user.displayName,
          createdAt: serverTimestamp(),
        });
        toast.success("Registration successful!");
      }
      // Again, no toast for success login, let the useEffect handle
    } catch (error) {
      console.error("Google Login Error:", error);
      toast.error(`Google Login Error: ${error.message}`);
    }
  };

  // FORGOT PASSWORD
  const handleForgotPassword = async () => {
    setIsResetLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent. Please check your inbox.");
      setShowForgotPasswordForm(false);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      toast.error("Failed to send password reset email. Please try again.");
    } finally {
      setIsResetLoading(false);
    }
  };

  const resetRecaptcha = () => {
    if (window.recaptchaVerifier && !window.recaptchaVerifier.destroyed) {
      // <-- Add !destroyed check
      try {
        console.log("Resetting reCAPTCHA...");
        window.recaptchaVerifier.clear();
        // Setting to null might still be useful after clearing if the effect relies on it
        // window.recaptchaVerifier = null;
      } catch (cleanupError) {
        // Log the error, but it might be because it was *just* destroyed
        console.warn("Error clearing/resetting reCAPTCHA:", cleanupError);
      }
    } else {
      console.log(
        "No valid reCAPTCHA verifier found to reset (might be null or destroyed)."
      );
    }
    // Ensure the container is ready for a potential re-render by the useEffect
    if (recaptchaContainerRef.current) {
      // Optionally clear the visible widget if size='normal' was used
      // recaptchaContainerRef.current.innerHTML = '';
    }
    // Explicitly set to null so the useEffect re-initializes on next attempt if needed
    window.recaptchaVerifier = null;
  };
  const handleSendOTP = async () => {
    console.log("Attempting to send OTP for:", phoneNumber);
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    const formattedNumber = phoneNumber; // Already E.164 format from PhoneInput

    try {
      // --- Email Check ---
      const usersRef = collection(firestore, "users");
      const emailQuery = query(
        usersRef,
        where("email", "==", formData.email),
        limit(1)
      );
      const emailSnapshot = await getDocs(emailQuery);
      if (!emailSnapshot.empty) {
        toast.error("This email is already registered.");
        resetRecaptcha(); // Reset the component's verifier
        return;
      }

      // --- Phone Number Check ---
      const exists = await checkPhoneNumberExists(formattedNumber);
      if (exists) {
        toast.error("This phone number is already registered.");
        resetRecaptcha(); // Reset the component's verifier
        return;
      }

      // --- Check reCAPTCHA instance (created by useEffect) ---
      if (!window.recaptchaVerifier) {
        // Attempt to re-render or prompt refresh if initialization failed
        try {
          if (recaptchaContainerRef.current) {
            recaptchaContainerRef.current.innerHTML = ""; // Clear container
            // Maybe try re-initializing? This is complex, refresh might be easier.
            // setupRecaptcha(); // If you had a setup function
            toast.error(
              "Security check not ready. Please try again shortly or refresh."
            );
          } else {
            toast.error("Security check container not found. Please refresh.");
          }
        } catch (e) {
          toast.error("Security check failed. Please refresh the page.");
        }
        return; // Stop execution
      }

      // --- Send OTP ---
      toast.loading("Sending OTP...");

      // ***** KEY CHANGE HERE *****
      // Call signInWithPhone from useAuth, passing the number AND the verifier instance
      const confirmationResult = await signInWithPhone(
        formattedNumber,
        window.recaptchaVerifier // Pass the existing verifier managed by the component
      );
      // ***************************

      toast.dismiss();

      if (confirmationResult) {
        // Store confirmationResult - globally (like before) or preferably via state/context setter from useAuth
        window.confirmationResult = confirmationResult;
        // If useAuth provides a setter like setConfirmationResultState:
        // setConfirmationResultState(confirmationResult);

        setIsOTPsent(true);
        toast.success("OTP sent successfully!");
      } else {
        // This case might indicate an issue within the signInWithPhone implementation itself
        // if it didn't throw an error but returned falsy.
        throw new Error(
          "Failed to get confirmation result from signInWithPhone."
        );
      }
    } catch (error) {
      toast.dismiss();
      console.error("OTP Sending Error in Component:", error);

      // Reset the component's verifier on any error during the process
      resetRecaptcha();

      // --- Handle Specific Errors (Keep existing logic) ---
      if (error.code === "auth/invalid-phone-number") {
        toast.error(`Invalid phone number format: ${formattedNumber}`);
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many requests. Please try again later.");
      } else if (error.message && error.message.includes("reCAPTCHA")) {
        // This might catch errors from Firebase related to the verifier itself
        toast.error("reCAPTCHA verification failed. Please try again.");
      }
      // Removed check for "verifier not initialized" here as it's checked before the call now.
      else {
        toast.error(
          "Failed to send OTP. Please check the number or try again."
        );
        console.error("Unhandled OTP Error Details:", error);
      }
      // Ensure OTP sent state is false on failure
      setIsOTPsent(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    if (otp.length < 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      const user = await verifyOTP(otp);
      if (user) {
        setIsOTPVerified(true);
        // Create user document after successful OTP verification
        try {
          const user = auth.currentUser;
          await linkEmailToPhoneAccount(
            user,
            formData.email,
            formData.name,
            phoneNumber,
            formData.password
          );
          toast.success("Email linked successfully! Redirecting...");
          navigate("/user-dashboard/dashboard");
        } catch (error) {
          console.error("Error linking email:", error);
          toast.error("Failed to link email. Please try again.");
        }
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);

      // Provide more specific error messages
      if (error.code === "auth/invalid-verification-code") {
        toast.error("Invalid OTP. Please check and try again.");
      } else if (error.code === "auth/code-expired") {
        toast.error("OTP has expired. Please request a new one.");
        setIsOTPsent(false); // Reset to allow requesting a new OTP
      } else {
        toast.error("Verification failed. Please try again.");
      }
    }
  };

  const handleSendEmailVerification = async () => {
    if (!formData.email) {
      toast.error("Please enter your email.");
      return;
    }

    try {
      await sendEmailVerificationLink(formData);
      // Clear the form
      setFormData({
        name: "",
        email: "",
        password: "",
        showPassword: false,
      });
      setIsEmailSent(false); // Reset email sent state
      toast.success(
        "Verification link has been sent to your email. Please login through it."
      );
      // Switch to login form
      setShowSignUpForm(false);
    } catch (error) {
      console.error("Error sending verification email:", error);
      toast.error("Failed to send verification link. Try again.");
    }
  };

  useEffect(() => {
    // Ensure cleanup runs only once by checking if verifier already exists
    if (!window.recaptchaVerifier && recaptchaContainerRef.current) {
      console.log("Initializing reCAPTCHA...");
      try {
        // Ensure the container is empty before rendering (important for HMR or re-renders)
        recaptchaContainerRef.current.innerHTML = "";

        const verifier = new RecaptchaVerifier(
          auth, // Pass the auth instance directly
          recaptchaContainerRef.current, // Use the ref
          {
            size: "invisible", // Or 'normal'
            callback: (response) => {
              // reCAPTCHA solved, allow phone number submission
              // Often, you'll trigger the send OTP logic here directly
              // or set a state flag indicating readiness.
              console.log("reCAPTCHA solved:", response);
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
              toast.error("reCAPTCHA expired. Please verify again.");
              if (window.recaptchaVerifier) {
                try {
                  window.recaptchaVerifier.clear(); // Clear the existing verifier state
                  // Optionally re-render if needed, though clear() is often enough
                  // window.recaptchaVerifier.render().catch(err => console.error("Failed to re-render reCAPTCHA", err));
                } catch (e) {
                  console.error("Error clearing expired reCAPTCHA", e);
                }
              }
            },
            "error-callback": (error) => {
              // Error loading or rendering reCAPTCHA
              toast.error("Failed to load reCAPTCHA. Please refresh.");
              console.error("reCAPTCHA error callback:", error);
            },
          }
        );

        // Render the verifier (important for invisible reCAPTCHA too)
        verifier
          .render()
          .then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
            console.log("reCAPTCHA rendered, widgetId:", widgetId);
          })
          .catch((err) => {
            console.error("reCAPTCHA render error:", err);
            toast.error("Failed to render security check. Please refresh.");
          });

        window.recaptchaVerifier = verifier; // Store globally
      } catch (error) {
        console.error("Error initializing RecaptchaVerifier:", error);
        toast.error(
          "Failed to initialize security check. Please refresh the page."
        );
      }
    }

    // Cleanup function
    return () => {
      // Clear any existing verifier when component unmounts
      // Avoid memory leaks and issues on navigation
      if (window.recaptchaVerifier) {
        try {
          console.log("Clearing reCAPTCHA on unmount...");
          window.recaptchaVerifier.clear(); // Use clear method
          window.recaptchaVerifier = null;
          // Optional: Also clear the container's content if needed
          // if (recaptchaContainerRef.current) {
          //   recaptchaContainerRef.current.innerHTML = '';
          // }
        } catch (e) {
          console.warn("Error during reCAPTCHA cleanup:", e);
        }
      }
    };
  }, [auth]); // Dependency array includes auth instance
  return (
    <div className="min-h-screen bg-gray-800">
      <div ref={recaptchaContainerRef} id="recaptcha-container"></div>
      <div className="flex flex-col md:flex-row h-screen">
        {/* Lottie container - hidden on mobile */}
        <div className="hidden md:flex md:w-1/2 justify-center items-center">
          <img src={LoginVector} className="max-w-full h-auto" />
        </div>

        {/* Form container - full width on mobile */}
        <div className="w-full md:w-1/2 flex justify-center items-center text-white min-h-screen md:min-h-0">
          <div className="w-11/12 max-w-md bg-white text-secondary p-8 rounded-3xl my-8 md:my-0">
            {/* -------- SIGN UP FORM -------- */}
            {showSignUpForm ? (
              <form>
                <h1 className="text-2xl font-bold mb-6">Sign Up</h1>
                {/* Social Logins */}
                <div className="mt-6 text-center">
                  <div className="flex justify-center space-x-4">
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={handleGoogleLogin}
                    >
                      <img
                        src={googleSvg}
                        alt="Google"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error("LinkedIn login not available.")
                      }
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() => toast.error("Meta login not available.")}
                    >
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
                <p className="my-3 text-sm text-gray-400">
                  or sign up with email/phone
                </p>
                {/* Full Name */}
                <div className="relative mb-3">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Full Name"
                    className="block w-full px-3 py-2 rounded-lg border border-gray-300"
                  />
                </div>
                {/* Email */}
                <div className="relative mb-3">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="block w-full px-3 py-2 rounded-lg border border-gray-300"
                  />
                </div>
                {/* Password */}
                <div className="relative mb-3">
                  <input
                    type={formData.showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="block w-full px-3 py-2 rounded-lg border border-gray-300"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm">
                    <button
                      type="button"
                      onClick={togglePasswordVisibility_s}
                      className="focus:outline-none"
                    >
                      {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                {/* 🔹 Choose Verification Method (Phone or Email) */}
                <div className="relative mb-3">
                  <span className="block mb-2 text-gray-700 text-sm font-semibold">
                    Select Verification Method:
                  </span>
                  <select
                    value="phone"
                    onChange={(e) => setVerificationMethod(e.target.value)}
                    className="block w-full px-3 py-2 rounded-lg border border-gray-300"
                    disabled
                  >
                    <option value="phone">Phone OTP Verification</option>
                  </select>
                </div>
                {/* 🔹 Phone OTP Verification Fields */}
                <div className="mb-3">
                  {/* 2. Replace the input with the PhoneInput component */}
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    defaultCountry="GB"
                    // --- Add these props ---
                    international // Ensures international formatting and value storage
                    withCountryCallingCode // Displays the country code (+44) in the input field
                    // --- End of added props ---

                    // Styling (may need adjustments)
                    className="phone-input-container"
                    inputClassName="block w-full px-3 py-2 rounded-lg border border-gray-300 custom-phone-input"
                    // Optional: Specific styles if Tailwind doesn't apply perfectly
                    // style={{ /* Your custom styles here if needed */ }}
                    // inputStyle={{ /* Your custom input styles here if needed */ }}
                  />
                </div>
                {isOTPsent && (
                  <div className="relative mb-3">
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      className="block w-full px-3 py-2 rounded-lg border border-gray-300"
                    />
                  </div>
                )}

                <div className="mt-6">
                  {isOTPsent ? (
                    <button
                      type="button"
                      className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700"
                      onClick={handleVerifyOTP}
                    >
                      Verify OTP
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700"
                      onClick={handleSendOTP}
                    >
                      Send OTP
                    </button>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Already a member?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleSignUpForm}
                    >
                      Login now
                    </span>
                  </p>
                </div>
              </form>
            ) : showForgotPasswordForm ? (
              /* -------- FORGOT PASSWORD FORM -------- */
              <form>
                <h1 className="text-2xl font-bold mb-6">Forgot Password</h1>
                <div className="relative mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="username"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 relative"
                    onClick={handleForgotPassword}
                    disabled={isResetLoading}
                  >
                    {isResetLoading ? (
                      <>
                        <span className="opacity-0">Reset Password</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isResetLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Remember your password?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleForgotPasswordForm}
                    >
                      Login now
                    </span>
                  </p>
                </div>
              </form>
            ) : (
              /* -------- LOGIN FORM -------- */
              <form>
                <h1 className="text-2xl font-bold mb-6">Login</h1>
                <div className="mt-6 text-center">
                  <div className="flex justify-center space-x-4">
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={handleGoogleLogin}
                    >
                      <img
                        src={googleSvg}
                        alt="Google"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error(
                          "LinkedIn login is not available right now."
                        )
                      }
                    >
                      <img
                        src={linkedin}
                        alt="LinkedIn"
                        className="w-full h-full"
                      />
                    </div>
                    <div
                      className="w-8 h-8 rounded-full overflow-hidden cursor-pointer"
                      onClick={() =>
                        toast.error("Meta login is not available right now.")
                      }
                    >
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
                <p className="my-3 text-sm text-gray-400">
                  or login with email
                </p>

                {/* Login Email */}
                <div className="relative mb-3">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="username"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email or Phone Number
                  </label>
                </div>

                {/* Login Password */}
                <div className="relative mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    autoComplete="current-password"
                  />
                  <label
                    htmlFor="password"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Password
                  </label>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm">
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="focus:outline-none"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleLogin}
                    disabled={isLoginLoading}
                  >
                    {isLoginLoading ? (
                      <>
                        <span className="opacity-0">Login Account</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isLoginLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Login Account"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Not a member?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleSignUpForm}
                    >
                      Sign up now
                    </span>
                  </p>
                </div>
                <div className="mt-4 text-center">
                  <span
                    className="text-red-600 cursor-pointer"
                    onClick={toggleForgotPasswordForm}
                  >
                    Forgot Password?
                  </span>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_Test;
