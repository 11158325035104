import React, { useState, useEffect, useMemo, useRef } from "react";
import { room_list } from "./room_list";
import room from "../../../assets/img/room.jpg";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { firestore } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { FaHouseUser } from "react-icons/fa";
import CustomDropdown from "../../Snippets/Dropdown.js";
import CircularLoader from "../../Snippets/CircularLoader.js";
import Banner from "../../Snippets/Banner.js";
import { Hotel } from "lucide-react";

const BATCH_SIZE = 5; // For JSON infinite scroll

const HotelBooking = () => {
  // Add new state for initial data loading
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // ================================
  // STATE: Actual filters (applied)
  // ================================
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [maxPrice, setMaxPrice] = useState(300); // Only affects JSON
  const [isSearchActive, setIsSearchActive] = useState(false);

  // ================================
  // STATE: Pending filters (not yet applied)
  // ================================
  const [pendingSearchTerm, setPendingSearchTerm] = useState("");
  const [pendingFilterCity, setPendingFilterCity] = useState("");
  const [pendingMaxPrice, setPendingMaxPrice] = useState(300);

  // ================================
  // STATE: Loader
  // ================================
  const [isLoading, setIsLoading] = useState(false);

  // ================================
  // ACCOMMODATIONS
  // ================================
  const [dbRooms, setDbRooms] = useState([]);
  const [jsonRooms, setJsonRooms] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);

  // Pagination (DB) & infinite scroll (JSON)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [visibleJsonCount, setVisibleJsonCount] = useState(BATCH_SIZE);
  const observerRef = useRef(null);

  // Auth & navigation
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();

  // ================================
  // 1. INIT JSON ROOMS
  // ================================
  useEffect(() => {
    const processed = room_list.map((room) => ({
      ...room,
      searchText: `${room.roomName || ""} ${room.city || ""} ${
        room.location || ""
      } ${(room.tags || []).join(" ")}`.toLowerCase(),
    }));
    setJsonRooms(processed);
    // Set initial loading to false if DB rooms are also ready
    if (dbRooms.length > 0) {
      setIsInitialLoading(false);
    }
  }, [dbRooms]);

  // ================================
  // 2. FETCH FIRESTORE ROOMS
  // ================================
  useEffect(() => {
    const fetchFirebaseRooms = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "accommodations")
        );
        const rooms = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            accommodationId: doc.id,
            roomName: data.roomName || "",
            location: data.location || "",
            city: data.city || "",
            price: data.cost ? `From £${data.cost}/ week` : "",
            tags: [
              ...(data.room_types?.[0]?.features || []),
              data.walkingDistance ? `${data.walkingDistance} min walk` : "",
              data.trainDistance ? `${data.trainDistance} min to train` : "",
            ].filter(Boolean),
            images: data.images || [],
            searchText: `${data.roomName || ""} ${data.city || ""} ${
              data.location || ""
            }`.toLowerCase(),
          };
        });
        setDbRooms(rooms);
        // Set initial loading to false if JSON rooms are also ready
        // if (jsonRooms.length > 0) {
        //   setIsInitialLoading(false);
        // }
      } catch (error) {
        console.error("Error fetching Firebase rooms:", error);
        setDbRooms([]);
        setIsInitialLoading(false); // Set to false even on error to show empty state
      }
    };
    fetchFirebaseRooms();
  }, []);

  // ================================
  // 3. FILTERED DB ROOMS
  // (No price filter)
  // ================================
  const filteredDbRooms = useMemo(() => {
    return dbRooms.filter((room) => {
      if (!room) return false;
      const matchesSearch =
        !searchTerm || room.searchText.includes(searchTerm.toLowerCase());
      const matchesCity =
        !filterCity ||
        room.city?.toLowerCase()?.includes(filterCity.toLowerCase());
      return matchesSearch && matchesCity;
    });
  }, [dbRooms, searchTerm, filterCity]);

  // ================================
  // 4. FILTERED JSON ROOMS
  // (City, search, + price filter)
  // ================================
  const filteredJsonRooms = useMemo(() => {
    return jsonRooms.filter((room) => {
      if (!room) return false;
      const matchesSearch =
        !searchTerm || room.searchText.includes(searchTerm.toLowerCase());
      const matchesCity =
        !filterCity ||
        room.city?.toLowerCase().trim() === filterCity.toLowerCase().trim();

      // Price only for JSON
      let price = 0;
      if (room.tags && room.tags.length) {
        const lastTag = room.tags[room.tags.length - 1];
        const priceMatch = lastTag.match(/\d+/);
        price = priceMatch ? parseInt(priceMatch[0]) : 0;
      }
      const matchesPrice = !maxPrice || price <= parseInt(maxPrice);

      return matchesSearch && matchesCity && matchesPrice;
    });
  }, [jsonRooms, searchTerm, filterCity, maxPrice]);

  // ================================
  // 5. UNIQUE CITIES
  // ================================
  useEffect(() => {
    const allCities = [...dbRooms, ...jsonRooms]
      .map((item) => item?.city?.trim())
      .filter(Boolean);
    const uniqueCitiesList = [...new Set(allCities)];
    setUniqueCities(uniqueCitiesList);
  }, [dbRooms, jsonRooms]);

  // ================================
  // 6. DB PAGINATION
  // ================================
  const totalDbPages = Math.ceil(filteredDbRooms.length / itemsPerPage);
  const dbPaginatedRooms = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredDbRooms.slice(startIndex, endIndex);
  }, [filteredDbRooms, currentPage, itemsPerPage]);

  // ================================
  // 7. JSON INFINITE SCROLL
  // ================================
  const jsonVisibleItems = useMemo(() => {
    return filteredJsonRooms.slice(0, visibleJsonCount);
  }, [filteredJsonRooms, visibleJsonCount]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        visibleJsonCount < filteredJsonRooms.length
      ) {
        setVisibleJsonCount((prevCount) => prevCount + BATCH_SIZE);
      }
    });

    const sentinel = document.getElementById("json-infinite-scroll-sentinel");
    if (sentinel) observer.observe(sentinel);

    observerRef.current = observer;

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [filteredJsonRooms, visibleJsonCount]);

  // ================================
  // HANDLERS
  // ================================
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalDbPages));
  };

  // Key part: "Apply Filters" triggers a loading state
  const handleApplyFilters = () => {
    setIsLoading(true);

    // Simulate a short delay (500ms) for the “loading” effect
    setTimeout(() => {
      setSearchTerm(pendingSearchTerm);
      setFilterCity(pendingFilterCity);
      setMaxPrice(pendingMaxPrice);
      setIsSearchActive(true);
      setIsLoading(false);
    }, 500);
  };

  const handleClearSearch = () => {
    setIsSearchActive(false);
    setSearchTerm("");
    setFilterCity("");
    setMaxPrice(300);
    setCurrentPage(1);
    setVisibleJsonCount(BATCH_SIZE);

    // Reset pending as well
    setPendingSearchTerm("");
    setPendingFilterCity("");
    setPendingMaxPrice(300);
  };

  const handleViewDetails = (accommodationId) => {
    if (currentUser) {
      navigate(`/user-dashboard/accommodation/${accommodationId}`);
    } else {
      navigate("/login");
    }
  };

  // ================================
  // RENDER
  // ================================
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <Banner
        img={room}
        showHostButton={true}
        heading="Find Your Ideal Accommodation"
        text="Discover comfortable stays tailored to your needs."
        buttonText="Become a Host"
        url="/business/signup"
        icon={<Hotel />}
      />

      {/* Filters */}
      <div className="container mx-auto py-2">
        <div className="mb-1">
          <div className="flex flex-col sm:flex-row items-center mb-4 space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="flex-grow w-full sm:w-auto">
              <div className="flex items-center w-full border border-orange-600 rounded bg-transparent">
                <span className="pl-2 text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className="bg-transparent text-gray-100 py-1.5 pl-2 pr-2 w-full text-sm focus:outline-none"
                  value={pendingSearchTerm}
                  onChange={(e) => setPendingSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="flex space-x-2 w-full sm:w-auto">
              <button
                className="bg-gray-50 text-black rounded font-bold px-2 py-1 text-sm hover:bg-gray-100 transition duration-200 w-full sm:w-auto"
                onClick={handleClearSearch}
              >
                Clear
              </button>
              <button
                className="bg-orange-600 text-white rounded font-bold px-2 py-1 text-sm hover:bg-orange-700 transition duration-200 w-full sm:w-auto"
                onClick={handleApplyFilters}
              >
                Apply Filters
              </button>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-4 sm:space-y-0 space-y-6">
            <div className="w-full">
              {/* <select
                className="bg-white font-bold text-black p-2.5 rounded-3 w-full"
                value={pendingFilterCity}
                onChange={(e) => setPendingFilterCity(e.target.value)}
              >
                <option value="">Search by city</option>
                {uniqueCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select> */}
              <CustomDropdown
                options={uniqueCities}
                value={pendingFilterCity}
                onChange={(e) => setPendingFilterCity(e.target.value)}
                placeholder="select City"
                labelText="City"
              />
            </div>

            {/* Price Range only for JSON */}
            <div className="w-full">
              <div className="flex space-x-3 space-y-2 text-sm">
                <div className="mt-2">
                  <label>Price Range:</label>
                </div>
                <div className="flex-1 w-full">
                  <div className="relative">
                    <input
                      type="range"
                      id="pendingMaxPrice"
                      min={100}
                      max={2000}
                      step={10}
                      value={pendingMaxPrice}
                      onChange={(e) => setPendingMaxPrice(e.target.value)}
                      className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer accent-orange-500"
                      style={{
                        background: `linear-gradient(to right, #FF4500 0%, #FF4500 ${
                          ((pendingMaxPrice - 100) / (2000 - 100)) * 100
                        }%, #E5E5E5 ${
                          ((pendingMaxPrice - 100) / (2000 - 100)) * 100
                        }%, #E5E5E5 100%)`,
                      }}
                    />
                    <div
                      className="absolute top-[-22px] left-1/2 transform -translate-x-1/2 bg-white text-orange-600 px-3 py-1 rounded-5 shadow-md text-xs font-bold"
                      style={{
                        left: `calc(${
                          ((pendingMaxPrice - 100) / (2000 - 100)) * 100
                        }% - 0px)`,
                      }}
                    >
                      £{pendingMaxPrice}
                    </div>
                  </div>
                  <div className="flex justify-between text-gray-400 text-sm font-bold">
                    <span>£100</span>
                    <span>£2000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MAIN CONTENT */}
        {isInitialLoading ? (
          <div className="flex justify-center items-center">
            <CircularLoader />
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center">
            <CircularLoader />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {/* 1) If searching -> Merged Results */}
            {isSearchActive && (
              <div>
                <h3 className="text-xl font-bold text-white mb-4">
                  Search Results (
                  {filteredDbRooms.length + filteredJsonRooms.length})
                </h3>
                {filteredDbRooms.length > 0 || filteredJsonRooms.length > 0 ? (
                  <div className="grid grid-cols-1 gap-6">
                    {[...filteredDbRooms, ...filteredJsonRooms].map(
                      (item, index) => (
                        <div
                          key={`search-${index}`}
                          className="bg-transparent border-2 border-white p-2.5 rounded-lg overflow-hidden transition-all transform flex flex-col sm:flex-row"
                        >
                          {/* Slider Section */}
                          <div className="w-full sm:w-1/3 slider_arrows">
                            <Slider
                              dots={false}
                              infinite={item.images?.length > 1}
                              speed={500}
                              slidesToShow={1}
                              slidesToScroll={1}
                              arrows={item.images?.length > 1}
                              className="group relative"
                            >
                              {(item.images || []).map((image, idx) => (
                                <img
                                  key={idx}
                                  src={image}
                                  alt={`Accommodation Image ${idx + 1}`}
                                  className="w-full h-15.7 object-cover rounded-lg"
                                />
                              ))}
                            </Slider>
                          </div>

                          {/* Content Section */}
                          <div className="px-4 sm:w-2/3">
                            <div className="flex items-center justify-between">
                              <h2 className="text-base font-semibold mb-0">
                                {item.roomName}
                              </h2>
                              <p className="text-base font-bold text-orange-600 m-0">
                                {item.price ||
                                  (item.tags &&
                                    item.tags[item.tags.length - 1])}
                              </p>
                            </div>
                            <p className="text-sm text-gray-400 mb-0">
                              {item.location}
                            </p>
                            <p className="text-sm text-gray-400 mb-2">
                              {item.city}
                            </p>
                            <div className="flex flex-wrap gap-2 mb-4">
                              {(item.tags || []).map((detail, idx) => (
                                <span
                                  key={idx}
                                  className="bg-gray-700 text-gray-300 text-xs p-2 rounded-2"
                                >
                                  {detail}
                                </span>
                              ))}
                            </div>
                            <div className="text-end mt-4">
                              <button
                                className="bg-gray-50 text-orange-600 py-2 px-4 font-bold rounded-4 hover:bg-gray-200 transition duration-300"
                                onClick={() =>
                                  handleViewDetails(item.accommodationId)
                                }
                              >
                                Click to view more
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-gray-400">No results found.</p>
                )}
              </div>
            )}

            {/* 2) Normal layout if not searching */}
            {!isSearchActive && (
              <>
                {/* DB Accommodations */}
                {userData && (
                  <div>
                    <h3 className="text-base font-bold text-white mb-2">
                      Featured Accommodations ({filteredDbRooms.length})
                    </h3>

                    {filteredDbRooms.length > 0 ? (
                      filteredDbRooms.length === 1 ? (
                        // When there's only one accommodation, render the alternate card UI:
                        filteredDbRooms.map((item, index) => (
                          <div
                            key={`json-${index}`}
                            className="bg-transparent border-2 border-white p-2.5 rounded-lg overflow-hidden transition-all transform flex flex-col sm:flex-row"
                          >
                            {/* Slider Section */}
                            <div className="w-full sm:w-1/3 slider_arrows">
                              <Slider
                                dots={false}
                                infinite={item.images.length > 1} // Only enable infinite if there are multiple images
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={item.images.length > 1} // Only show arrows if there are multiple images
                                className="group relative"
                              >
                                {item.images.map((image, idx) => (
                                  <img
                                    key={idx}
                                    src={image}
                                    alt={`Accommodation Image ${idx + 1}`}
                                    className="w-full h-15.7 object-cover rounded-lg"
                                  />
                                ))}
                              </Slider>
                            </div>

                            {/* Content Section */}
                            <div className="px-4 sm:w-2/3">
                              <div className="flex items-center justify-between">
                                <h2 className="text-base font-semibold mb-0">
                                  {item.roomName}
                                </h2>
                                <p className="text-base font-bold text-orange-600 m-0">
                                  {item.price}
                                </p>
                              </div>
                              <p className="text-sm text-gray-400 mb-0">
                                {item.location}
                              </p>
                              <p className="text-sm text-gray-400 mb-2">
                                {item.city}
                              </p>
                              <div className="flex flex-wrap gap-2 mb-4">
                                {(item.tags || []).map((detail, idx) => (
                                  <span
                                    key={idx}
                                    className="bg-gray-700 text-gray-300 text-xs p-2 rounded-2"
                                  >
                                    {detail}
                                  </span>
                                ))}
                              </div>
                              <div className="text-end mt-4">
                                <button
                                  className="bg-gray-50 text-orange-600 py-2 px-4 font-bold rounded-4 hover:bg-gray-200 transition duration-300"
                                  onClick={() =>
                                    handleViewDetails(item.accommodationId)
                                  }
                                >
                                  Click to view more
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        // When there are multiple accommodations, render the slider UI:
                        (() => {
                          const isSingle = filteredDbRooms.length === 1;
                          const desiredSlides = 3;
                          const slidesToShow = isSingle
                            ? 1
                            : Math.min(filteredDbRooms.length, desiredSlides);

                          // Slider settings for multiple items
                          const settings = {
                            dots: true,
                            infinite:
                              !isSingle &&
                              filteredDbRooms.length > slidesToShow,
                            speed: 500,
                            slidesToShow: slidesToShow,
                            slidesToScroll: 1,
                            arrows: !isSingle && filteredDbRooms.length > 1,
                            className: "my-6",
                            ...(isSingle && {
                              centerMode: true,
                              centerPadding: "0px",
                            }),
                          };

                          return (
                            <Slider {...settings}>
                              {filteredDbRooms.map((item, index) => (
                                <div
                                  key={`db-${index}`}
                                  className="px-2"
                                  style={{ maxWidth: "260px", margin: "auto" }}
                                >
                                  <div className="bg-transparent border-2 border-white p-2 rounded-lg overflow-hidden transition-all flex flex-col gap-2">
                                    {/* Nested image slider */}
                                    <div className="slider_arrows relative">
                                      <Slider
                                        dots={false}
                                        infinite={item.images?.length > 1}
                                        speed={500}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        arrows={item.images?.length > 1}
                                      >
                                        {item.images.map((image, idx) => (
                                          <img
                                            key={idx}
                                            src={image}
                                            alt={`Accommodation Image ${
                                              idx + 1
                                            }`}
                                            className="w-full h-40 object-cover rounded-md"
                                          />
                                        ))}
                                      </Slider>
                                    </div>

                                    {/* Content Section */}
                                    <div>
                                      <div className="flex items-center justify-between">
                                        <h2 className="text-base font-semibold truncate">
                                          {item.roomName}
                                        </h2>
                                        <p className="text-base font-bold text-orange-600">
                                          {item.price}
                                        </p>
                                      </div>
                                      <p className="text-xs text-gray-400 truncate">
                                        {item.location}
                                      </p>
                                      <p className="text-xs text-gray-400 truncate">
                                        {item.city}
                                      </p>
                                      <div className="flex flex-wrap gap-1 mt-1">
                                        {item.tags.map((detail, idx) => (
                                          <span
                                            key={idx}
                                            className="bg-gray-700 text-gray-300 text-xs py-1 px-2 rounded-md"
                                          >
                                            {detail}
                                          </span>
                                        ))}
                                      </div>
                                      <div className="text-end mt-3">
                                        <button
                                          className="bg-gray-50 text-orange-600 text-xs font-semibold py-1 px-2 rounded hover:bg-gray-200 transition"
                                          onClick={() =>
                                            handleViewDetails(
                                              item.accommodationId
                                            )
                                          }
                                        >
                                          View more
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          );
                        })()
                      )
                    ) : (
                      <p className="text-gray-400">
                        No featured accommodations available
                      </p>
                    )}
                  </div>
                )}

                {/* JSON Accommodations (Infinite scroll + price filter) */}
                <div>
                  <h3 className="text-base font-bold text-white mb-2">
                    Other Accommodations ({filteredJsonRooms.length})
                  </h3>
                  {jsonVisibleItems.length > 0 ? (
                    <div className="grid grid-cols-1 gap-6">
                      {jsonVisibleItems.map((item, index) => {
                        const tags = item?.tags || [];
                        const price =
                          tags.length > 0
                            ? tags[tags.length - 1]
                            : "Price not available";
                        const detailsWithoutPrice =
                          tags.length > 0 ? tags.slice(0, -1) : [];
                        const images = item?.images || [];

                        return (
                          <div
                            key={`json-${index}`}
                            className="bg-transparent border-2 border-white p-2.5 rounded-lg overflow-hidden transition-all transform flex flex-col sm:flex-row"
                          >
                            {/* Slider Section */}
                            <div className="w-full sm:w-1/3 slider_arrows">
                              <Slider
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={true}
                                className="group relative"
                              >
                                {images.map((image, idx) => (
                                  <img
                                    key={idx}
                                    src={image}
                                    alt={`Accommodation Image ${idx + 1}`}
                                    className="w-full h-15.7 object-cover rounded-lg"
                                  />
                                ))}
                              </Slider>
                            </div>

                            {/* Content Section */}
                            <div className="px-4 sm:w-2/3">
                              <div className="flex items-center justify-between">
                                <h2 className="text-base font-semibold mb-0">
                                  {item.roomName}
                                </h2>
                                <p className="text-sm font-bold text-orange-600 m-0">
                                  {price}
                                </p>
                              </div>
                              <p className="text-sm text-gray-400 mb-0">
                                {item.location}
                              </p>
                              <p className="text-sm text-gray-400 mb-2">
                                {item.city}
                              </p>
                              <div className="flex flex-wrap gap-2 mb-4">
                                {detailsWithoutPrice.map((detail, idx) => (
                                  <span
                                    key={idx}
                                    className="bg-gray-700 text-gray-300 text-xs p-2 rounded-2"
                                  >
                                    {detail}
                                  </span>
                                ))}
                              </div>
                              <div className="text-end mt-4">
                                <button
                                  className="bg-gray-50 text-orange-600 text-sm py-2 px-4 font-bold rounded-4 hover:bg-gray-200 transition duration-300"
                                  onClick={() =>
                                    handleViewDetails(item.accommodationId)
                                  }
                                >
                                  Click to view more
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-gray-400">
                      No other accommodations available
                    </p>
                  )}
                  {/* Infinite scroll sentinel */}
                  <div id="json-infinite-scroll-sentinel" className="h-10" />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelBooking;
