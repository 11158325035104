import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import toast, { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import AdminDashboard from "./components/Admin/admin-dashboard/adminDashboard";
import Overview from "./components/Admin/overview/Overview";
// import LoginPage from "./components/Home/pages/LoginPage";
import FilterEventsPage from "./components/Student/Events/FilterEventsPage";
import { gsap } from "gsap";
import UserDashboard from "./components/Student/userdashboard/UserDashboard";
import EventsPage from "./components/Student/Events/EventsPage";
import University from "./components/Student/Universities/University";
import StudentProfile from "./components/Student/userProfile/StudentProfile";
// import UniversityDetails from "./components/Student/Universities/UniversityDetails";
import JobsHome from "./components/Admin/jobs/JobsHome";
import PrivateRoute from "./auth/PrivateRoute/PrivateRoute";
import PageNotFound from "./components/Home/pages/PageNotFound";
import NewHome from "./components/Home/pages/NewHome";
// import Join from "./components/Home/pages/Join";
// import SmeForm from './components/Home/pages/SmeForm';
// import Jobs from "./components/Student/Jobs/Jobs";
import TempJobs from "./components/Student/Jobs/TempJobs";
import AboutUsPage from "./components/Home/pages/AboutUsPage";
import IQAgentMiddleware from "./components/Student/Accomodation/Accomodation";
// import AuthComponent from "./components/Testing";
import DownloadExcel from "./components/Admin/FetchData";
import AddRestaurant from "./components/Admin/restaurants/AddRestaurant";
import Restaurant from "./components/Student/Explore/RestaurantTabs";
import SwipeCarousel from "./components/Student/Explore/SwipeCarousel";
import Mentorship from "./components/Home/pages/Mentorship";
import UploadEventForm from "./components/Admin/events/UploadEvents";
import EventPage from "./components/Student/Events/EventPage";
import Success from "./components/CheckOutForm/Success";
import Failure from "./components/CheckOutForm/Failure";
// import Maps from "./components/Student/Maps/Maps";
import MyBookings from "./components/Student/Bookings/MyBookings";
import Ambassador from "./components/Home/pages/Ambassador";
// import GoogleMap from "./components/Student/Maps/GoogleMaps";
import CheckoutDetails from "./components/Admin/events/Bookings";
// import MapComponent from "./components/Student/Maps/GoogleMaps";
// import Home from "./components/Home/pages/Home";
import QRScanner from "./components/Admin/events/TicketVerifiction";
import VerificationLogs from "./components/Admin/events/VerificationLogs";
// import MapWithGeocoding from "./components/Student/Maps/GeoCode";
// import CityRestaurants from "./components/Student/Explore/CityRestaurants";
import Layout from "./components/Home/layout/layout";
import TicektPreview from "./components/Student/Bookings/TicketPreview";
import RefundRequests from "./components/Admin/events/RefundRequests";
import BusinessHome from "./components/Home/pages/BusinessHome";
import BusinessLoginPage from "./components/Home/pages/BusinessLoginPage";
// import OrgPage from "./components/Admin/events/BusinessEventCompanyPage";
import EditPageSettings from "./components/Admin/events/EditPageSetting";
import BusinessEventCompanyPage from "./components/Admin/events/BusinessEventCompanyPage";
import PublicEventPage from "./components/Home/pages/PublicEventPage";
// import Feed from "./components/Student/Explore/Reliv/Feed";
// import Post from "./components/Reliv/post";
// import MyProfile from "./components/Reliv/MyProfile";
// import AddPost from "./components/Reliv/AddPost";
import RelivHome from "./components/Reliv/RelivHome";
import PreviewUniversity from "./components/Student/Universities/PreviewUniversity";
import RestaurantPage from "./components/Student/Explore/RestaurantPage";
import RestaurantGrid from "./components/Student/Explore/CityBasedRestaurants";
import ExploreMain from "./components/Student/Explore/ExploreMain";
import NearbyRestaurants from "./components/Student/Explore/NearbyRestaurants";
import AddExperience from "./components/Reliv/AddExperience";
import Itinerary from "./components/Reliv/Itinerary";
import PostReliv from "./components/Reliv/PostReliv";
import RelivAndBooking from "./components/Reliv/RelivAndBooking";
import MyWorlds from "./components/Reliv/MyWorlds";
import RelivAnalyticsDashboard from "./components/Reliv/RelivAnalyticsDashboard";
import EditProfile from "./components/Reliv/EditProfileModal";
import WeatherDashboard from "./components/Home/pages/WeatherDashboard";
import Login_Test from "./components/Home/pages/Login_Test";
import RoomDetails from "./components/Student/Accomodation/RoomDetails";
import ExperienceCart from "./components/Reliv/ExperienceCart";
import Unauthorized from "./auth/PrivateRoute/Unauthorized";
import { useAuth } from "./auth/userProvider/AuthProvider";
import { JobsProvider } from "./components/Admin/jobs/JobsContext";
import PostJobs from "./components/Admin/jobs/PostJobs";
import JobListing from "./components/Admin/jobs/JobListing";
import JobsDashboard from "./components/Admin/jobs/JobsDashboard";
import CompanyPage from "./components/Admin/jobs/CompanyPage";
import AccommodationForm from "./components/Admin/accomodation/AccommodationHome";
import PostAccomodation from "./components/Admin/accomodation/PostAccommodation";
import AccommodationHome from "./components/Admin/accomodation/AccommodationHome";
import AvailableRooms from "./components/Admin/accomodation/AvailableRooms";
import UniversityLayout from "./components/Admin/universities/UniversityLayout";
import UploadUniversityInfo from "./components/Admin/universities/UploadUniversityInfo";
import AddCourses from "./components/Admin/universities/AddCourses";
import UniversityDashboard from "./components/Admin/universities/UniversityDashboard";
import HelpForm from "./components/Student/Help/HelpForm";
import Dashboard from "./components/Student/Dashboard/Dashboard";
import ToastProvider from "./utils/toastconfig";
import FoodVarietyRestaurant from "./components/Student/Explore/FoodVarietyRestaurant";
import CoursePlatform from "./components/Student/Learning/LearningHome";
import CourseDetail from "./components/Student/Learning/CourseDetails";
import { AnalyticsTracker } from "./AnalyticsTracker";
import { UniversityAnalytics } from "./components/Admin/universities/UniversityAnalytics";
import UniversityProfile from "./components/Admin/universities/UniversityProfile";
import UniversitySettings from "./components/Admin/universities/UniversitySettings";
import EmailVerification from "./auth/userProvider/EmailVerification";
import AdvertiseForm from "./components/Admin/accomodation/PostAds";
import AdvertiseRoomForm from "./components/Admin/accomodation/PostAds";
import VapiLoader from "./VapitLoader";
import Analytics from "./components/Admin/events/Analytics";

function App() {
  return (
    <ToastProvider>
      <Router>
        {/* <VapiLoader /> */}
        <AnalyticsTracker />
        <JobsProvider>
          <Routes>
            {/* ------------------ PUBLIC ROUTES ------------------ */}
            <Route element={<Layout />}>
              {/* Layout's children */}
              <Route path="/" element={<NewHome />} />
              <Route path="ambassador" element={<Ambassador />} />
              <Route path="login" element={<Login_Test />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="blank" element={<PageNotFound />} />
              <Route path="mentorship" element={<Mentorship />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route
                path="/email-verification/*"
                element={<EmailVerification />}
              />
              {/* Public Event / Jobs / Accommodation / Universities */}
              <Route path="events" element={<EventsPage />} />
              <Route path="job-opportunities" element={<TempJobs />} />
              <Route path="accommodation" element={<IQAgentMiddleware />} />
              <Route path="explore" element={<ExploreMain />} />
              <Route path="universities" element={<University />} />
              <Route path="universities/:id" element={<PreviewUniversity />} />
              <Route
                path="events/p/:companyPage"
                element={<PublicEventPage />}
              />
              <Route
                path="events/eventDetails/:eventId"
                element={<EventPage />}
              />
              <Route
                path="events/all-events/:eventCategory"
                element={<FilterEventsPage />}
              />
              <Route path="events/all-events" element={<FilterEventsPage />} />
            </Route>

            {/* Business Landing & Signup (unprotected) */}
            <Route path="/business" element={<BusinessHome />} />
            <Route path="/business/signup" element={<BusinessLoginPage />} />

            {/* ------------------ ADMIN (BUSINESS) PROTECTED ROUTES ------------------ */}
            <Route path="/business" element={<PrivateRoute role="admin" />}>
              {/* 
                We now nest <AdminDashboard> as a *layout* with an <Outlet/> inside it. 
                That way, /business -> AdminDashboard, and child routes appear inside.
              */}
              <Route element={<AdminDashboard />}>
                {/* Child routes relative to /business */}
                <Route path="events/overview" element={<Overview />} />
                <Route path="events" element={<BusinessEventCompanyPage />} />
                <Route path="events/new" element={<UploadEventForm />} />
                <Route
                  path="events/edit/:eventId"
                  element={<UploadEventForm />}
                />
                <Route
                  path="events/company-profile"
                  element={<EditPageSettings />}
                />
                <Route path="events/verify" element={<QRScanner />} />
                <Route
                  path="events/verificaton-logs"
                  element={<VerificationLogs />}
                />
                <Route path="events/orders" element={<CheckoutDetails />} />
                <Route path="events/requests" element={<RefundRequests />} />
                <Route path="events/analytics" element={<Analytics />} />
                {/* <Route path="add-college" element={<CollegeUpload />} /> */}
                <Route path="restaurants" element={<AddRestaurant />} />

                {/* University Admin nested */}
                <Route path="university" element={<UniversityLayout />}>
                  <Route
                    path="upload/info"
                    element={<UploadUniversityInfo />}
                  />
                  <Route path="add/courses" element={<AddCourses />} />
                  <Route path="profile" element={<UniversityProfile />} />
                  <Route path="settings" element={<UniversitySettings />} />
                  <Route path="dashboard" element={<UniversityAnalytics />} />
                  {/* <Route path="analytics" element={<UniversityAnalytics />} /> */}
                </Route>

                {/* Jobs Admin nested */}
                <Route path="jobs" element={<JobsHome />}>
                  <Route path="dashboard" element={<JobsDashboard />} />
                  <Route path="add-job" element={<PostJobs />} />
                  <Route path="job-listings" element={<JobListing />} />
                  <Route path="profile" element={<CompanyPage />} />
                </Route>

                {/* Accommodation Admin nested */}
                <Route path="accommodation" element={<AccommodationHome />}>
                  <Route path="add" element={<PostAccomodation />} />
                  <Route path="ads" element={<AdvertiseRoomForm />} />
                  <Route path="rooms" element={<AvailableRooms />} />
                </Route>
              </Route>
            </Route>

            {/* ------------------ STUDENT PROTECTED ROUTES ------------------ */}
            <Route
              path="/user-dashboard"
              element={<PrivateRoute role="student" />}
            >
              {/* 
                Nest <UserDashboard> as layout with <Outlet /> 
                so all /user-dashboard/... pages appear inside that layout.
              */}
              <Route element={<UserDashboard />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="help" element={<HelpForm />} />
                <Route path="events" element={<EventsPage />} />

                <Route path="learning" element={<CoursePlatform />} />
                <Route
                  path="learning/course/:courseId"
                  element={<CourseDetail />}
                />
                <Route path="success" element={<Success />} />
                <Route path="failure" element={<Failure />} />
                <Route path="fetchData" element={<DownloadExcel />} />
                <Route path="swipe" element={<SwipeCarousel />} />
                <Route
                  path="bookings/tickets/:ticketId"
                  element={<TicektPreview />}
                />

                {/* Filtered events under /user-dashboard */}
                <Route
                  path="events/all-events/:eventCategory"
                  element={<FilterEventsPage />}
                />
                <Route
                  path="events/all-events"
                  element={<FilterEventsPage />}
                />
                <Route path="eventDetails/:eventId" element={<EventPage />} />

                {/* Accomodation, Universities, Jobs */}
                <Route path="accommodation" element={<IQAgentMiddleware />} />
                <Route
                  path="accommodation/:accommodationId"
                  element={<RoomDetails />}
                />
                <Route path="university" element={<University />} />
                <Route
                  path="universities/:id"
                  element={<PreviewUniversity />}
                />
                <Route path="jobs" element={<TempJobs />} />

                {/* Explore */}
                <Route path="explore" element={<ExploreMain />} />
                <Route
                  path="nearbyrestaurant"
                  element={<NearbyRestaurants />}
                />
                <Route
                  path="foodvariety/:item"
                  element={<FoodVarietyRestaurant />}
                />
                <Route path="restaurant/:uuid" element={<RestaurantPage />} />
                <Route path="explore/:restaurantId" element={<Restaurant />} />
                <Route path="explore/c/:city" element={<RestaurantGrid />} />
                <Route path=":city/:restaurantId" element={<Restaurant />} />

                {/* Bookings, Profile, etc. */}
                <Route path="bookings" element={<MyBookings />} />
                <Route path="settings/:userId" element={<StudentProfile />} />

                {/* Reliv */}
                <Route path="reliv" element={<RelivHome />} />
                <Route path="reliv/create/post" element={<PostReliv />} />
                <Route
                  path="reliv/create/itinerary/:experienceId"
                  element={<Itinerary />}
                />
                <Route
                  path="reliv/book/:postId"
                  element={<RelivAndBooking />}
                />
                <Route path="reliv/book/cart" element={<ExperienceCart />} />
                <Route path="reliv/myworlds" element={<MyWorlds />} />
                <Route path="profile" element={<EditProfile />} />
                <Route
                  path="reliv/analysis"
                  element={<RelivAnalyticsDashboard />}
                />
              </Route>
            </Route>

            {/* ------------------ 404 CATCH-ALL ------------------ */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </JobsProvider>
      </Router>
    </ToastProvider>
  );
}

export default App;
