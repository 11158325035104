"use client";

import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase";
import {
  Eye,
  Users,
  Award,
  Loader,
  BookOpen,
  ChevronDown,
  ChevronUp,
  ArrowUpDown,
} from "lucide-react";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export function UniversityAnalytics() {
  const [analyticsData, setAnalyticsData] = useState({
    pageviews: [],
    courseClicks: [],
    enquiryClicks: [],
    universityId: "",
    universityName: "",
    totalViews: 0,
    totalCourseClicks: 0,
    totalEnquiries: 0,
    directViews: 0,
    referralViews: 0,
    uniqueCourses: {}, // Grouped by course
    recentViews: [],
  });
  // Add these state variables to your component
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Adjust the number of rows per page as needed
  const [loading, setLoading] = useState(true);
  const [expandedCourse, setExpandedCourse] = useState(null); // Track open accordion
  const [sortOrder, setSortOrder] = useState("desc"); // Sorting order for courses
  const [activeTab, setActiveTab] = useState("overview"); // Added for tab navigation

  const { userData } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // ✅ Fetch university details
        const q = query(
          collection(firestore, "universities"),
          where("userId", "==", userData.userId)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const universityDoc = querySnapshot.docs[0];
          const data = universityDoc.data();
          const universityId = universityDoc.id;

          console.log("University ID:", universityId);

          // ✅ Fetch Pageviews
          const pageviewsCollection = collection(
            firestore,
            `analytics/universitiesMeta/universities/${universityId}/pageviews`
          );
          const pageviewsSnapshot = await getDocs(pageviewsCollection);
          const views = pageviewsSnapshot.docs.map((doc) => doc.data());

          // ✅ Process pageview analytics
          const directViews = views.filter(
            (view) => view.referrer === "direct"
          ).length;
          const referralViews = views.length - directViews;
          const sortedViews = [...views].sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          const recentViews = sortedViews.slice(0, 5);

          // ✅ Fetch Course Clicks
          const courseClicksCollection = collection(
            firestore,
            `analytics/universitiesMeta/universities/${universityId}/courseClicks`
          );
          const courseClicksSnapshot = await getDocs(courseClicksCollection);
          let courseClicks = [];

          courseClicksSnapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (Array.isArray(data.courseClick)) {
              courseClicks = [
                ...courseClicks,
                ...data.courseClick.map((c) => ({
                  ...c,
                  name: data.name || "Anonymous",
                  email: data.email,
                  userId: data.userId,
                  city: data.city,
                  country: data.country,
                  state: data.state,
                  gender: data.gender,
                  age: data.age,
                })),
              ];
            }
          });

          console.log("Course Clicks Data:", courseClicks);

          // ✅ Group clicks by unique courses & count clicks
          const uniqueCourses = courseClicks.reduce((acc, course) => {
            if (!acc[course.courseName]) {
              acc[course.courseName] = { clicks: 0, users: [] };
            }
            acc[course.courseName].clicks += 1;
            acc[course.courseName].users.push(course);
            return acc;
          }, {});

          // Fetch Enquiry Clicks
          const enquiryClicksCollection = collection(
            firestore,
            `analytics/universitiesMeta/universities/${universityId}/enquiryClicks`
          );
          const enquiryClicksSnapshot = await getDocs(enquiryClicksCollection);
          let enquiryClicks = [];

          enquiryClicksSnapshot.docs.forEach((doc) => {
            const data = doc.data();
            if (Array.isArray(data.enquireClick)) {
              enquiryClicks = [
                ...enquiryClicks,
                ...data.enquireClick.map((e) => ({
                  ...e,
                  name: data.name || "Anonymous",
                  email: data.email,
                  userId: data.userId,
                  city: data.city,
                  country: data.country,
                  state: data.state,
                  gender: data.gender,
                  age: data.age,
                })),
              ];
            }
          });

          setAnalyticsData((prev) => ({
            ...prev,
            pageviews: views,
            courseClicks,
            enquiryClicks,
            universityId,
            universityName: data.university_name || "Your University",
            totalViews: views.length,
            totalCourseClicks: courseClicks.length,
            totalEnquiries: enquiryClicks.length,
            directViews,
            referralViews,
            recentViews,
            uniqueCourses,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userData?.userId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [userData?.userId]);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (e) {
      return dateString;
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const prepareChartData = () => {
    // Process course clicks by date
    const courseClicksByDate = analyticsData.courseClicks.reduce(
      (acc, click) => {
        const date = new Date(click.timestamp).toLocaleDateString();
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      },
      {}
    );

    // Process enquiry clicks by date
    const enquiryClicksByDate = analyticsData.enquiryClicks.reduce(
      (acc, click) => {
        const date = new Date(click.timestamp).toLocaleDateString();
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      },
      {}
    );

    // Get all unique dates
    const allDates = [
      ...new Set([
        ...Object.keys(courseClicksByDate),
        ...Object.keys(enquiryClicksByDate),
      ]),
    ].sort();

    // Prepare data for the line chart
    const lineChartData = {
      labels: allDates,
      datasets: [
        {
          label: "Course Clicks",
          data: allDates.map((date) => courseClicksByDate[date] || 0),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          tension: 0.3,
        },
        {
          label: "Enquiries",
          data: allDates.map((date) => enquiryClicksByDate[date] || 0),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.3,
        },
      ],
    };

    // Prepare data for the pie chart
    const pieChartData = {
      labels: ["Direct Views", "Referral Views", "Course Clicks", "Enquiries"],
      datasets: [
        {
          data: [
            analyticsData.directViews,
            analyticsData.referralViews,
            analyticsData.totalCourseClicks,
            analyticsData.totalEnquiries,
          ],
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255, 206, 86)",
            "rgb(255, 99, 132)",
            "rgb(75, 192, 192)",
          ],
          borderWidth: 1,
        },
      ],
    };

    // Top courses data
    const sortedCourses = Object.entries(analyticsData.uniqueCourses)
      .sort(([, a], [, b]) => b.clicks - a.clicks)
      .slice(0, 5);

    const barChartData = {
      labels: sortedCourses.map(([name]) => name),
      datasets: [
        {
          label: "Course Clicks",
          data: sortedCourses.map(([, data]) => data.clicks),
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132, 0.8)",
          borderWidth: 1,
        },
      ],
    };

    // Add country distribution data
    const countryDistribution = [
      ...analyticsData.courseClicks,
      ...analyticsData.enquiryClicks,
    ].reduce((acc, interaction) => {
      const country = interaction.country || "Unknown";
      acc[country] = (acc[country] || 0) + 1;
      return acc;
    }, {});

    // Sort countries by interaction count and get top 10
    const topCountries = Object.entries(countryDistribution)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10);

    const countryChartData = {
      labels: topCountries.map(([country]) => country),
      datasets: [
        {
          label: "User Interactions",
          data: topCountries.map(([, count]) => count),
          backgroundColor: [
            "rgb(54, 162, 235)",
            "rgb(255, 99, 132)",
            "rgb(255, 206, 86)",
            "rgb(75, 192, 192)",
            "rgb(153, 102, 255)",
            "rgb(255, 159, 64)",
            "rgb(199, 199, 199)",
            "rgb(83, 102, 255)",
            "rgb(255, 99, 255)",
            "rgb(159, 159, 64)",
          ],
        },
      ],
    };

    return {
      lineChartData,
      pieChartData,
      barChartData,
      countryChartData,
    };
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <div className="animate-spin">
          <Loader className="h-10 w-10 text-blue-500" />
        </div>
        <p className="text-gray-400">Loading analytics data...</p>
      </div>
    );
  }

  return (
    <div className="">
      <div className="overflow-hidden  rounded-lg shadow-xl">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white p-2">
          <h2 className="text-base font-bold flex items-center gap-2">
            <Award className="h-4 w-4" />
            {analyticsData.universityName} Analytics
          </h2>
        </div>

        {/* Tabs */}
        <div className="bg-gray-800 border-b border-gray-700 px-4">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab("overview")}
              className={`py-2 px-4 font-medium text-sm focus:outline-none ${
                activeTab === "overview"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-400 hover:text-gray-300"
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab("courses")}
              className={`py-3 px-4 font-medium text-sm focus:outline-none ${
                activeTab === "courses"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              disabled
            >
              Courses
            </button>
            <button
              onClick={() => setActiveTab("users")}
              className={`py-3 px-4 font-medium text-sm focus:outline-none ${
                activeTab === "users"
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              disabled
            >
              Users
            </button>
          </div>
        </div>

        <div className="p-6">
          {/* Metric Cards */}
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
            {/* Pageviews */}
            <div className="p-4 rounded-lg flex items-center gap-3 border border-blue-500 border-opacity-20 bg-gray-800 shadow-md transition-all hover:shadow-lg hover:translate-y-[-2px]">
              <div className="p-3 bg-blue-900 rounded-full">
                <Eye className="h-3 w-3 text-blue-400" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Total Pageviews</p>
                <p className="text-2xl font-bold text-white">
                  {analyticsData.totalViews}
                </p>
              </div>
            </div>

            {/* Course Clicks */}
            <div className="p-4 rounded-lg flex items-center gap-3 border border-orange-500 border-opacity-20 bg-gray-800 shadow-md transition-all hover:shadow-lg hover:translate-y-[-2px]">
              <div className="p-3 bg-orange-900 rounded-full">
                <BookOpen className="h-3 w-3 text-orange-400" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Total Course Clicks</p>
                <p className="text-2xl font-bold text-white">
                  {analyticsData.totalCourseClicks}
                </p>
              </div>
            </div>

            {/* Direct Views */}
            <div className="p-4 rounded-lg flex items-center gap-3 border border-green-500 border-opacity-20 bg-gray-800 shadow-md transition-all hover:shadow-lg hover:translate-y-[-2px]">
              <div className="p-3 bg-green-900 rounded-full">
                <Users className="h-3 w-3 text-green-400" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Direct Visits</p>
                <p className="text-2xl font-bold text-white">
                  {analyticsData.directViews}
                </p>
              </div>
            </div>

            {/* Referral Views */}
            <div className="p-4 rounded-lg flex items-center gap-3 border border-yellow-500 border-opacity-20 bg-gray-800 shadow-md transition-all hover:shadow-lg hover:translate-y-[-2px]">
              <div className="p-3 bg-yellow-900 rounded-full">
                <Users className="h-3 w-3 text-yellow-400" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Referral Visits</p>
                <p className="text-2xl font-bold text-white">
                  {analyticsData.referralViews}
                </p>
              </div>
            </div>

            {/* New Enquiries Card */}
            <div className="p-4 rounded-lg flex items-center gap-3 border border-purple-500 border-opacity-20 bg-gray-800 shadow-md transition-all hover:shadow-lg hover:translate-y-[-2px]">
              <div className="p-3 bg-purple-900 rounded-full">
                <Users className="h-3 w-3 text-purple-400" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Total Enquiries</p>
                <p className="text-2xl font-bold text-white">
                  {analyticsData.totalEnquiries}
                </p>
              </div>
            </div>
          </div>

          {/* Charts Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
            <div className="bg-gray-800 p-3 rounded-lg shadow-md border border-gray-700">
              <h3 className="text-lg font-medium text-gray-300 mb-4">
                Activity Over Time
              </h3>
              <div className="h-[300px]">
                <Line
                  data={prepareChartData().lineChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(156, 163, 175, 0.1)",
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                      x: {
                        grid: {
                          color: "rgba(156, 163, 175, 0.1)",
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                    },
                    plugins: {
                      legend: {
                        labels: { color: "rgb(156, 163, 175)" },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="bg-gray-800 p-3 rounded-lg shadow-md border border-gray-700">
              <h3 className="text-lg font-medium text-gray-300 mb-4">
                Interaction Distribution
              </h3>
              <div className="h-[300px]">
                <Pie
                  data={prepareChartData().pieChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: "bottom",
                        labels: { color: "rgb(156, 163, 175)" },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="bg-gray-800 p-3 rounded-lg shadow-md border border-gray-700 lg:col-span-1">
              <h3 className="text-lg font-medium text-gray-300 mb-4">
                Top Courses
              </h3>
              <div className="h-[300px]">
                <Bar
                  data={prepareChartData().barChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(156, 163, 175, 0.1)",
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                      x: {
                        grid: {
                          display: false,
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                    },
                    plugins: {
                      legend: {
                        labels: { color: "rgb(156, 163, 175)" },
                      },
                    },
                  }}
                />
              </div>
            </div>

            {/* Country Distribution Chart */}
            <div className="bg-gray-800 p-3 rounded-lg shadow-md border border-gray-700 lg:col-span-1">
              <h3 className="text-lg font-medium text-gray-300 mb-4">
                User Distribution by Country
              </h3>
              <div className="h-[300px]">
                <Bar
                  data={prepareChartData().countryChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: "y",
                    scales: {
                      x: {
                        beginAtZero: true,
                        grid: {
                          color: "rgba(156, 163, 175, 0.1)",
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                        ticks: { color: "rgb(156, 163, 175)" },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) =>
                            `${context.parsed.x} interactions`,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          {/* Sorting Button */}
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-300">
              Recent Course Clicks
            </h3>
            <button
              onClick={toggleSortOrder}
              className="flex items-center bg-[#252a37] text-white px-4 py-2 rounded-lg hover:bg-[#323a4d] transition-colors shadow-md"
            >
              <ArrowUpDown className="h-5 w-5 mr-2" />
              Sort: {sortOrder === "desc" ? "Most Clicked" : "Least Clicked"}
            </button>
          </div>

          {/* Course Clicks Accordion */}
          <div className="space-y-4">
            {Object.keys(analyticsData.uniqueCourses)
              .sort((a, b) =>
                sortOrder === "desc"
                  ? analyticsData.uniqueCourses[b].clicks -
                    analyticsData.uniqueCourses[a].clicks
                  : analyticsData.uniqueCourses[a].clicks -
                    analyticsData.uniqueCourses[b].clicks
              )
              .map((courseName, index) => (
                <div
                  key={index}
                  className="bg-gray-800 rounded-lg border border-gray-700 shadow-md overflow-hidden"
                >
                  {/* Accordion Header */}
                  <button
                    onClick={() =>
                      setExpandedCourse(
                        expandedCourse === courseName ? null : courseName
                      )
                    }
                    className="w-full flex justify-between items-center p-4 text-left text-gray-300 font-medium focus:outline-none hover:bg-[#252a37] transition-colors"
                  >
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-5 w-5 text-orange-500" />
                      <span className="text-white">{courseName}</span>
                      <span className="ml-2 text-sm text-gray-400 bg-[#252a37] px-2 py-1 rounded-full">
                        {analyticsData.uniqueCourses[courseName].clicks} clicks
                      </span>
                    </div>
                    {expandedCourse === courseName ? (
                      <ChevronUp className="h-5 w-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-400" />
                    )}
                  </button>

                  {/* Accordion Content */}
                  {expandedCourse === courseName && (
                    <div className="p-4 border-t border-gray-700 bg-gray-800">
                      <p className="text-sm text-gray-400 mb-3">
                        Users who clicked:
                      </p>
                      <div className="overflow-x-auto rounded-lg border border-gray-700">
                        <table className="min-w-full divide-y divide-gray-700">
                          <thead>
                            <tr>
                              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                                Name
                              </th>
                              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                                Email
                              </th>
                              <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                                Time
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-700 bg-gray-800">
                            {analyticsData.uniqueCourses[courseName].users
                              .slice(
                                page * rowsPerPage,
                                (page + 1) * rowsPerPage
                              )
                              .map((click, i) => (
                                <tr
                                  key={i}
                                  className={`hover:bg-[#252a37] transition-colors ${
                                    i % 2 === 0 ? "" : "bg-[#20252f]"
                                  }`}
                                >
                                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-300">
                                    {click.name || "N/A"}
                                  </td>
                                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-300">
                                    {click.email}
                                  </td>
                                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-300">
                                    {formatDate(click.timestamp)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>

                        {/* Pagination controls */}
                        <div className="flex items-center justify-between border-t border-gray-700 px-4 py-3 bg-[#252a37]">
                          <div className="flex-1 flex justify-between sm:hidden">
                            <button
                              onClick={() => setPage(Math.max(0, page - 1))}
                              disabled={page === 0}
                              className="relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-[#252a37] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            >
                              Previous
                            </button>
                            <button
                              onClick={() =>
                                setPage(
                                  Math.min(
                                    Math.ceil(
                                      analyticsData.uniqueCourses[courseName]
                                        .users.length / rowsPerPage
                                    ) - 1,
                                    page + 1
                                  )
                                )
                              }
                              disabled={
                                page >=
                                Math.ceil(
                                  analyticsData.uniqueCourses[courseName].users
                                    .length / rowsPerPage
                                ) -
                                  1
                              }
                              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-[#252a37] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            >
                              Next
                            </button>
                          </div>
                          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                              <p className="text-sm text-gray-400">
                                Showing{" "}
                                <span className="font-medium text-white">
                                  {page * rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium text-white">
                                  {Math.min(
                                    (page + 1) * rowsPerPage,
                                    analyticsData.uniqueCourses[courseName]
                                      .users.length
                                  )}
                                </span>{" "}
                                of{" "}
                                <span className="font-medium text-white">
                                  {
                                    analyticsData.uniqueCourses[courseName]
                                      .users.length
                                  }
                                </span>{" "}
                                results
                              </p>
                            </div>
                            <div>
                              <nav
                                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                aria-label="Pagination"
                              >
                                <button
                                  onClick={() => setPage(Math.max(0, page - 1))}
                                  disabled={page === 0}
                                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-[#252a37] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                >
                                  <span className="sr-only">Previous</span>
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>

                                {/* Page buttons - show 5 pages max */}
                                {[
                                  ...Array(
                                    Math.min(
                                      5,
                                      Math.ceil(
                                        analyticsData.uniqueCourses[courseName]
                                          .users.length / rowsPerPage
                                      )
                                    )
                                  ),
                                ].map((_, i) => {
                                  const pageNumber = i + Math.max(0, page - 2);
                                  if (
                                    pageNumber <
                                    Math.ceil(
                                      analyticsData.uniqueCourses[courseName]
                                        .users.length / rowsPerPage
                                    )
                                  ) {
                                    return (
                                      <button
                                        key={pageNumber}
                                        onClick={() => setPage(pageNumber)}
                                        className={`relative inline-flex items-center px-4 py-2 border ${
                                          page === pageNumber
                                            ? "border-orange-500 bg-[#252a37] text-orange-500"
                                            : "border-gray-700 bg-gray-800 text-gray-300 hover:bg-[#252a37]"
                                        } text-sm font-medium transition-colors`}
                                      >
                                        {pageNumber + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}

                                <button
                                  onClick={() =>
                                    setPage(
                                      Math.min(
                                        Math.ceil(
                                          analyticsData.uniqueCourses[
                                            courseName
                                          ].users.length / rowsPerPage
                                        ) - 1,
                                        page + 1
                                      )
                                    )
                                  }
                                  disabled={
                                    page >=
                                    Math.ceil(
                                      analyticsData.uniqueCourses[courseName]
                                        .users.length / rowsPerPage
                                    ) -
                                      1
                                  }
                                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-700 bg-gray-800 text-sm font-medium text-gray-300 hover:bg-[#252a37] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                >
                                  <span className="sr-only">Next</span>
                                  <svg
                                    className="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>

          {/* Add Enquiries Section */}
          <div className="mt-8 mb-8">
            <h3 className="text-lg font-medium text-gray-300 mb-4">
              Recent Enquiries
            </h3>
            <div className="bg-gray-800 rounded-lg overflow-hidden border border-gray-700 shadow-md">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Contact
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Location
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                      Timestamp
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-800 divide-y divide-gray-700">
                  {analyticsData.enquiryClicks.map((enquiry, index) => (
                    <tr
                      key={index}
                      className="hover:bg-[#252a37] transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium text-gray-300">
                              {enquiry.name}
                            </div>
                            <div className="text-sm text-gray-400">
                              {enquiry.gender}, {enquiry.age}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-300">
                          {enquiry.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-300">
                          {enquiry.city}, {enquiry.state}
                        </div>
                        <div className="text-sm text-gray-400">
                          {enquiry.country}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {formatDate(enquiry.timestamp)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
