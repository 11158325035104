import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Footer from "../sub-components/Footer";
import BusinessNav from "../sub-components/BusinessNav";
import { firestore } from "../../../firebase";
import LoginVector from "../../../assets/img/admin_login.png";
import CustomDropdown from "../../Snippets/Dropdown.js";
// 1) Import from your AuthProvider instead of Firebase Auth directly
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import ClipLoader from "react-spinners/ClipLoader";

// 2) Reuse your validators
import {
  validateEmail,
  validatePasswordStrength,
} from "../../../utils/validators";

// If you have any brand images, like googleSvg, import them if used

const BusinessLoginPage = () => {
  const navigate = useNavigate();

  // 3) Get your Auth functions from context
  const {
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    resetPassword,
  } = useAuth();

  // Local states
  const [isLogin, setIsLogin] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    organizationType: "",
    showPassword: false, // for sign-up password toggle
  });

  const organizationTypes = [
    "Events",
    "Restaurants",
    "Accommodation",
    "Jobs",
    "University Ambassador",
  ];
  const disabledTypes = [];

  // --- Form & UI Toggles ---
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilitySignup = () => {
    setFormData((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  const toggleToSignup = () => {
    setIsLogin(false);
    setCurrentStep(1);
    setIsForgotPassword(false);
    // Clear old form data
    setFormData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      companyName: "",
      organizationType: "",
      showPassword: false,
    });
  };
  const toggleToLogin = () => {
    setIsLogin(true);
    setIsForgotPassword(false);
    // Clear old form data
    setFormData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      companyName: "",
      organizationType: "",
      showPassword: false,
    });
  };
  const toggleForgotPassword = () => {
    setIsForgotPassword(true);
  };
  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNextStep = () => {
    // --- MANUAL VALIDATION for Step 1 ---
    // This runs when the "Next Step" button (type="button") is clicked
    if (!formData.firstName) {
      toast.error("Please enter your first name.");
      return; // Stop if invalid
    }
    if (!formData.lastName) {
      toast.error("Please enter your last name.");
      return; // Stop if invalid
    }
    if (!formData.email) {
      toast.error("Please enter your email address.");
      return; // Stop if invalid
    }
    // Optional: Add email format validation
    if (!formData.password) {
      toast.error("Please enter your password.");
      return; // Stop if invalid
    }
    // Optional: Add password strength validation here
    // --- END MANUAL VALIDATION for Step 1 ---

    // If validation passes, proceed to the next step
    setCurrentStep(2);
  };
  const handlePreviousStep = () => setCurrentStep((prev) => prev - 1);

  // --- Auth Logic ---

  // 1) Sign Up
  const handleSignUp = async (event) => {
    event.preventDefault();
    // Basic validations
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (!validatePasswordStrength(formData.password)) {
      toast.error(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character."
      );
      return;
    }
    setIsSignUpLoading(true);

    try {
      // Create the user via Auth context
      const userCredential = await signUpWithEmailAndPassword(
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      // Create Firestore doc
      await setDoc(doc(firestore, "users", user.uid), {
        userId: user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        companyName: formData.companyName,
        organizationType: formData.organizationType,
        accountType: "business",
        createdAt: Timestamp.now(),
        role: "admin", // or your chosen role
      });

      toast.success("Signup successful! Please log in.");
      toggleToLogin(); // Switch to the login form
    } catch (error) {
      console.error("Signup Error:", error);
      toast.error(error.message);
    } finally {
      setIsSignUpLoading(false);
    }
  };

  // 2) Login
  const handleLogin = async () => {
    setIsLoginLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      // Fetch user doc to see if it's a business account & route them
      const userDoc = await getDoc(doc(firestore, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.accountType === "business") {
          switch (userData.organizationType) {
            case "Events":
              navigate("/business/events/analytics");
              toast.success("Login successful!");
              break;
            case "Accommodation":
              navigate("/business/accommodation/add");
              toast.success("Login successful!");
              break;
            case "Restaurants":
              navigate("/business/restaurants");
              toast.success("Login successful!");
              break;
            case "Jobs":
              navigate("/business/jobs/dashboard");
              toast.success("Login successful!");
              break;
            case "University Ambassador":
              navigate("/business/university/upload/info");
              toast.success("Login successful!");
              break;
            default:
              toast.error("Unknown organization type. Please contact support.");
          }
        } else {
          toast.error(
            "email already registered as user, please create a new business account"
          );
          throw new Error(
            "Unauthorized access: Account is not a business account."
          );
        }
      } else {
        toast.error("No account found");
        throw new Error("User data not found.");
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Login failed. Please check your email and password.");
    } finally {
      setIsLoginLoading(false);
    }
  };

  // 3) Forgot Password
  const handleForgotPassword = async () => {
    if (!formData.email) {
      toast.error("Please enter your email address.");
      return;
    }
    setIsResetLoading(true);

    try {
      await resetPassword(formData.email);
      toast.success("Password reset email sent. Please check your inbox.");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      toast.error("Failed to send password reset email. Please try again.");
    } finally {
      setIsResetLoading(false);
    }
  };

  return (
    <div className="flex flex-col ">
      <BusinessNav />
      <div className="min-h-screen bg-gray-800">
        <div className="flex flex-col md:flex-row h-screen">
          <div className="hidden md:flex md:w-1/2 justify-center items-center">
            <img src={LoginVector} className="max-w-full h-auto"></img>
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center text-white min-h-screen md:min-h-0">
            <div className="w-11/12 max-w-md bg-white text-secondary p-8 rounded-3xl my-8 md:my-0">
              <div
                className={isLogin && !isForgotPassword ? "block" : "hidden"}
              >
                <h1 className="text-2xl font-bold mb-6">Login</h1>
                <p className="my-3 text-sm text-gray-400">
                  Login to your account to access Wordlynk's business dashboard.
                </p>
                <div className="relative mb-3">
                  <input
                    type="email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>
                <div className="relative mb-3">
                  <input
                    type={formData.showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder=" "
                    required
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="password"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Password
                  </label>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <button
                      type="button"
                      onClick={togglePasswordVisibilitySignup}
                      className="focus:outline-none"
                    >
                      {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleLogin}
                    disabled={isLoginLoading}
                  >
                    {isLoginLoading ? (
                      <>
                        <span className="opacity-0">Login Account</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isLoginLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Login Account"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Not a member?{" "}
                    <span
                      onClick={toggleToSignup}
                      className="text-blue-600 cursor-pointer"
                    >
                      Sign up now
                    </span>
                  </p>
                </div>
                <div className="mt-4 text-center">
                  <span
                    onClick={toggleForgotPassword}
                    className="text-red-600 cursor-pointer"
                  >
                    Forgot Password?
                  </span>
                </div>
              </div>

              <div className={isForgotPassword ? "block" : "hidden"}>
                <h1 className="text-2xl font-bold mb-6">Forgot Password</h1>
                <div className="relative mb-4">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    placeholder=" "
                    className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor="email"
                    className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                  >
                    Email Address
                  </label>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                    onClick={handleForgotPassword}
                    disabled={isResetLoading}
                  >
                    {isResetLoading ? (
                      <>
                        <span className="opacity-0">Reset Password</span>
                        <div className="absolute inset-0 flex items-center justify-center">
                          <ClipLoader
                            loading={isResetLoading}
                            size={20}
                            color="#ffffff"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Remember your password?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={toggleToLogin}
                    >
                      Login now
                    </span>
                  </p>
                </div>
              </div>

              <form
                className={!isLogin ? "block" : "hidden"}
                onSubmit={handleSignUp}
              >
                <h1 className="text-2xl font-bold mb-6">Sign Up</h1>
                <p className="my-3 text-sm text-gray-400">
                  Create your account to access Wordlynk's business dashboard.
                </p>

                {currentStep === 1 && (
                  <div>
                    <div className="relative mb-3">
                      <input
                        type="text"
                        name="firstName"
                        required
                        value={formData.firstName}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="firstName"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        First Name
                      </label>
                    </div>
                    <div className="relative mb-3">
                      <input
                        type="text"
                        name="lastName"
                        required
                        value={formData.lastName}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="lastName"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        Last Name
                      </label>
                    </div>
                    <div className="relative mb-3">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder=" "
                        required
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="email"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        Email Address
                      </label>
                    </div>
                    <div className="relative mb-3">
                      <input
                        type={formData.showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder=" "
                        required
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="password"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        Password
                      </label>
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                        <button
                          type="button"
                          onClick={togglePasswordVisibilitySignup}
                          className="focus:outline-none"
                        >
                          {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <button
                        onClick={handleNextStep}
                        className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700"
                      >
                        Next Step
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-sm text-gray-600 mb-2">
                        Already a member?{" "}
                        <span
                          onClick={toggleToLogin}
                          className="text-blue-600 cursor-pointer"
                        >
                          Login now
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <div className="relative mb-3">
                      <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                      <label
                        htmlFor="companyName"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        Company Name
                      </label>
                    </div>
                    <div className="relative mb-3">
                      <select
                        name="organizationType"
                        value={formData.organizationType}
                        onChange={handleInputChange}
                        required
                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-xs text-gray-800 border border-gray-300 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      >
                        <option value="">
                          <p className="text-xs">Select Organization Type</p>
                        </option>
                        {organizationTypes.map((type) => (
                          <option
                            key={type}
                            value={type}
                            disabled={disabledTypes.includes(type)}
                          >
                            {type}
                          </option>
                        ))}
                      </select>
                      <label
                        htmlFor="organizationType"
                        className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600"
                      >
                        Organization Type
                      </label>
                    </div>
                    <div className="mt-6">
                      <button
                        type="submit"
                        className="w-full py-2 bg-orange-600 text-white rounded-xl hover:bg-orange-700 relative"
                        // onClick={handleSignUp}
                        disabled={isSignUpLoading}
                      >
                        {isSignUpLoading ? (
                          <>
                            <span className="opacity-0">Register Account</span>
                            <div className="absolute inset-0 flex items-center justify-center">
                              <ClipLoader
                                loading={isSignUpLoading}
                                size={20}
                                color="#ffffff"
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          </>
                        ) : (
                          "Register Account"
                        )}
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <button
                        type="button"
                        onClick={handlePreviousStep}
                        className="text-blue-600 hover:underline"
                      >
                        Previous Step
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BusinessLoginPage;
