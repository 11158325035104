import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../auth/userProvider/AuthProvider";
import { firestore, storage } from "../../firebase";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useLoadScript } from "@react-google-maps/api";
import { MapPin } from "lucide-react";
import placeholder_image from "../../assets/img/placeholder_image.png";

// Libraries array for Google Maps
const libraries = ["places"];

// Separate Location Field Components
const LocationField = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  error,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <label className="block text-gray-400 text-sm font-medium mb-1">
        {label} *
      </label>
      <div className="flex items-center bg-gray-800 rounded-md border border-gray-700 focus-within:border-purple-500 transition duration-200">
        <MapPin className="ml-3 text-gray-400" size={18} />
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          className="w-full p-2.5 bg-transparent text-white rounded-md outline-none"
          onChange={(e) => onChange(e.target.value)}
          onFocus={(e) => {
            const input = e.target;
            const options = {
              types:
                type === "city"
                  ? ["(cities)"]
                  : type === "state"
                  ? ["administrative_area_level_1"]
                  : ["country"],
            };

            const autocomplete = new window.google.maps.places.Autocomplete(
              input,
              options
            );
            autocomplete.addListener("place_changed", () => {
              const place = autocomplete.getPlace();
              let selectedValue = "";

              if (place.address_components) {
                const component = place.address_components[0];
                selectedValue = component.long_name;
              }

              onChange(selectedValue);
            });
          }}
        />
      </div>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

const EditProfile = () => {
  const { userData } = useAuth();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    profileImage: "",
    university: "",
    city: "",
    state: "",
    country: "",
    age: "",
    gender: "male",
  });

  const [profileImageFile, setProfileImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    university: "",
    city: "",
    state: "",
    country: "",
    age: "",
    gender: "",
  });

  // Fetch user data from Firestore on component mount
  useEffect(() => {
    if (userData?.userId) {
      const docRef = doc(collection(firestore, "users"), userData.userId);
      getDoc(docRef)
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            setProfileData({
              name: data.name || "",
              email: data.email || "",
              phone: data.phone || "",
              profileImage: data.profileImage || "",
              university: data.university || "",
              city: data.city || "",
              state: data.state || "",
              country: data.country || "",
              age: data.age || "",
              gender: data.gender || "male",
            });
            setPreviewImage(data.profileImage || "");
            setExistingData(data);
          }
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [userData?.userId]);

  // Handle form input changes
  const handleInputChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle city change
  const handleCityChange = (value) => {
    setProfileData((prev) => ({ ...prev, city: value }));
  };

  // Handle state change
  const handleStateChange = (value) => {
    setProfileData((prev) => ({ ...prev, state: value }));
  };

  // Handle country change
  const handleCountryChange = (value) => {
    setProfileData((prev) => ({ ...prev, country: value }));
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImageFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Validate form before submission
  const validateForm = () => {
    const newErrors = {};

    if (!profileData.name) newErrors.name = "Name is required";
    if (!profileData.phone) newErrors.phone = "Phone number is required";
    if (!profileData.university)
      newErrors.university = "University is required";
    if (!profileData.city) newErrors.city = "City is required";
    if (!profileData.state) newErrors.state = "State is required";
    if (!profileData.country) newErrors.country = "Country is required";
    if (!profileData.age) {
      newErrors.age = "Age is required";
    } else if (isNaN(profileData.age) || parseInt(profileData.age) <= 0) {
      newErrors.age = "Please enter a valid age";
    }
    if (!profileData.gender) newErrors.gender = "Gender is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle profile update submission
  const handleProfileUpdate = async () => {
    if (!validateForm()) return;
    setLoading(true);

    try {
      let profileImageUrl = profileData.profileImage;

      // If a new profile image is uploaded, store it in Firebase Storage
      if (profileImageFile) {
        const storageRef = ref(storage, `/users/${userData.userId}/profileImg`);
        await uploadBytes(storageRef, profileImageFile);
        profileImageUrl = await getDownloadURL(storageRef);
      }

      // Prepare updated data
      const updatedData = {
        name: profileData.name,
        email: profileData.email,
        phone: profileData.phone,
        profileImage: profileImageUrl,
        university: profileData.university,
        city: profileData.city,
        state: profileData.state,
        country: profileData.country,
        age: profileData.age,
        gender: profileData.gender,
        updatedAt: serverTimestamp(),
      };

      // Update document in Firestore
      const docRef = doc(collection(firestore, "users"), userData.userId);
      await updateDoc(docRef, {
        ...existingData,
        ...updatedData,
      });

      toast.success("Profile updated successfully!");
      navigate("/user-dashboard/reliv/myworlds");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center relative rounded-lg p-6 max-w-2xl mx-auto">
      <button
        onClick={() => window.history.back()}
        className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
      >
        <FaArrowLeft />
      </button>

      {/* Main content container */}
      <div className="w-full max-w-md">
        {/* Header */}
        <h2 className="text-2xl font-bold mb-8 text-center text-gradient bg-gradient-to-r from-purple-400 to-pink-500 inline-block bg-clip-text text-transparent mt-6">
          Edit Profile
        </h2>

        <form className="space-y-5">
          {/* Profile Picture */}
          <div className="flex justify-center mb-6">
            <div className="relative">
              <img
                src={previewImage || placeholder_image}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover border-2 border-purple-500"
              />
              <label className="absolute bottom-0 right-0 bg-purple-500 rounded-full p-1 cursor-pointer shadow-lg">
                <input
                  type="file"
                  className="hidden"
                  onChange={handleImageUpload}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </label>
            </div>
          </div>

          {/* Name Input */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1">
              Name *
            </label>
            <input
              type="text"
              name="name"
              className={`w-full p-2.5 bg-gray-800 border ${
                errors.name ? "border-red-500" : "border-gray-700"
              } focus:border-purple-500 focus:ring-1 focus:ring-purple-500 rounded-md transition duration-200 outline-none`}
              placeholder="Enter your name"
              value={profileData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-500">{errors.name}</p>
            )}
          </div>

          {/* Email Input (non-editable) */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="w-full p-2.5 bg-gray-700 border border-gray-600 rounded-md cursor-not-allowed text-gray-400"
              value={profileData.email}
              disabled
            />
          </div>

          {/* Phone Input */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1">
              Phone *
            </label>
            <input
              type="tel"
              name="phone"
              className={`w-full p-2.5 bg-gray-800 border ${
                errors.phone ? "border-red-500" : "border-gray-700"
              } focus:border-purple-500 focus:ring-1 focus:ring-purple-500 rounded-md transition duration-200 outline-none`}
              placeholder="Enter your phone number"
              value={profileData.phone}
              onChange={handleInputChange}
              required
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
            )}
          </div>

          {/* Location Fields */}
          <div className="space-y-4">
            <LocationField
              label="City"
              placeholder="Enter your city"
              value={profileData.city}
              onChange={handleCityChange}
              type="city"
              error={errors.city}
            />
            <LocationField
              label="State/Region"
              placeholder="Enter your state"
              value={profileData.state}
              onChange={handleStateChange}
              type="state"
              error={errors.state}
            />
            <LocationField
              label="Country"
              placeholder="Enter your country"
              value={profileData.country}
              onChange={handleCountryChange}
              type="country"
              error={errors.country}
            />
          </div>

          {/* Age Input */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1">
              Age *
            </label>
            <input
              type="number"
              name="age"
              className={`w-full p-2.5 bg-gray-800 border ${
                errors.age ? "border-red-500" : "border-gray-700"
              } focus:border-purple-500 focus:ring-1 focus:ring-purple-500 rounded-md transition duration-200 outline-none`}
              placeholder="Enter your age"
              value={profileData.age}
              onChange={handleInputChange}
              required
              min="1"
            />
            {errors.age && (
              <p className="mt-1 text-sm text-red-500">{errors.age}</p>
            )}
          </div>

          {/* Improved Gender Options */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-2">
              Gender *
            </label>
            <div className="grid grid-cols-3 gap-4">
              {["male", "female", "other"].map((option) => (
                <label
                  key={option}
                  className={`flex items-center justify-center p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                    profileData.gender === option
                      ? "bg-purple-500 border-purple-600"
                      : "bg-gray-800 border-gray-700 hover:bg-gray-700"
                  } border`}
                >
                  <input
                    type="radio"
                    name="gender"
                    value={option}
                    checked={profileData.gender === option}
                    onChange={handleInputChange}
                    className="hidden"
                  />
                  <span className="capitalize">{option}</span>
                </label>
              ))}
            </div>
            {errors.gender && (
              <p className="mt-1 text-sm text-red-500">{errors.gender}</p>
            )}
          </div>

          {/* University Input */}
          <div>
            <label className="block text-gray-400 text-sm font-medium mb-1">
              University *
            </label>
            <input
              type="text"
              name="university"
              className={`w-full p-2.5 bg-gray-800 border ${
                errors.university ? "border-red-500" : "border-gray-700"
              } focus:border-purple-500 focus:ring-1 focus:ring-purple-500 rounded-md transition duration-200 outline-none`}
              placeholder="Enter your university"
              value={profileData.university}
              onChange={handleInputChange}
              required
            />
            {errors.university && (
              <p className="mt-1 text-sm text-red-500">{errors.university}</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="button"
            className={`w-full py-3 mt-6 font-medium bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 rounded-md transition-all duration-200 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleProfileUpdate}
            disabled={loading}
          >
            {loading ? "Updating..." : "Save Changes"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
