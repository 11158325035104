import React from "react";
import { useParams } from "react-router-dom";
import { courses } from "./data/Courses";
import { Star } from "lucide-react";

const CourseDetail = () => {
  const { courseId } = useParams();
  const course = courses.find((c) => c.id === parseInt(courseId));

  if (!course) {
    return (
      <div className="flex items-center justify-center min-h-screen text-white text-lg font-semibold">
        Course not found.
      </div>
    );
  }

  const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split("v=")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen pb-10">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row items-center gap-8">
          {/* Left Section - Logo and Details */}
          <div className="w-full md:w-1/2 flex flex-col space-y-2">
            {/* Course Header */}
            <div className="">
              <h1 className="text-3xl md:text-4xl font-bold text-orange-400">{course.title}</h1>
              <p className="text-gray-400 mt-3 md:text-lg">Instructor: {course.instructor}</p>
              <div className="flex justify-start items-center gap-2 mt-2 md:text-lg">
                <Star className="text-yellow-400 w-6 h-6" />
                <span>{course.rating} / 5</span>
              </div>
              <p className="text-gray-400 mt-2">Duration: {course.duration} | Level: {course.level}</p>
              <div className="flex flex-wrap justify-start gap-2 mt-4">
                {course.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="border-2 text-white py-1 px-3 rounded-full text-xs md:text-sm border-orange-500"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 rounded-xl">
            {/* Video Section */}
            <div className="">
              <div className="rounded-lg overflow-hidden shadow-lg">
                <iframe
                  className="w-full h-[400px]"
                  src={getYouTubeEmbedUrl(course.videoUrl)}
                  title={course.title}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* Course Details */}
        <div className="mt-5">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 border-b border-gray-700 pb-2">Course Description</h2>
          <p className="text-gray-300 leading-relaxed">{course.description}</p>
        </div>

        {/* Resources */}
        <div className="mt-5">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 border-b border-gray-700 pb-2">Resources</h2>
          <ul className="list-disc list-inside text-gray-300 space-y-2">
            {course.resources.map((resource, index) => (
              <li key={index}>
                <a
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-orange-400 hover:underline"
                >
                  {resource.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Reviews */}
        <div className="mt-5">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 border-b border-gray-700 pb-2">Student Reviews</h2>
          <div className="space-y-4">
            {course.reviews.map((review, index) => (
              <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
                <p className="md:text-lg font-semibold text-orange-300">{review.user}</p>
                <p className="text-gray-300 mt-2 leading-relaxed">{review.comment}</p>
                <div className="flex items-center mt-2">
                  <Star className="text-yellow-400 w-5 h-5" />
                  <span className="ml-1 text-gray-300">{review.rating} / 5</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
