import React, { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { firestore, auth, storage } from "../../../firebase";
import { deleteUser } from "firebase/auth";
import {
  Building2,
  Shield,
  Mail,
  User,
  Briefcase,
  Calendar,
  Settings as SettingsIcon,
  UserX,
} from "lucide-react";
import {
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";
import { ref, listAll, deleteObject } from "firebase/storage";
import toast from "react-hot-toast";

Modal.setAppElement("#root");

const Settings = () => {
  const { userData } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Format timestamp to readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  const deleteAccount = async () => {
    if (!userData.userId) {
      toast.error("User ID not found!");
      return;
    }

    try {
      const universitiesRef = collection(firestore, "universities");
      const uniQuery = query(
        universitiesRef,
        where("userId", "==", userData.userId)
      );
      const uniDocs = await getDocs(uniQuery);

      if (!uniDocs.empty) {
        const universityDoc = uniDocs.docs[0];
        const universityData = universityDoc.data();
        const universityId = universityDoc.id;

        const userStorageRef = ref(storage, `${userData.userId}/`);
        const storageList = await listAll(userStorageRef);
        storageList.items.forEach(async (fileRef) => {
          await deleteObject(fileRef);
        });

        await deleteDoc(doc(firestore, "universities", universityId));
      }

      const usersRef = collection(firestore, "users");
      const userQuery = query(usersRef, where("userId", "==", userData.userId));
      const userDocs = await getDocs(userQuery);

      if (!userDocs.empty) {
        await deleteDoc(doc(firestore, "users", userDocs.docs[0].id));
      }

      const currentUser = auth.currentUser;
      if (currentUser && currentUser.uid === userData.userId) {
        await deleteUser(currentUser);
        toast.success("Account deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Failed to delete account.");
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-3xl font-bold text-white flex items-center gap-2">
          <SettingsIcon className="w-8 h-8" />
          Account Settings
        </h2>
      </div>

      {/* Profile Information Card */}
      <div className="bg-gray-800 rounded-lg p-6 shadow-lg border border-red-800">
        <h3 className="text-xl font-semibold text-white mb-6 flex items-center gap-2">
          <User className="w-5 h-5" />
          Profile Information
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="flex items-center gap-3 text-gray-300">
              <Building2 className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Company Name</p>
                <p className="font-medium">{userData.companyName || "N/A"}</p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-gray-300">
              <Mail className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Email Address</p>
                <p className="font-medium">{userData.email}</p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-gray-300">
              <Shield className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Role</p>
                <p className="font-medium capitalize">{userData.role}</p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-3 text-gray-300">
              <Briefcase className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Organization Type</p>
                <p className="font-medium">{userData.organizationType}</p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-gray-300">
              <Calendar className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Account Created</p>
                <p className="font-medium">{formatDate(userData.createdAt)}</p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-gray-300">
              <User className="w-5 h-5" />
              <div>
                <p className="text-sm text-gray-400">Account Type</p>
                <p className="font-medium capitalize">{userData.accountType}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Danger Zone Card */}
      <div className="bg-gray-800 rounded-lg p-6 shadow-lg border border-red-800">
        <h3 className="text-xl font-semibold text-red-500 mb-6 flex items-center gap-2">
          <UserX className="w-5 h-5" />
          Danger Zone
        </h3>
        <p className="text-gray-400 mb-4">
          Once you delete your account, there is no going back. Please be
          certain.
        </p>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded flex items-center gap-2"
        >
          <UserX className="w-4 h-4" />
          Delete Account
        </button>
      </div>

      {/* Modal remains unchanged */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="bg-gray-800 text-white p-6 rounded-lg max-w-4xl mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h3 className="text-lg font-semibold text-white mb-4">
          Are you sure you want to delete your account? This action is
          irreversible.
        </h3>
        <div className="flex justify-between">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-500 hover:bg-gray-400 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            onClick={deleteAccount}
            className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded"
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
