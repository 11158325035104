import React, { memo } from "react";

const SearchBar = ({ searchTerm, onSearchChange, onClear }) => (
  <div className="flex flex-col sm:flex-row items-center mb-4 space-y-3 sm:space-y-0 sm:space-x-3">
    <div className="flex-grow w-full sm:w-auto">
      <div className="flex items-center w-full border border-orange-600 rounded bg-transparent">
        <span className="pl-2 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35m0 0a7 7 0 111.4-1.4m-1.4 1.4L16.65 16.65z"
            />
          </svg>
        </span>
        <input
          type="text"
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search universities..."
          className="bg-transparent text-gray-100 py-1.5 pl-2 pr-2 w-full text-sm focus:outline-none"
        />
      </div>
    </div>
    <div className="flex space-x-2 w-full sm:w-auto">
      <button
        onClick={onClear}
        className="bg-gray-50 text-black rounded font-bold px-2 py-1 text-sm hover:bg-gray-100 transition duration-200 w-full sm:w-auto"
      >
        Clear
      </button>
      <button className="bg-orange-600 text-white rounded font-bold px-2 py-1 text-sm hover:bg-orange-700 transition duration-200 w-full sm:w-auto">
        Search
      </button>
    </div>
  </div>
);

export default memo(SearchBar);
