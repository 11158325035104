import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/userProvider/AuthProvider.js";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebase.js";
import animationData from "../../../assets/lotties/loader1.json";
import Lottie from "react-lottie";
import DashboardNav from "./dashboardNav.js";
import Sidebar from "./sidebar/Sidebar.js";
import ProfileUpdateDialog from "./ProfileUpdateDialog"; // Import the dialog

const UserDashboard = () => {
  const { userData } = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showProfileUpdateDialog, setShowProfileUpdateDialog] = useState(false); // State for dialog

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
      if (window.innerWidth < 1400) {
        setIsSidebarExpanded(false);
      } else {
        setIsSidebarExpanded(true); // Expand when above 1400px
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!userData) return;

    // Check if any of the required fields are missing
    const requiredFields = [
      "name",
      "email",
      "phone",
      "gender",
      "university",
      "city",
      "country",
      "state",
      "age",
      "gender",
    ];
    const missingFields = requiredFields.filter(
      (field) => !userData[field] || userData[field] === ""
    );

    console.log("Missing fields:", missingFields);
    console.log(missingFields);
    // Show the dialog if there are missing fields
    if (missingFields.length > 0) {
      console.log("missing fields", missingFields);
      setTimeout(() => setShowProfileUpdateDialog(true), 1500);
    }

    setLoading(false);
    setInitialLoad(false);
  }, [userData]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            setUserLocation(location);
            localStorage.setItem("userLocation", JSON.stringify(location));
          },
          (error) => {
            console.error("Error fetching location:", error.message);
          },
          { timeout: 10000, enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    if (userData) {
      getUserLocation();
    }
  }, [userData]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  // Function to close the dialog
  const closeDialog = () => {
    setShowProfileUpdateDialog(false);
  };

  return (
    <div className="user-dashboard bg-gray-900 relative">
      {isMobile && (
        <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
          <DashboardNav userLocation={userLocation} />
        </div>
      )}

      {!loading && (
        <div className="content z-10">
          {!isMobile && (
            <Sidebar
              isSidebarExpanded={isSidebarExpanded}
              toggleSidebar={toggleSidebar}
              handleLogout={handleLogout}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}

          {/* Main Content */}
          <div
            className="flex-grow py-2"
            style={{
              marginLeft: !isMobile ? (isSidebarExpanded ? "20%" : "7%") : "0",
              // paddingLeft: '10px',
            }}
          >
            {userData ? (
              <>
                <Outlet context={{ user: userData, location: userLocation }} />

                {/* Show the profile update dialog if required */}
                {showProfileUpdateDialog && (
                  <ProfileUpdateDialog closeDialog={closeDialog} />
                )}
              </>
            ) : (
              <div className="overlay-container">
                <div className="flex items-center justify-center h-screen bg-gray-800">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                    }}
                    height={200}
                    width={200}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
