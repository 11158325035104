import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UniData from "./data/no_of_uni_updated";
import uni from "../../../assets/img/university-image.jpg";
import RecommendedUniversities from "./RecommendedUniversities";
import UniversityCard from "../../Snippets/UniversityCard";
import SearchBar from "../../Snippets/SearchBar";
import Pagination from "../../Snippets/Pagination";
import CirclurarLoader from "../../Snippets/CircularLoader";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { firestore } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import Banner from "../../Snippets/Banner";
import { BookOpen } from "lucide-react";

const University = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { userData } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const universitiesPerPage = 8;
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUniversities = useCallback(async () => {
    try {
      const universitiesRef = collection(firestore, "universities");
      const snapshot = await getDocs(universitiesRef);
      const universitiesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUniversities(universitiesList);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching universities:", err);
      setError("Failed to load universities");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUniversities();
  }, [fetchUniversities]);

  const filteredUniversities = useMemo(
    () =>
      Object.values(UniData).filter((university) =>
        university.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [searchTerm]
  );

  const totalPages = useMemo(
    () => Math.ceil(filteredUniversities.length / universitiesPerPage),
    [filteredUniversities.length, universitiesPerPage]
  );

  const paginatedUniversities = useMemo(
    () =>
      filteredUniversities.slice(
        (currentPage - 1) * universitiesPerPage,
        currentPage * universitiesPerPage
      ),
    [filteredUniversities, currentPage, universitiesPerPage]
  );

  const handleUniversityClick = useCallback(
    (universityId) => {
      navigate(`/user-dashboard/universities/${universityId}`);
    },
    [navigate]
  );

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchTerm("");
    setCurrentPage(1);
  }, []);
  const text =
    "Discover the rich history and academic excellence of institutions like Oxford and Cambridge. The UK offers a diverse range of educational experiences.";
  return (
    <div className="bg-gray-900 min-h-screen">
      {/* Hero section - always visible */}
      <Banner
        img={uni}
        showHostButton={true}
        heading="Find a perfect University"
        text={text}
        buttonText="Learning"
        url="/user-dashboard/learning"
        icon={<BookOpen />}
      />

      <div className="container mx-auto py-2 px-4">
        {/* Search bar - always visible */}
        <div className="mb-6">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            onClear={handleClearSearch}
          />
        </div>

        <div className="px-2">
          {loading ? (
            <div className="flex justify-center items-center">
              <CirclurarLoader />
            </div>
          ) : error ? (
            <div className="flex justify-center items-center h-[50vh]">
              <div className="text-red-500">{error}</div>
            </div>
          ) : searchTerm ? (
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-white mb-4">
                Search Results
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {paginatedUniversities.map((university) => (
                  <UniversityCard
                    key={university.id}
                    university={university}
                    onClick={handleUniversityClick}
                  />
                ))}
              </div>
              {filteredUniversities.length === 0 && (
                <p className="text-gray-400 text-center">
                  No universities found matching your search.
                </p>
              )}
            </div>
          ) : (
            <>
              {userData && (
                <>
                  <h2 className="text-base font-bold text-white mb-2">
                    Featured Universities
                  </h2>
                  <RecommendedUniversities universities={universities} />
                </>
              )}

              <div className="mt-8">
                <h2 className="text-base font-bold text-white mb-2">
                  Explore More Universities
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  {paginatedUniversities.map((university) => (
                    <UniversityCard
                      key={university.id}
                      university={university}
                      onClick={handleUniversityClick}
                    />
                  ))}
                </div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPrevPage={handlePrevPage}
                  onNextPage={handleNextPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default University;
