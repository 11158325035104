import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Search, Star } from "lucide-react";
import Banner from "../../Snippets/Banner";
import img from "../../../assets/img/learningBanner.jpg";
import CustomDropdown from "../../Snippets/Dropdown";
import Pagination from "../../Snippets/Pagination";
import { courses } from "./data/Courses";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
const COURSES_PER_PAGE = 6;

const CoursePlatform = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { userData } = useAuth();
  const text = `Welcome back, ${userData.name}! Continue your learning journey with top courses in personalized category & explore new opportunities to grow your skills.`;
  // Applied filters
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCategory, setFilterCategory] = useState("All");
  const [filterPrice, setFilterPrice] = useState("All");

  // Pending filters
  const [pendingSearchQuery, setPendingSearchQuery] = useState("");
  const [pendingFilterCategory, setPendingFilterCategory] = useState("All");
  const [pendingFilterPrice, setPendingFilterPrice] = useState("All");

  // Memoized unique categories
  const uniqueCategories = useMemo(
    () => ["All", ...new Set(courses.map((c) => c.category))],
    []
  );

  // Memoized filtered courses
  const filteredCourses = useMemo(() => {
    return courses.filter((course) => {
      const matchesSearch =
        !searchQuery ||
        course.title.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory =
        filterCategory === "All" || course.category === filterCategory;
      const matchesPrice =
        filterPrice === "All" ||
        (filterPrice === "Free" ? course.price === 0 : course.price > 0);

      return matchesSearch && matchesCategory && matchesPrice;
    });
  }, [searchQuery, filterCategory, filterPrice]);

  // Memoized pagination calculations
  const totalPages = useMemo(
    () => Math.ceil(filteredCourses.length / COURSES_PER_PAGE),
    [filteredCourses.length]
  );

  const paginatedCourses = useMemo(() => {
    const startIndex = (currentPage - 1) * COURSES_PER_PAGE;
    return filteredCourses.slice(startIndex, startIndex + COURSES_PER_PAGE);
  }, [filteredCourses, currentPage]);

  // Handlers with useCallback
  const handleApplyFilters = useCallback(() => {
    setSearchQuery(pendingSearchQuery);
    setFilterCategory(pendingFilterCategory);
    setFilterPrice(pendingFilterPrice);
    setCurrentPage(1); // Reset to first page when applying filters
  }, [pendingSearchQuery, pendingFilterCategory, pendingFilterPrice]);

  const handleClearFilters = useCallback(() => {
    // Clear both applied and pending filters
    setSearchQuery("");
    setFilterCategory("All");
    setFilterPrice("All");
    setPendingSearchQuery("");
    setPendingFilterCategory("All");
    setPendingFilterPrice("All");
    setCurrentPage(1);
  }, []);

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Banner
        img={img}
        showHostButton={false}
        heading="Worldlynk Learning."
        text={text}
      />

      <div className="container mx-auto py-6 px-4">
        {/* Search and Filters */}
        <div className="flex flex-col sm:flex-row items-center gap-4 mb-6">
          <div className="flex-grow w-full sm:w-auto">
            <div className="flex items-center w-full border-2 border-orange-600 rounded-md bg-gray-800">
              <span className="pl-3 text-gray-400">
                <Search className="w-5 h-5" />
              </span>
              <input
                type="text"
                placeholder="Search courses..."
                className="bg-transparent text-white py-2.5 pl-3 pr-3 w-full focus:outline-none"
                value={pendingSearchQuery}
                onChange={(e) => setPendingSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full sm:w-auto">
            <CustomDropdown
              options={uniqueCategories}
              value={pendingFilterCategory}
              onChange={(e) => setPendingFilterCategory(e.target.value)}
              placeholder="Select Category"
              labelText="Category"
            />
          </div>

          <div className="w-full sm:w-auto">
            <CustomDropdown
              options={["All", "Free", "Paid"]}
              value={pendingFilterPrice}
              onChange={(e) => setPendingFilterPrice(e.target.value)}
              placeholder="Select Price"
              labelText="Price"
            />
          </div>
        </div>

        {/* Apply & Clear Buttons */}
        <div className="flex gap-2 mb-8">
          <button
            className="bg-orange-600 text-white font-bold px-6 py-2.5 rounded-md shadow-md hover:bg-orange-700 transition duration-200"
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className="bg-gray-700 text-white font-bold px-6 py-2.5 rounded-md shadow-md hover:bg-gray-800 transition duration-200"
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>

        {/* Course Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {paginatedCourses.length > 0 ? (
            paginatedCourses.map((course) => (
              <div
                key={course.id}
                onClick={() =>
                  navigate(`/user-dashboard/learning/course/${course.id}`)
                }
                className="bg-gray-800 rounded-lg overflow-hidden shadow-lg cursor-pointer"
              >
                <img
                  src={course.image}
                  alt={course.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{course.title}</h2>
                  <p className="text-gray-400 text-sm">{course.instructor}</p>
                  <div className="flex items-center mt-2">
                    <Star className="text-yellow-400 w-5 h-5" />
                    <span className="ml-1">{course.rating}</span>
                  </div>
                  <p className="mt-2 font-bold">£{course.price}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-400 col-span-3 text-center">
              No courses found.
            </p>
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-8">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPrevPage={handlePrevPage}
              onNextPage={handleNextPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursePlatform;