import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaStar,
  FaSearch,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
} from "react-icons/fa";
import restaurantsData from "./list_of_resturants_c.json";
import Banner from "../../Snippets/Banner";
import FoodVarietySlider from "../../Snippets/FoodVariety";
import SearchInput from "../../Snippets/SearchInput.js";
import RestaurantCard from "../../Snippets/RestaurantCard.js";

const RestaurantGrid = () => {
  const { city } = useParams();
  const navigate = useNavigate();

  const cityRestaurants = restaurantsData[city] || [];

  const [visibleRestaurants, setVisibleRestaurants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const observer = useRef();
  const itemsPerPage = 9;

  const filteredRestaurants = cityRestaurants.filter(
    (restaurant) =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      restaurant.tags.some((tag) =>
        tag.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const loadMoreRestaurants = useCallback(() => {
    if (loading) return;
    setLoading(true);
    const startIndex = visibleRestaurants.length;
    const endIndex = startIndex + itemsPerPage;
    const nextRestaurants = filteredRestaurants.slice(startIndex, endIndex);
    setVisibleRestaurants((prev) => [...prev, ...nextRestaurants]);
    setLoading(false);
  }, [visibleRestaurants, filteredRestaurants, loading]);

  const lastRestaurantRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          visibleRestaurants.length < filteredRestaurants.length
        ) {
          loadMoreRestaurants();
        }
      });
      if (node) observer.current.observe(node);
    },
    [visibleRestaurants, filteredRestaurants, loadMoreRestaurants]
  );

  useEffect(() => {
    setVisibleRestaurants([]);
    loadMoreRestaurants();
  }, [searchTerm, city]);

  const handleRestaurantClick = (uuid) => {
    navigate(`/user-dashboard/restaurant/${uuid}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const banners = {
    london: require("../../../assets/img/london_banner.gif"),
    manchester: require("../../../assets/img/manchester_banner.jpg"),
    birmingham: require("../../../assets/img/birmingham_banner.jpg"),
  };

  const bannerImage = banners[city?.toLowerCase()] || banners.london;
  return (
    <div className="bg-gray-900 min-h-screen">
      <Banner
        img={bannerImage}
        showHostButton={false}
        heading={city}
        text={`Explore ${city}'s best dining spots, from cozy cafés to gourmet restaurants, and savor the city's diverse flavors.`}
      />
      <div className="container mx-auto px-4 py-4">
        <div>
          <p className="text-white text-lg md:text-xl">
            Dive into our wide variety of cuisines.
          </p>
          <FoodVarietySlider />
        </div>
        <div>
          <p className="text-white text-lg md:text-xl">
            View the restaurants in the London city.
          </p>

          <div className="flex items-center gap-3">
            {/* Search Bar */}
            <div className="w-full md:max-w-lg">
              {/* Input Field */}
              <SearchInput
                placeholder={"Search for restaurants, cuisines or locations"}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            {/* Map Icon - Properly Positioned */}
            <button className="p-3 bg-orange-600 rounded-full">
              <FaMapMarkedAlt className="text-white w-6 h-6" />
            </button>
          </div>
        </div>
        {/* Grid of Restaurants */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-8">
          {visibleRestaurants.map((restaurant, index) => (
            <RestaurantCard
              key={restaurant.uuid}
              restaurant={restaurant}
              lastRestaurantRef={
                visibleRestaurants.length === index + 1
                  ? lastRestaurantRef
                  : null
              }
              handleRestaurantClick={handleRestaurantClick}
            />
          ))}
        </div>

        {/* Loader */}
        {loading && (
          <div className="text-center mt-8 text-white">
            Loading more restaurants...
          </div>
        )}
      </div>
      {/* Search Bar and Filters */}
    </div>
  );
};

export default RestaurantGrid;
