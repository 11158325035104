import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTimes, FaChevronDown, FaMapMarkerAlt } from "react-icons/fa";
import finalLogo from "../../../assets/img/log.png";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { useLoadScript } from "@react-google-maps/api";
import PropTypes from "prop-types";

const DashboardNav = ({ userLocation }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/");
  const [locationName, setLocationName] = useState("");
  const navigate = useNavigate();
  const { userData, logout } = useAuth();
  const location = useLocation();
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
  });

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (isLoaded && userLocation) {
      const fetchLocationName = async () => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation?.latitude},${userLocation?.longitude}&key=${googleApiKey}`
          );
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            setLocationName(data.results[0].formatted_address);
          }
        } catch (error) {
          console.error("Error fetching location name:", error);
        }
      };

      fetchLocationName();
    }
  }, [userLocation, googleApiKey, isLoaded]);

  const handleTabClick = (path) => {
    navigate(path);
    setMobileMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  return (
    <nav className="bg-gray-950 text-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="cursor-pointer">
            <img src={finalLogo} alt="Logo" className="h-8 md:h-10" />
          </div>

          <div className="flex items-center space-x-4 ml-auto">
            <div className="hidden xl:flex">
              {locationName ? (
                <div
                  className="flex items-center text-sm text-gray-400 border border-gray-700 px-3 py-1 rounded-lg hover:bg-gray-800 cursor-pointer"
                  title={locationName}
                >
                  <FaMapMarkerAlt className="mr-1" />
                  {locationName.length > 20
                    ? `${locationName.slice(0, 20)}...`
                    : locationName}
                </div>
              ) : (
                <div className="flex items-center text-sm text-gray-400 border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700">
                  <FaMapMarkerAlt className="mr-1" />
                  Fetching location...
                </div>
              )}
            </div>

            <div className="hidden xl:flex">
              <div
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="text-gray-300 cursor-pointer flex items-center border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700"
              >
                {userData?.firstName || userData?.name || "User"}{" "}
                <FaChevronDown className="ml-1" />
              </div>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-gray-300 border border-gray-700 rounded-lg shadow-lg z-10">
                  <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer">
                    Profile
                  </div>
                  <div
                    className="py-2 px-4 hover:bg-gray-700 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="xl:hidden text-white hover:text-gray-300 focus:outline-none"
            >
              {mobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="xl:hidden px-4">
          <div className="border-gray-700 border-t pb-4 pt-2">
            <MobileNavItem
              path="/user-dashboard/university"
              label="Universities"
              active={activeTab === "/user-dashboard/university"}
              onClick={handleTabClick}
            />
            {/* Add other navigation items */}
            <MobileNavItem
              path="/user-dashboard/accommodation"
              label="Accomodation"
              active={activeTab === "/user-dashboard/accommodation"}
              onClick={handleTabClick}
            />
            <MobileNavItem
              path="/user-dashboard/events"
              label="Events"
              active={activeTab === "/user-dashboard/events"}
              onClick={handleTabClick}
            />
            <MobileNavItem
              path="/user-dashboard/jobs"
              label="Jobs"
              active={activeTab === "/user-dashboard/jobs"}
              onClick={handleTabClick}
            />
            <MobileNavItem
              path="/user-dashboard/explore"
              label="Explore"
              active={activeTab === "/user-dashboard/explore"}
              onClick={handleTabClick}
            />
            <MobileNavItem
              path="/user-dashboard/reliv"
              label="Reliv (Upcoming)"
              active={activeTab === "/user-dashboard/reliv"}
              onClick={handleTabClick}
              disabled={true}
            />
            <MobileNavItem
              path="/user-dashboard/help"
              label="Help"
              active={activeTab === "/user-dashboard/help"}
              onClick={handleTabClick}
              disabled={true}
            />
            <div className="border-t border-gray-700">
              <MobileNavItem
                path="/user-dashboard/profile"
                label="Profile"
                active={activeTab === "/user-dashboard/profile"}
                onClick={handleTabClick}
              />
              <MobileNavItem
                path="/logout"
                label="Logout"
                active={false}
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

const MobileNavItem = ({ path, label, active, onClick, disabled }) => (
  <button
    onClick={() => {
      if (!disabled) {
        onClick(path);
      }
    }}
    disabled={disabled}
    className={`block px-4 py-2 text-left w-full ${
      active ? "bg-gray-800 font-semibold" : "text-white"
    } ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
  >
    {label}
  </button>
);

DashboardNav.propTypes = {
  userLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

MobileNavItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MobileNavItem.defaultProps = {
  disabled: false,
};

export default DashboardNav;
