import React, { useState, useEffect } from "react";
import {
  Building2,
  MapPin,
  Globe,
  Mail,
  Phone,
  Plus,
  Save,
  MapPinned,
  Trash2,
} from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useLoadScript } from "@react-google-maps/api";

const useFormState = (initialState) => {
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNestedChange = (index, field, e) => {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      course_locations: prevState.course_locations.map((location, idx) =>
        idx === index ? { ...location, [field]: value } : location
      ),
    }));
  };

  const addNestedField = () => {
    setState((prevState) => ({
      ...prevState,
      course_locations: [
        ...prevState.course_locations,
        { location_name: "", location_address: "" },
      ],
    }));
  };

  const deleteNestedField = (index) => {
    setState((prevState) => ({
      ...prevState,
      course_locations: prevState.course_locations.filter(
        (_, idx) => idx !== index
      ),
    }));
  };

  return {
    state,
    handleChange,
    handleNestedChange,
    addNestedField,
    deleteNestedField,
    setState,
  };
};

const InputField = ({ icon: Icon, label, name, error, required, ...props }) => (
  <div>
    <label htmlFor={name} className="block mb-2 text-sm font-medium">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="flex items-center bg-gray-700 rounded">
      <Icon className="ml-3 text-gray-400" />
      <input
        id={name}
        name={name}
        required={required}
        {...props}
        className="w-full p-3 bg-transparent text-white rounded [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
      />
    </div>
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

const libraries = ["places"];

const PlacesAutocomplete = ({ value, onChange, error, required }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="flex items-center bg-gray-700 rounded">
      <MapPin className="ml-3 text-gray-400" />
      <input
        type="text"
        placeholder="Search location"
        value={value}
        required={required}
        className="w-full p-3 bg-transparent text-white rounded [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) => {
          const input = e.target;
          const autocomplete = new window.google.maps.places.Autocomplete(
            input
          );
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.formatted_address) {
              onChange(place.formatted_address);
            }
          });
        }}
      />
    </div>
  );
};

const UploadUniversityInfo = () => {
  const { userData } = useAuth();
  const [isEditMode, setIsEditMode] = useState(false);
  const [universityId, setUniversityId] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [logoPreview, setLogoPreview] = useState(null);
  const [backgroundPreview, setBackgroundPreview] = useState(null);

  const {
    state: universityData,
    handleChange,
    handleNestedChange,
    addNestedField,
    deleteNestedField,
    setState,
  } = useFormState({
    university_name: "",
    location: "",
    official_website: "",
    contact_email: "",
    contact_phone: "",
    course_locations: [{ location_name: "", location_address: "" }],
    logo_url: "",
    background_image: "",
    about_us: "",
    courses: {
      Undergraduate: { "Full-time": [], "Part-time": [] },
      Postgraduate: { "Full-time": [], "Part-time": [] },
    },
  });

  const wrappedHandleChange = (e) => {
    handleChange(e);
    const { name } = e.target;
    setFormErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const wrappedHandleNestedChange = (index, field, e) => {
    handleNestedChange(index, field, e);
    const errorKey = `course_location_${field}_${index}`;
    setFormErrors((prev) => ({ ...prev, [errorKey]: undefined }));
  };
  const validateForm = () => {
    let errors = {};
    // Required field validations
    if (!universityData.university_name.trim()) {
      errors.university_name = "University name is required";
    }
    if (!universityData.location.trim()) {
      errors.location = "Location is required";
    }
    if (!universityData.official_website.trim()) {
      errors.official_website = "Official website is required";
    }
    if (!universityData.contact_email.trim()) {
      errors.contact_email = "Contact email is required";
    } else if (!/\S+@\S+\.\S+/.test(universityData.contact_email)) {
      errors.contact_email = "Invalid email format";
    }
    if (!universityData.contact_phone.trim()) {
      errors.contact_phone = "Contact phone is required";
    }
    if (!universityData.about_us.trim()) {
      errors.about_us = "About university is required";
    }

    // File validations
    if (!logoPreview && !universityData.logo_url) {
      errors.logo = "Logo is required";
    }
    if (!backgroundPreview && !universityData.background_image) {
      errors.background = "Background image is required";
    }

    // Course locations validation
    universityData.course_locations.forEach((loc, index) => {
      if (!loc.location_name.trim()) {
        errors[`course_location_name_${index}`] = "Location name is required";
      }
      if (!loc.location_address.trim()) {
        errors[`course_location_address_${index}`] =
          "Location address is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFileChange = (e, setFile, setPreview, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      setFile(file);
      setFormErrors((prev) => ({ ...prev, [fieldName]: undefined }));
    } else {
      setPreview(null);
      setFile(null);
      setFormErrors((prev) => ({
        ...prev,
        [fieldName]: `${fieldName} is required`,
      }));
    }
  };

  const uploadFile = async (file, path) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const timestamp = new Date();
      const universityDataWithTimestamps = {
        ...universityData,
        updatedAt: timestamp,
        userId: userData.userId,
      };

      if (logoFile) {
        const logoPath = `${userData.userId}/${universityData.university_name}/logo`;
        universityDataWithTimestamps.logo_url = await uploadFile(
          logoFile,
          logoPath
        );
      }

      if (backgroundFile) {
        const backgroundPath = `${userData.userId}/${universityData.university_name}/background`;
        universityDataWithTimestamps.background_image = await uploadFile(
          backgroundFile,
          backgroundPath
        );
      }

      if (isEditMode) {
        await updateDoc(
          doc(firestore, "universities", universityId),
          universityDataWithTimestamps
        );
        toast.success("University information updated successfully!");
      } else {
        universityDataWithTimestamps.createdAt = timestamp;
        await addDoc(
          collection(firestore, "universities"),
          universityDataWithTimestamps
        );
        toast.success("University information saved successfully!");
      }
    } catch (error) {
      console.error("Error saving university data:", error);
      toast.error("Error saving university information");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchUniversity = async () => {
      try {
        const q = query(
          collection(firestore, "universities"),
          where("userId", "==", userData.userId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const universityDoc = querySnapshot.docs[0];
          const data = universityDoc.data();
          setUniversityId(universityDoc.id);
          setState(data);
          if (data.logo_url) setLogoPreview(data.logo_url);
          if (data.background_image)
            setBackgroundPreview(data.background_image);
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("Error fetching university data:", error);
      }
    };

    fetchUniversity();
  }, [userData.userId, setState]);

  useEffect(() => {
    return () => {
      if (logoPreview) URL.revokeObjectURL(logoPreview);
      if (backgroundPreview) URL.revokeObjectURL(backgroundPreview);
    };
  }, [logoPreview, backgroundPreview]);

  return (
    <div className="max-w-6xl mx-auto p-3">
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <Building2 className="mr-3 text-blue-400" />
        {isEditMode
          ? "Edit University Information"
          : "Add University Information"}
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            icon={Building2}
            label="University Name"
            name="university_name"
            placeholder="University Name"
            value={universityData.university_name}
            onChange={wrappedHandleChange}
            error={formErrors.university_name}
            required
          />
          <div>
            <label className="block mb-2 text-sm font-medium">
              Location <span className="text-red-500">*</span>
            </label>
            <PlacesAutocomplete
              value={universityData.location}
              onChange={(value) =>
                wrappedHandleChange({ target: { name: "location", value } })
              }
              error={formErrors.location}
              required
            />
            {formErrors.location && (
              <p className="text-red-500 text-sm mt-1">{formErrors.location}</p>
            )}
          </div>
          <InputField
            icon={Globe}
            label="Official Website"
            name="official_website"
            placeholder="Official Website"
            value={universityData.official_website}
            onChange={wrappedHandleChange}
            error={formErrors.official_website}
            required
          />
          <InputField
            icon={Mail}
            label="Contact Email"
            name="contact_email"
            placeholder="Contact Email"
            value={universityData.contact_email}
            onChange={wrappedHandleChange}
            error={formErrors.contact_email}
            required
          />
          <InputField
            icon={Phone}
            label="Contact Phone"
            name="contact_phone"
            placeholder="Contact Phone"
            value={universityData.contact_phone}
            onChange={wrappedHandleChange}
            error={formErrors.contact_phone}
            required
          />
        </div>

        <div>
          <label className="block mb-2 text-sm font-medium">
            About University <span className="text-red-500">*</span>
          </label>
          <ReactQuill
            theme="snow"
            value={universityData.about_us}
            onChange={(value) => {
              setState((prev) => ({ ...prev, about_us: value }));
              setFormErrors((prev) => ({ ...prev, about_us: undefined }));
            }}
            className="bg-gray-800 text-white border-gray-600 rounded"
          />
          {formErrors.about_us && (
            <p className="text-red-500 text-sm mt-1">{formErrors.about_us}</p>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">
              Logo <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleFileChange(e, setLogoFile, setLogoPreview, "logo")
              }
              className="w-full p-2 border rounded bg-gray-700 text-white"
            />
            {formErrors.logo && (
              <p className="text-red-500 text-sm mt-1">{formErrors.logo}</p>
            )}
            {logoPreview && (
              <img
                src={logoPreview}
                alt="Logo preview"
                className="mt-2 w-32 h-32 object-contain rounded"
              />
            )}
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">
              Background Image <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleFileChange(
                  e,
                  setBackgroundFile,
                  setBackgroundPreview,
                  "background"
                )
              }
              className="w-full p-2 border rounded bg-gray-700 text-white"
            />
            {formErrors.background && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.background}
              </p>
            )}
            {backgroundPreview && (
              <img
                src={backgroundPreview}
                alt="Background preview"
                className="mt-2 w-full h-32 object-cover rounded"
              />
            )}
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <MapPinned className="mr-2 text-blue-400" />
            Course Locations
          </h3>
          {universityData.course_locations.map((location, index) => (
            <div
              key={index}
              className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 p-4 border border-gray-600 rounded relative"
            >
              <button
                type="button"
                onClick={() => deleteNestedField(index)}
                className="absolute -top-2 -right-2 text-red-500 hover:text-red-400"
              >
                <Trash2 size={20} />
              </button>
              <InputField
                icon={Building2}
                label="Location Name"
                name="location_name"
                placeholder="Location Name"
                value={location.location_name}
                error={formErrors[`course_location_name_${index}`]}
                onChange={(e) => handleNestedChange(index, "location_name", e)}
              />
              <InputField
                icon={MapPin}
                label="Location Address"
                name="location_address"
                placeholder="Location Address"
                value={location.location_address}
                error={formErrors[`course_location_address_${index}`]}
                onChange={(e) =>
                  handleNestedChange(index, "location_address", e)
                }
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addNestedField}
            className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded flex items-center"
          >
            <Plus className="mr-2" /> Add Location
          </button>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? (
            "Saving..."
          ) : (
            <>
              <Save className="mr-2" />
              {isEditMode ? "Update University" : "Save University"}
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default UploadUniversityInfo;
