import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { firestore, storage } from "../../../firebase";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import {
  doc,
  deleteDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import toast from "react-hot-toast";
import UniversityCard from "../../Snippets/UniversityCard";
import {
  Building2,
  Mail,
  Phone,
  Globe,
  MapPin,
  Clock,
  Trash2,
  Info,
  Loader,
} from "lucide-react";

Modal.setAppElement("#root"); // To avoid accessibility issues

const Profile = () => {
  const { userData } = useAuth();
  const [university, setUniversity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(university);
  useEffect(() => {
    const fetchUniversity = async () => {
      if (!userData.userId) return;

      try {
        const q = query(
          collection(firestore, "universities"),
          where("userId", "==", userData.userId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const universityDoc = querySnapshot.docs[0];
          setUniversity({ id: universityDoc.id, ...universityDoc.data() });
        } else {
          setUniversity(null);
        }
      } catch (error) {
        console.error("Error fetching university:", error);
        toast.error("Failed to load university details.");
      } finally {
        setLoading(false);
      }
    };

    fetchUniversity();
  }, [userData.userId]);

  const deleteUniversity = async () => {
    if (!university) return;

    try {
      if (university.logo_url) {
        const logoRef = ref(
          storage,
          `${userData.userId}/${university.university_name}/logo`
        );
        await deleteObject(logoRef).catch(() => {});
      }

      if (university.background_image) {
        const bgRef = ref(
          storage,
          `${userData.userId}/${university.university_name}/background`
        );
        await deleteObject(bgRef).catch(() => {});
      }

      await deleteDoc(doc(firestore, "universities", university.id));
      toast.success("University deleted successfully!");
      setUniversity(null);
    } catch (error) {
      console.error("Error deleting university:", error);
      toast.error("Failed to delete university.");
    } finally {
      setIsModalOpen(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold text-white mb-6 flex items-center gap-2">
        <Building2 className="w-8 h-8" />
        University Profile
      </h2>

      {loading ? (
        <div className="flex flex-col items-center justify-center h-64 space-y-4">
          <div className="animate-spin">
            <Loader className="h-10 w-10 text-blue-500" />
          </div>
          <p className="text-gray-400">Loading analytics data...</p>
        </div>
      ) : university ? (
        <div className="bg-gray-800 rounded-lg border border-white/20 overflow-hidden">
          {/* Header with Background Image */}
          <div
            className="h-48 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${university.background_image})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="absolute bottom-4 left-4 flex items-center gap-4">
              <img
                src={university.logo_url}
                alt="Logo"
                className="w-20 h-20 rounded-lg border-2 border-white"
              />
              <h3 className="text-2xl font-bold text-white">
                {university.university_name}
              </h3>
            </div>
          </div>

          {/* Content Grid */}
          <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-4">
              <div className="flex items-center gap-3 text-gray-300">
                <MapPin className="w-5 h-5 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">Location</p>
                  <p className="font-medium">{university.location}</p>
                </div>
              </div>

              <div className="flex items-center gap-3 text-gray-300">
                <Mail className="w-5 h-5 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">Contact Email</p>
                  <p className="font-medium">{university.contact_email}</p>
                </div>
              </div>

              <div className="flex items-center gap-3 text-gray-300">
                <Phone className="w-5 h-5 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">Contact Phone</p>
                  <p className="font-medium">{university.contact_phone}</p>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-4">
              <div className="flex items-center gap-3 text-gray-300">
                <Globe className="w-5 h-5 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">Website</p>
                  <a
                    href={`https://${university.official_website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium hover:text-blue-400"
                  >
                    {university.official_website}
                  </a>
                </div>
              </div>

              <div className="flex items-center gap-3 text-gray-300">
                <Clock className="w-5 h-5 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">Last Updated</p>
                  <p className="font-medium">
                    {formatDate(university.updatedAt)}
                  </p>
                </div>
              </div>
            </div>

            {/* About Section - Full Width */}
            <div className="col-span-full">
              <div className="flex items-start gap-3 text-gray-300">
                <Info className="w-5 h-5 text-blue-400 mt-1" />
                <div>
                  <p className="text-sm text-gray-400 mb-2">About Us</p>
                  <div
                    className="font-medium prose prose-invert"
                    dangerouslySetInnerHTML={{ __html: university.about_us }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* Delete Button */}
          <div className="p-6 border-t border-white/10">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded flex items-center gap-2"
            >
              <Trash2 className="w-4 h-4" />
              Delete University
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center py-8 text-gray-400">
          No university profile found.
        </div>
      )}

      {/* React Modal for Confirmation */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="bg-gray-800 text-white p-6 rounded-lg max-w-4xl mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h3 className="text-lg font-semibold text-white mb-4">
          Are you sure you want to delete this university?
        </h3>
        <div className="flex justify-between">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-500 hover:bg-gray-400 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            onClick={deleteUniversity}
            className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded"
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
