import { FaHouseUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Banner = ({
  img,
  showHostButton = true,
  heading,
  text,
  buttonText,
  url,
  icon,
  secondaryButtonText,
  secondaryButtonUrl,
  secondaryButtonOnClick,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="relative bg-gray-800 py-8 rounded-4 mx-2 bg-cover bg-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-4"></div>
      {showHostButton && (
        <button
          className="absolute top-4 right-4 bg-orange-600 text-sm font-semibold text-white px-2 py-2 rounded-4 shadow flex items-center gap-1 hover:bg-orange-700 transition duration-200"
          onClick={() => navigate(url)}
        >
          {icon}
          {buttonText}
        </button>
      )}
      <div className="relative container mx-auto px-4">
        <div className="text-lg sm:text-3xl font-bold text-white mb-2">
          {heading}
        </div>
        <p className="text-sm sm:text-base text-gray-300 mb-4">{text}</p>
        {secondaryButtonText && (
          <button
            onClick={
              secondaryButtonOnClick || (() => navigate(secondaryButtonUrl))
            }
            className="bg-white text-black px-4 py-2 rounded-full border-2 border-orange-700 text-sm font-semibold hover:!bg-orange-700 hover:!text-white hover:!border-orange-700 transition-all duration-200 ease-in-out"
          >
            {secondaryButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default Banner;
