import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import jobsData from "./data/jobs_final";
import { firestore } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../../../auth/userProvider/AuthProvider";

const categories = [
  "Engineering",
  "Law",
  "Psychology",
  "Manufacturing",
  "Marketing",
  "Healthcare",
  "Sales",
  "Education",
  "Customer Service",
];
const ukCities = [
  "London",
  "Manchester",
  "Birmingham",
  "Leeds",
  "Glasgow",
  "Newcastle upon Tyne",
  "Liverpool",
  "Bristol",
  "Edinburgh",
  "Sheffield",
  "Nottingham",
  "Leicester",
  "Coventry",
  "Cardiff",
  "Belfast",
  "Brighton",
  "Hull",
  "Plymouth",
  "Stoke-on-Trent",
  "Wolverhampton",
  "Derby",
  "Swansea",
  "Southampton",
  "Salford",
  "Aberdeen",
  "Westminster",
  "Portsmouth",
  "York",
  "Peterborough",
  "Dundee",
  "Lancaster",
  "Oxford",
  "Newport",
  "Preston",
  "St Albans",
  "Norwich",
  "Chester",
  "Cambridge",
  "Sunderland",
  "Lichfield",
  "Bath",
  "Durham",
  "Lincoln",
  "Hereford",
  "Armagh",
  "Inverness",
  "Stirling",
  "Canterbury",
  "Londonderry",
  "Lisburn",
  "Bangor",
  "Carlisle",
  "Wakefield",
  "Ripon",
  "Wells",
  "Winchester",
  "Newry",
  "Truro",
  "Gloucester",
  "Exeter",
];

const TempJobs = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterJobType, setFilterJobType] = useState("All");
  const [filterLocation, setFilterLocation] = useState("All");
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [filterCategory, setFilterCategory] = useState("All");
  const [firebaseJobs, setFirebaseJobs] = useState([]);
  const { userData } = useAuth();
  useEffect(() => {
    const fetchFirebaseJobs = async () => {
      const querySnapshot = await getDocs(collection(firestore, "jobs"));
      const jobs = querySnapshot.docs.map((doc) => doc.data());
      setFirebaseJobs(jobs);
    };
    fetchFirebaseJobs();
  }, []);

  const normalizeLocation = (location) => {
    if (!location) return null;
    const matchedCity = ukCities.find((city) =>
      location.toLowerCase().includes(city.toLowerCase())
    );
    return matchedCity || location; // Return matched city or original if no match
  };

  const filteredJobs = useMemo(() => {
    const allJobs = [...firebaseJobs, ...jobsData];
    return allJobs.filter((job) => {
      if (!job) return false;

      const jobTitle = job.job?.toLowerCase() ?? "";
      const company = job.company?.toLowerCase() ?? "";
      const jobType = job.job_type ?? "";
      const categories = job.category ?? "";
      const normalizedLocation = normalizeLocation(job.location);

      const matchesSearchTerm =
        searchTerm === "" ||
        jobTitle.includes(searchTerm.toLowerCase()) ||
        company.includes(searchTerm.toLowerCase());

      const matchesJobType =
        filterJobType === "All" || jobType === filterJobType;
      const matchesLocation =
        filterLocation === "All" || normalizedLocation === filterLocation;
      const matchesCategory =
        filterCategory === "All" || categories === filterCategory;

      return (
        matchesSearchTerm &&
        matchesJobType &&
        matchesLocation &&
        matchesCategory
      );
    });
  }, [searchTerm, filterJobType, filterLocation, filterCategory, firebaseJobs]);

  useEffect(() => {
    if (filteredJobs.length > 0) {
      setSelectedJob(filteredJobs[0]);
      // Only auto-show details on desktop
      setShowJobDetails(window.innerWidth >= 768);
    }
  }, [filteredJobs]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    // Reset filters when searching
    setFilterJobType("All");
    setFilterLocation("All");
    setFilterCategory("All");
  }, []);

  const handleJobTypeChange = useCallback((e) => {
    setFilterJobType(e.target.value);
  }, []);

  const handleLocationChange = useCallback((e) => {
    setFilterLocation(e.target.value);
  }, []);

  const handleCategoryChange = useCallback((e) => {
    setFilterCategory(e.target.value);
  }, []);
  const handleJobClick = useCallback((job) => {
    setSelectedJob(job);
    setShowJobDetails(true);
  }, []);

  const handleBackClick = useCallback(() => {
    setShowJobDetails(false);
  }, []);

  const handlePostJobClick = () => {
    window.open("/business/signup", "_blank", "noopener,noreferrer");
  };

  const handleApplyClick = (jobUrl) => {
    if (jobUrl && userData) {
      window.open(jobUrl, "_blank", "noopener,noreferrer");
      console.log(jobUrl);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      {/* Search and Filter Section */}
      <div className=" p-1 top-0 z-10 rounded-b-lg shadow-lg">
        <div className="max-w-6xl text-sm  mx-auto flex flex-wrap md:flex-nowrap items-center gap-2">
          <input
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full md:w-auto flex-1 p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-orange-600"
          />
          <select
            value={filterJobType}
            onChange={handleJobTypeChange}
            className="w-full md:w-40 p-2 bg-gray-700 text-white  rounded focus:outline-none"
          >
            <option value="All">All Job Types</option>
            <option value="Part Time">Part time</option>
            <option value="Full Time">Full time</option>
          </select>
          <select
            value={filterCategory}
            onChange={handleCategoryChange}
            className="w-full md:w-40 p-2 bg-gray-700 text-white rounded focus:outline-none"
          >
            <option value="All">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <select
            value={filterLocation}
            onChange={handleLocationChange}
            className="w-full md:w-40 p-2 bg-gray-700 text-white rounded focus:outline-none"
          >
            <option value="All">All Locations</option>
            {ukCities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <button
            onClick={handlePostJobClick}
            className="bg-orange-600 font-semibold text-white px-3 py-2 rounded-4 shadow flex items-center gap-2 hover:bg-orange-700 transition duration-200"
          >
            Post Jobs
          </button>
        </div>
      </div>

      {/* Job List and Details */}
      <div className="flex flex-col h-[calc(100vh-80px)]">
        {/* Mobile View */}
        <div className="md:hidden h-full">
          {showJobDetails && selectedJob ? (
            <JobDetailView
              job={selectedJob}
              onBack={handleBackClick}
              handleApplyClick={handleApplyClick}
            />
          ) : (
            <JobListView jobs={filteredJobs} onJobClick={handleJobClick} />
          )}
        </div>

        {/* Desktop View */}
        <div className="hidden md:flex h-full">
          <div className="w-1/3 border-r border-gray-700">
            <JobListView jobs={filteredJobs} onJobClick={handleJobClick} />
          </div>
          <div className="w-2/3">
            {selectedJob ? (
              <JobDetailView
                job={selectedJob}
                handleApplyClick={handleApplyClick}
              />
            ) : (
              <div className="h-full flex items-center justify-center">
                <p className="text-lg text-gray-400">
                  Select a job to view details
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const JobListView = ({ jobs, onJobClick }) => (
  <div className="w-full bg-gray-900 p-4 h-full overflow-y-auto overflow-x-hidden scrollbar-hide scrollbar-thumb-gray-700 scrollbar-track-gray-900">
    <div className="max-w-2xl mx-auto">
      <h2 className="text-xl font-bold mb-4">Job Listings</h2>
      {jobs.length > 0 ? (
        <ul className="space-y-4 w-full pl-0">
          {jobs.map((job, index) => (
            <li
              key={index}
              className="p-2 bg-gray-800 rounded cursor-pointer hover:bg-gray-700 transition-colors duration-200 flex items-start"
              onClick={() => onJobClick(job)}
            >
              {job.image_url && (
                <img
                  src={job.image_url}
                  alt={job.job}
                  className="w-16 h-16 object-cover rounded mr-4"
                />
              )}
              <div>
                <h3 className="font-bold text-sm">
                  {job.job || "Untitled Job"}
                </h3>
                <p className="text-xs">
                  {job.company || "Company not specified"}
                </p>
                <p className="text-xs">
                  {job.location || "Location not specified"}
                </p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400 text-sm">No jobs found</p>
      )}
    </div>
  </div>
);

const JobDetailView = ({ job, onBack, handleApplyClick }) => (
  <div className="w-full bg-gray-900 h-full overflow-y-auto scrollbar-hide">
    {onBack && (
      <button
        onClick={onBack}
        className="p-2 text-white text-sm flex items-center"
      >
        Back to listings
      </button>
    )}
    <div className="max-w-4xl mx-auto">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg m-4 relative">
        {job.image_url && (
          <img
            src={job.image_url}
            alt={job.job}
            className="w-24 h-24 object-cover rounded absolute top-4 right-4"
          />
        )}
        <h2 className="text-xl font-bold mb-4 text-orange-500">{job.job}</h2>
        <div className="mb-4">
          <p className="text-sm">
            <strong>Company:</strong> {job.company}
          </p>
          <p className="text-sm">
            <strong>Location:</strong> {job.location}
          </p>
          <p className="text-sm">
            <strong>Job Type:</strong> {job.job_type}
          </p>
          <p className="text-sm">
            <strong>Employment Type:</strong> {job.employement_type}
          </p>
        </div>
        <div className="mb-4">
          <p className="text-gray-300 text-sm mb-4">
            <strong>Description:</strong>
            <span
              className="ml-2 block mt-1 text-sm text-gray-400"
              dangerouslySetInnerHTML={{ __html: job.description }}
            />
          </p>
        </div>
        <button
          onClick={() => handleApplyClick(job.job_url)}
          className="bg-orange-600 hover:bg-orange-500 w-full text-white text-sm py-2 px-4 rounded-lg transition-colors duration-200 block text-center"
        >
          Apply Here
        </button>
      </div>
    </div>
  </div>
);

export default TempJobs;
