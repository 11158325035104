import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { storage, firestore } from "../../../firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { toast } from "react-hot-toast";

const AdvertiseRoomForm = () => {
    const [addressData, setAddressData] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");
    const [showAddressList, setShowAddressList] = useState(false); // Controls visibility of address list
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        roomType: "1 room for rent",
        bedrooms: "2 bed",
        propertyType: "",
        occupants: "1",
        postcode: "",
        address: "", //update this
        isLiveInLandlord: true,
        area: "",
        transport: "",
        transportTime: "",
        transportFrom: "",
        livingRoom: "yes",
        amenities: {
            parking: false,
            garden: false,
            garage: false,
            balcony: false,
            disabledAccess: false,
        },
        costOfRoom: "",
        sizeOfRoom: "single",
        enSuite: false,
        furnishings: "furnished",
        securityDeposit: "",
        availableFrom: "",
        minimumStay: "",
        maximumStay: "",
        shortTermLets: false,
        daysAvailable: "",
        referencesRequired: "",
        billsIncluded: "",
        broadbandIncluded: "",
        smoking: "No",
        gender: "Male",
        occupation: "Not disclosed",
        pets: "No",
        age: "",
        language: "English",
        nationality: "Not disclosed",
        lgbtHousehold: false,
        interests: "",
        smokingOk: "No preference",
        newGender: "No preference",
        newOccupation: "Available to all",
        newPets: "No",
        newMinAge: "",
        newMaxAge: "",
        newLanguage: "English",
        couplesWelcome: "No",
        newLgbtPreferred: false,
        vegetarianPreferred: false,
        adTitle: "",
        description: "",
        images: [],
        name: {
            firstName: "",
            lastName: "",
        },
        displayLastName: false,
        telephone: "",
        displayTelephone: false,
    });


    const [currentStep, setCurrentStep] = useState(1);
    const { userData } = useAuth(); // Get user details

    // Generic handler for updating form state
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            if (name in formData.amenities) {
                setFormData((prev) => ({
                    ...prev,
                    amenities: { ...prev.amenities, [name]: checked },
                }));
            } else {
                setFormData((prev) => ({ ...prev, [name]: checked }));
            }
        } else if (name === "firstName" || name === "lastName") {
            setFormData((prev) => ({
                ...prev,
                name: { ...prev.name, [name]: value },
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    // Handle image upload to Firebase Storage
    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
        if (!files.length) return;

        try {
            if (!userData || !userData.userId) {
                toast.error("User must be logged in to upload images.");
                return;
            }

            setLoading(true);
            const uploadedImages = await Promise.all(
                files.map(async (file) => {
                    const fileRef = ref(storage, `new-accomodation/${userData.userId}/${file.name}`);
                    await uploadBytes(fileRef, file);
                    return await getDownloadURL(fileRef);
                })
            );

            setFormData((prev) => ({
                ...prev,
                images: [...prev.images, ...uploadedImages],
            }));

            toast.success("Images uploaded successfully");
        } catch (error) {
            console.error("Image upload error:", error);
            toast.error("Failed to upload images");
        } finally {
            setLoading(false);
        }
    };

    const handleContinue = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const formatPostcode = (postcode) => {
        return postcode.toUpperCase().replace(/\s+/g, ""); // Convert to uppercase & remove spaces
    };

    const fetchAddress = async () => {
        if (!formData.postcode) {
            alert("Please enter a postcode.");
            return;
        }

        // Format the postcode
        const formattedPostcode = formatPostcode(formData.postcode);
        console.log("Formatted Postcode:", formattedPostcode);

        try {
            const response = await fetch(
                `https://address-lookup-service.spareroom.co.uk/api/v1.0/addresses?territory=uk&postcode=${formattedPostcode}`
            );
            const data = await response.json();
            console.log("Fetched address data:", data); // Debugging statement

            // Check if response has valid data
            if (data.data && Array.isArray(data.data) && data.data.length > 0) {
                setAddressData(data.data); // Store addresses
                setShowAddressList(true); // Show address list
            } else {
                alert("No valid addresses found. Please check the postcode format.");
                setAddressData([]);
                setShowAddressList(false);
            }
        } catch (error) {
            console.error("Error fetching address data:", error);
            alert("Error fetching address data. Please try again.");
            setAddressData([]);
            setShowAddressList(false);
        }
    };

    // Validate Form before submission
    const validateForm = () => {
        const errors = {};
        if (!formData.adTitle.trim()) errors.adTitle = "Ad title is required.";
        if (!formData.description.trim()) errors.description = "Description is required.";
        if (!formData.postcode.trim()) errors.postcode = "Postcode is required.";
        if (!formData.address.trim()) errors.address = "Address is required.";
        if (!formData.costOfRoom.trim()) errors.costOfRoom = "Room cost is required.";
        return errors;
    };


    // Submit to Firebase Firestore
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            toast.error("Please fill in all required fields.");
            setLoading(false);
            return;
        }

        try {
            if (!userData || !userData.userId) {
                throw new Error("User not logged in.");
            }

            const accommodationData = {
                ...formData,
                createdBy: userData.userId,
                createdAt: new Date(),
                userId: userData.userId,
            };

            // Add to Firestore
            const docRef = await addDoc(collection(firestore, "new-accomodation"), accommodationData);

            // Update with accommodationId
            await updateDoc(doc(firestore, "new-accomodation", docRef.id), { accommodationId: docRef.id });

            toast.success("Accommodation posted successfully");
            setFormData({
                roomType: "1 room for rent",
                bedrooms: "2 bed",
                propertyType: "",
                occupants: "1",
                postcode: "",
                address: "",
                isLiveInLandlord: "Live in landlord",
                area: "",
                transport: "",
                transportTime: "",
                transportFrom: "",
                livingRoom: "yes",
                amenities: {
                    parking: false,
                    garden: false,
                    garage: false,
                    balcony: false,
                    disabledAccess: false,
                },
                costOfRoom: "",
                sizeOfRoom: "single",
                enSuite: false,
                furnishings: "furnished",
                securityDeposit: "",
                availableFrom: "",
                minimumStay: "",
                maximumStay: "",
                shortTermLets: false,
                daysAvailable: "",
                referencesRequired: "",
                billsIncluded: "",
                broadbandIncluded: "",
                smoking: "No",
                gender: "Male",
                occupation: "Not disclosed",
                pets: "No",
                age: "",
                language: "English",
                nationality: "Not disclosed",
                lgbtHousehold: false,
                interests: "",
                smokingOk: "No preference",
                newGender: "No preference",
                newOccupation: "Available to all",
                newPets: "No",
                newMinAge: "",
                newMaxAge: "",
                newLanguage: "English",
                couplesWelcome: "No",
                newLgbtPreferred: false,
                vegetarianPreferred: false,
                adTitle: "",
                description: "",
                images: [],
                name: { firstName: "", lastName: "" },
                displayLastName: false,
                telephone: "",
                displayTelephone: false,
            });
        } catch (error) {
            toast.error("Error submitting accommodation.");
            console.error("Submission error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-4xl mx-auto text-white">
            <form onSubmit={handleSubmit}>
                {currentStep === 1 && (
                    <>
                        <h2 className="text-2xl font-semibold">Advertise your room</h2>
                        <div className="mt-4">
                            <h3 className="text-lg font-medium">Step 1 of 5</h3>
                            {/* I Have (Flex Row) */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">I have</label>
                                <select
                                    name="roomType"
                                    value={formData.roomType}
                                    onChange={handleChange}
                                    className="w-3/4 bg-gray-700 p-2 rounded text-sm text-white"
                                >
                                    <option>1 room for rent</option>
                                    <option>2 rooms for rent</option>
                                    <option>3 rooms for rent</option>
                                    <option>4 rooms for rent</option>
                                    <option>5 rooms for rent</option>
                                    <option>6 rooms for rent</option>
                                    <option>7 rooms for rent</option>
                                    <option>8 rooms for rent</option>
                                    <option>9 rooms for rent</option>
                                    <option>10 rooms for rent</option>
                                    <option>11 rooms for rent</option>
                                    <option>12 rooms for rent</option>
                                </select>
                            </div>

                            {/* Size and Type of Property */}
                            <div className="mt-4 flex gap-2">
                                <label className="block text-sm w-1/4">Size and type of property</label>
                                <select
                                    name="bedrooms"
                                    value={formData.bedrooms}
                                    onChange={handleChange}
                                    className="w-1/4 bg-gray-700 p-2 rounded text-sm text-white"
                                >
                                    <option>2 bed</option>
                                    <option>3 bed</option>
                                    <option>5 bed</option>
                                    <option>6 bed</option>
                                    <option>7 bed</option>
                                    <option>8 bed</option>
                                    <option>9 bed</option>
                                    <option>10 bed</option>
                                    <option>11 bed</option>
                                    <option>12 bed</option>
                                </select>

                                <select
                                    name="propertyType"
                                    value={formData.propertyType}
                                    onChange={handleChange}
                                    className="w-1/2 bg-gray-700 p-2 rounded text-sm text-white"
                                >
                                    <option value="">Select</option>
                                    <option>Flat/Apartment</option>
                                    <option>House</option>
                                    <option>Property</option>
                                </select>
                            </div>

                            {/* Number of Occupants (Flex Row) */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">There are already</label>
                                <select
                                    name="occupants"
                                    value={formData.occupants}
                                    onChange={handleChange}
                                    className="w-1/3 bg-gray-700 p-2 rounded text-sm text-white"
                                >
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </select>
                                <p className="text-sm m-0 text-gray-400">occupants in the property (including you)</p>
                            </div>

                            {/* Postcode (Flex Row) */}
                            <div className="mt-4 flex gap-2">
                                {/* Label should be in a separate div for alignment */}
                                <div className="text-sm w-1/4">
                                    <label className="">Postcode of property</label>
                                </div>

                                {/* Input + Button Row */}
                                <div className="flex flex-col flex-grow-1 gap-2 items-center">
                                    <div className="flex gap-2 w-full">
                                        <input
                                            type="text"
                                            name="postcode"
                                            value={formData.postcode}
                                            onChange={(e) => {
                                                const formatted = e.target.value.toUpperCase();
                                                setFormData((prev) => ({ ...prev, postcode: formatted }));
                                            }}
                                            placeholder="e.g. SE17 2LS"
                                            className="w-2/3 p-2 bg-gray-700 text-white rounded text-sm [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
                                        />
                                        <button
                                            type="button"
                                            onClick={fetchAddress}
                                            className="w-1/3 bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 text-sm rounded-md"
                                        >
                                            Find address
                                        </button>
                                    </div>
                                    {/* Address Selection List (Visible only if showAddressList is true) */}
                                    {showAddressList && addressData.length > 0 && (
                                        <div className="bg-white border border-gray-400 rounded-md shadow-md overflow-hidden w-full">
                                            <ul className="max-h-40 overflow-y-auto pl-0">
                                                {addressData.map((address, index) => (
                                                    <li
                                                        key={index}
                                                        className={`px-4 py-2 text-gray-800 cursor-pointer transition ${formData.address === address.snippet ? "bg-blue-500 text-white" : "hover:bg-gray-100"
                                                            }`}
                                                        onClick={() => setFormData((prev) => ({ ...prev, address: address.snippet }))}
                                                    >
                                                        {address.snippet}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {/* Confirm Address Button (Visible only when an address is selected) */}
                                    {formData.address && showAddressList && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSelectedAddress(formData.address); // Confirm selected address
                                                setShowAddressList(false); // Hide the address list
                                            }}
                                            className="mt-2 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 text-sm rounded-md me-auto"
                                        >
                                            Confirm address
                                        </button>
                                    )}

                                    {/* Selected Address Display (Visible only after confirming address) */}
                                    {selectedAddress && !showAddressList && (
                                        <div className="mt-2 text-white text-sm w-full">
                                            <p>{selectedAddress.split(", ")[0]}</p>
                                            <p>{selectedAddress.split(", ")[1]}</p>
                                            <p>{selectedAddress.split(", ")[2]}</p>
                                            <p>{selectedAddress.split(", ")[3]}</p>
                                            <p>{formData.postcode}</p>
                                            <p
                                                className="text-blue-600 cursor-pointer hover:underline mt-1"
                                                onClick={() => {
                                                    setSelectedAddress(""); // Clear selected address
                                                    setFormData((prev) => ({ ...prev, address: "" })); // Clear from formData
                                                    setShowAddressList(true); // Show address list again
                                                }}
                                            >
                                                Change
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Live in Landlord (Radio Buttons) */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">I am a</label>
                                <div className="w-3/4 flex gap-2">
                                    {[
                                        "Live in landlord",
                                        "Live out landlord",
                                        "Current tenant/flatmate",
                                        "Agent",
                                        "Former flatmate"
                                    ].map((option, index) => (
                                        <label key={index} className="flex items-center gap-2 text-sm text-white">
                                            <input
                                                type="radio"
                                                name="isLiveInLandlord"
                                                value={option}
                                                checked={formData.isLiveInLandlord === option}
                                                onChange={(e) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        isLiveInLandlord: e.target.value
                                                    }))
                                                }
                                                className=""
                                            />
                                            {option}
                                        </label>
                                    ))}
                                </div>
                            </div>


                            {/* Continue Button */}
                            <div className="mt-6 flex justify-end">
                                <button
                                    onClick={handleContinue}
                                    className="w-1/4 bg-blue-500 text-white text-sm font-bold py-2 rounded-md hover:bg-blue-600"
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {currentStep === 2 && (
                    <>
                        <h2 className="text-2xl font-semibold">More about the property</h2>
                        <div className="mt-4">
                            <h3 className="text-lg font-medium">Step 2 of 5</h3>
                            {/* Address of Property */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Address of property</label>
                                <p className="text-sm text-gray-400 m-0">{selectedAddress}</p>
                            </div>

                            {/* Area */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Area</label>
                                <select
                                    name="area"
                                    value={formData.area}
                                    onChange={handleChange}
                                    className="w-1/2 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Select area...</option>
                                    {/* Add more options as needed */}
                                </select>
                            </div>

                            {/* Transport */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Transport</label>
                                <select
                                    name="transport"
                                    value={formData.transport}
                                    onChange={handleChange}
                                    className="w-1/5 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Select...</option>
                                    <option>0-5</option>
                                    <option>5-10</option>
                                    <option>10-15</option>
                                    <option>15-20</option>
                                    <option>20-25</option>
                                    <option>25+</option>
                                    {/* Add more options as needed */}
                                </select>
                                <p className="text-sm m-0 text-gray-400">minutes</p>
                                <select
                                    name="transportTime"
                                    value={formData.transportTime}
                                    onChange={handleChange}
                                    className="w-1/5 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Select...</option>
                                    <option>walk</option>
                                    <option>by car</option>
                                    <option>by bus</option>
                                    <option>by tram</option>
                                    {/* Add more options as needed */}
                                </select>
                                <p className="text-sm m-0 text-gray-400">from</p>
                                <select
                                    name="transportFrom"
                                    value={formData.transportFrom}
                                    onChange={handleChange}
                                    className="w-1/5 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Select...</option>
                                    {/* Add more options as needed */}
                                </select>
                            </div>

                            {/* Living Room */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Living room?</label>
                                <input
                                    type="radio"
                                    name="livingRoom"
                                    value="yes"
                                    checked={formData.livingRoom === "yes"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes, there is a shared living room</label>
                                <input
                                    type="radio"
                                    name="livingRoom"
                                    value="no"
                                    checked={formData.livingRoom === "no"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm w-1/4">No</label>
                            </div>

                            {/* Amenities */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Amenities</label>
                                <div className="flex flex-wrap gap-2">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="parking"
                                            checked={formData.amenities.parking}
                                            onChange={handleChange}
                                            className="text-gray-900"
                                        />
                                        <span className="ml-2 text-sm">Parking</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="garden"
                                            checked={formData.amenities.garden}
                                            onChange={handleChange}
                                            className="text-gray-900"
                                        />
                                        <span className="ml-2 text-sm">Garden/patio</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="garage"
                                            checked={formData.amenities.garage}
                                            onChange={handleChange}
                                            className="text-gray-900"
                                        />
                                        <span className="ml-2 text-sm">Garage</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="balcony"
                                            checked={formData.amenities.balcony}
                                            onChange={handleChange}
                                            className="text-gray-900"
                                        />
                                        <span className="ml-2 text-sm">Balcony/roof terrace</span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="disabledAccess"
                                            checked={formData.amenities.disabledAccess}
                                            onChange={handleChange}
                                            className="text-gray-900"
                                        />
                                        <span className="ml-2 text-sm">Disabled access</span>
                                    </label>
                                </div>
                            </div>

                            {/* Continue Button */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={handleBack}
                                    className="w-1/4 bg-gray-500 text-white text-sm font-bold py-2 rounded-md hover:bg-gray-600"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleContinue}
                                    className="w-1/4 bg-blue-500 text-white text-sm font-bold py-2 rounded-md hover:bg-blue-600"
                                >
                                    Continue to next step
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {currentStep === 3 && (
                    <>
                        <h2 className="text-2xl font-semibold">The room</h2>
                        <div className="mt-4">
                            <h3 className="text-lg font-medium">Step 3 of 5</h3>
                            {/* Cost of Room */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Cost of room</label>
                                <div className="flex items-center w-1/4">
                                    <span className="text-sm me-2">£</span>
                                    <input
                                        type="number"
                                        name="costOfRoom"
                                        value={formData.costOfRoom}
                                        onChange={handleChange}
                                        className="w-full p-2 bg-gray-700 text-white rounded text-sm"
                                        step="0.01"
                                    />
                                </div>
                                <input
                                    type="radio"
                                    name="costOfRoomType"
                                    value="pcm"
                                    checked={formData.costOfRoomType === "pcm"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">per calendar month</label>
                                <input
                                    type="radio"
                                    name="costOfRoomType"
                                    value="pw"
                                    checked={formData.costOfRoomType === "pw"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">per week</label>
                            </div>

                            {/* Size of Room */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Size of room</label>
                                <input
                                    type="radio"
                                    name="sizeOfRoom"
                                    value="single"
                                    checked={formData.sizeOfRoom === "single"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Single</label>
                                <input
                                    type="radio"
                                    name="sizeOfRoom"
                                    value="double"
                                    checked={formData.sizeOfRoom === "double"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Double</label>
                            </div>

                            {/* En-suite */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Amenities</label>
                                <input
                                    type="checkbox"
                                    name="enSuite"
                                    checked={formData.enSuite}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">En-suite <span className="text-gray-400">(tick if room has own toilet and/or bath/shower)</span></label>
                            </div>

                            {/* Furnishings */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Furnishings</label>
                                <input
                                    type="radio"
                                    name="furnishings"
                                    value="furnished"
                                    checked={formData.furnishings === "furnished"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Furnished</label>
                                <input
                                    type="radio"
                                    name="furnishings"
                                    value="unfurnished"
                                    checked={formData.furnishings === "unfurnished"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Unfurnished</label>
                            </div>

                            {/* Security Deposit */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Security deposit</label>
                                <div className="flex items-center w-1/4">
                                    <span className="text-sm me-2">£</span>
                                    <input
                                        type="number"
                                        name="securityDeposit"
                                        value={formData.securityDeposit}
                                        onChange={handleChange}
                                        className="w-full p-2 bg-gray-700 text-white rounded text-sm"
                                        step="0.01"
                                    />
                                </div>
                            </div>

                            {/* Available From */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Available from</label>
                                <input
                                    type="date"
                                    name="availableFrom"
                                    value={formData.availableFrom}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                />
                            </div>

                            {/* Minimum Stay */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Minimum stay</label>
                                <select
                                    name="minimumStay"
                                    value={formData.minimumStay}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option value="">No minimum</option>
                                    {[
                                        "1 month", "2 months", "3 months", "4 months", "5 months", "6 months",
                                        "7 months", "8 months", "9 months", "10 months", "11 months", "1 year",
                                        "1 year 3 months", "1 year 6 months", "1 year 9 months", "2 years", "3 years"
                                    ].map((minduration, index) => (
                                        <option key={index} value={minduration}>{minduration}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Maximum Stay */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Maximum stay</label>
                                <select
                                    name="maximumStay"
                                    value={formData.maximumStay}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option value="">No maximum</option>
                                    {[
                                        "1 month", "2 months", "3 months", "4 months", "5 months", "6 months",
                                        "7 months", "8 months", "9 months", "10 months", "11 months", "1 year",
                                        "1 year 3 months", "1 year 6 months", "1 year 9 months", "2 years", "3 years"
                                    ].map((maxduration, index) => (
                                        <option key={index} value={maxduration}>{maxduration}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Short Term Lets */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Short term lets considered?</label>
                                <input
                                    type="checkbox"
                                    name="shortTermLets"
                                    checked={formData.shortTermLets}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm w-1/4">Tick for yes</label>
                            </div>

                            {/* Days Available */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Days available</label>
                                <select
                                    name="daysAvailable"
                                    value={formData.daysAvailable}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>7 days a week</option>
                                    <option>Mon to Fri only</option>
                                    <option>Weekends only</option>
                                </select>
                            </div>

                            {/* References Required */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">References required?</label>
                                <input
                                    type="radio"
                                    name="referencesRequired"
                                    value="yes"
                                    checked={formData.referencesRequired === "yes"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes</label>
                                <input
                                    type="radio"
                                    name="referencesRequired"
                                    value="no"
                                    checked={formData.referencesRequired === "no"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">No</label>
                            </div>

                            {/* Bills Included */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Bills included?</label>
                                <input
                                    type="radio"
                                    name="billsIncluded"
                                    value="yes"
                                    checked={formData.billsIncluded === "yes"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes</label>
                                <input
                                    type="radio"
                                    name="billsIncluded"
                                    value="no"
                                    checked={formData.billsIncluded === "no"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">No</label>
                                <input
                                    type="radio"
                                    name="billsIncluded"
                                    value="some"
                                    checked={formData.billsIncluded === "some"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Some</label>
                            </div>

                            {/* Broadband Included */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Broadband included?</label>
                                <input
                                    type="radio"
                                    name="broadbandIncluded"
                                    value="yes"
                                    checked={formData.broadbandIncluded === "yes"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes</label>
                                <input
                                    type="radio"
                                    name="broadbandIncluded"
                                    value="no"
                                    checked={formData.broadbandIncluded === "no"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">No</label>
                            </div>

                            {/* Continue Button */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={handleBack}
                                    className="w-1/4 bg-gray-500 text-white text-sm font-bold py-2 rounded-md hover:bg-gray-600"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleContinue}
                                    className="w-1/4 bg-blue-500 text-white text-sm font-bold py-2 rounded-md hover:bg-blue-600"
                                >
                                    Continue to next step
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {currentStep === 4 && (
                    <>
                        <h2 className="text-2xl font-semibold">Step 4 of 5</h2>
                        <div className="mt-4">
                            <h3 className="text-lg font-medium bg-gray-500 p-2 rounded-lg">The Existing Flatmate</h3>
                            {/* Smoking */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Smoking</label>
                                <select
                                    name="smoking"
                                    value={formData.smoking}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>No</option>
                                    <option>Yes</option>
                                </select>
                            </div>

                            {/* Gender */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Gender</label>
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* Occupation */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Occupation</label>
                                <select
                                    name="occupation"
                                    value={formData.occupation}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Not disclosed</option>
                                    <option>Student</option>
                                    <option>Professional</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* Pets */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Pets</label>
                                <select
                                    name="pets"
                                    value={formData.pets}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>No</option>
                                    <option>Yes</option>
                                </select>
                            </div>

                            {/* Age */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Age</label>
                                <input
                                    type="number"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                />
                            </div>

                            {/* Language */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Language</label>
                                <select
                                    name="language"
                                    value={formData.language}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>English</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* Nationality */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Nationality</label>
                                <select
                                    name="nationality"
                                    value={formData.nationality}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>Not disclosed</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* LGBT Household */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">LGBT household?</label>
                                <input
                                    type="checkbox"
                                    name="lgbtHousehold"
                                    checked={formData.lgbtHousehold}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes, one or more occupants identify as LGBT</label>
                            </div>

                            {/* Interests */}
                            {/* <div className="mt-4 flex items-center gap-2">
                            <label className="text-sm w-1/4">Interests</label>
                            <button className="w-1/4 bg-gray-300 hover:bg-gray-400 text-gray-700 p-1 text-sm rounded-md">
                                Add interests
                            </button>
                        </div> */}

                            <h3 className="text-lg font-medium mt-8 bg-gray-500 p-2 rounded-lg">Preferences For New Flatmate</h3>

                            {/* Smoking OK */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Smoking OK?</label>
                                <select
                                    name="smokingOk"
                                    value={formData.smokingOk}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>No preference</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>

                            {/* Gender */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Gender</label>
                                <select
                                    name="newGender"
                                    value={formData.newGender}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>No preference</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* Occupation */}
                            {/* Occupation (Radio Buttons) */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Occupation</label>
                                <div className="flex gap-2">
                                    {["Students only", "Not suitable for students", "Available to all"].map((option, index) => (
                                        <label key={index} className="flex items-center gap-2 text-sm text-white">
                                            <input
                                                type="radio"
                                                name="newOccupation"
                                                value={option}
                                                checked={formData.newOccupation === option}
                                                onChange={handleChange}
                                                className="text-gray-900"
                                            />
                                            {option}
                                        </label>
                                    ))}
                                </div>
                            </div>


                            {/* Pets Considered */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Pets considered</label>
                                <select
                                    name="newPets"
                                    value={formData.newPets}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>No</option>
                                    <option>Yes</option>
                                </select>
                            </div>

                            {/* Minimum Age */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Minimum age</label>
                                <input
                                    type="number"
                                    name="newMinAge"
                                    value={formData.newMinAge}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                />
                            </div>

                            {/* Maximum Age */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Maximum age</label>
                                <input
                                    type="number"
                                    name="newMaxAge"
                                    value={formData.newMaxAge}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                />
                            </div>

                            {/* Language */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Language</label>
                                <select
                                    name="newLanguage"
                                    value={formData.newLanguage}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm"
                                >
                                    <option>English</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            {/* Couples Welcome */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Couples welcome?</label>
                                <input
                                    type="radio"
                                    name="couplesWelcome"
                                    value="Yes"
                                    checked={formData.couplesWelcome === "Yes"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Yes</label>
                                <input
                                    type="radio"
                                    name="couplesWelcome"
                                    value="No"
                                    checked={formData.couplesWelcome === "No"}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">No</label>
                            </div>

                            {/* Other Preferences */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Other preferences</label>
                                <input
                                    type="checkbox"
                                    name="newLgbtPreferred"
                                    checked={formData.newLgbtPreferred}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">LGBT preferred</label>
                                <input
                                    type="checkbox"
                                    name="vegetarianPreferred"
                                    checked={formData.vegetarianPreferred}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Vegetarian/vegan preferred</label>
                            </div>

                            {/* Continue Button */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={handleBack}
                                    className="w-1/4 bg-gray-500 text-white text-sm font-bold py-2 rounded-md hover:bg-gray-600"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleContinue}
                                    className="w-1/4 bg-blue-500 text-white text-sm font-bold py-2 rounded-md hover:bg-blue-600"
                                >
                                    Continue to next step
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {currentStep === 5 && (
                    <>
                        <h2 className="text-2xl font-semibold">Step 5 of 5</h2>
                        <div className="mt-4">
                            <h3 className="text-lg font-medium bg-gray-500 p-2 rounded-lg">Your ad & contact details</h3>
                            {/* Ad Title */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Ad title</label>
                                <input
                                    type="text"
                                    name="adTitle"
                                    value={formData.adTitle}
                                    onChange={handleChange}
                                    className="w-full p-2 bg-gray-700 text-white rounded text-sm [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
                                    maxLength="100"
                                />
                            </div>

                            {/* Description */}
                            <div className="mt-4 mb-16 flex gap-2">
                                <label className="text-sm w-1/4">Description</label>
                                <ReactQuill
                                    value={formData.description}
                                    onChange={(value) => setFormData((prev) => ({ ...prev, description: value }))}
                                    className="w-full p-1 text-sm text-gray-900 rounded-md"
                                />
                            </div>

                            {/* Upload Photos */}
                            <div className="mt-6 flex gap-2">
                                <div className="text-sm w-1/4">
                                    <label>Upload photos</label>
                                </div>
                                <div className="flex flex-col flex-grow-1 gap-2 w-full">
                                    <div className="w-full">
                                        <input
                                            type="file"
                                            name="images"
                                            multiple
                                            accept="image/*"
                                            onChange={(e) => {
                                                const files = Array.from(e.target.files);
                                                const imageUrls = files.map(file => URL.createObjectURL(file));
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    images: imageUrls,
                                                }));
                                            }}
                                            className="hidden"
                                            id="upload-photos"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => document.getElementById('upload-photos').click()}
                                            className="w-1/4 bg-gray-500 text-white text-sm font-bold py-2 rounded-md hover:bg-gray-600"
                                        >
                                            Add up to 9 images
                                        </button>
                                    </div>
                                    <div className="mt-2 flex flex-wrap gap-2">
                                        {formData.images.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Upload ${index}`}
                                                className="w-24 h-24 object-cover rounded"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Name */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Your name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.name.firstName}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.name.lastName}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
                                />
                                <input
                                    type="checkbox"
                                    name="displayLastName"
                                    checked={formData.displayLastName}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Display last name on ad?</label>
                            </div>

                            {/* Telephone */}
                            <div className="mt-4 flex items-center gap-2">
                                <label className="text-sm w-1/4">Telephone</label>
                                <input
                                    type="text"
                                    name="telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                    className="w-1/4 p-2 bg-gray-700 text-white rounded text-sm [&:-webkit-autofill]:[-webkit-text-fill-color:white] [&:-webkit-autofill]:[transition-delay:9999s] [&:-webkit-autofill]:[-webkit-box-shadow:0_0_0px_1000px_#374151_inset]"
                                />
                                <input
                                    type="checkbox"
                                    name="displayTelephone"
                                    checked={formData.displayTelephone}
                                    onChange={handleChange}
                                    className="text-gray-900"
                                />
                                <label className="text-sm">Display with ad?</label>
                            </div>

                            {/* Submit Button */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={handleBack}
                                    className="w-1/4 bg-gray-500 text-white text-sm font-bold py-2 rounded-md hover:bg-gray-600"
                                >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    className="w-1/4 bg-blue-500 text-white text-sm font-bold py-2 rounded-md hover:bg-blue-600"
                                    disabled={loading}
                                >
                                    {loading ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export default AdvertiseRoomForm;