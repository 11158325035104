import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  EmailAuthProvider,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  linkWithPopup,
} from "firebase/auth";
import {
  doc,
  onSnapshot,
  setDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  limit,
  orderBy,
} from "firebase/firestore";
import { auth, firestore } from "../../firebase"; // Firebase config
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/loader1.json";
import toast from "react-hot-toast";

// Create the context
const AuthContext = createContext();

// Easy way to use the context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmationResultState, setConfirmationResultState] = useState(null);
  // --- Auth Functions ---
  const signUpWithEmailAndPassword = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signInWithEmailAndPasswordFn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const logout = async () => {
    return signOut(auth);
  };

  // --- Phone Authentication (OTP) ---
  // In AuthProvider.js - Modify your setupRecaptcha function
  // const setupRecaptcha = () => {
  //   try {
  //     // Clear existing reCAPTCHA if it exists
  //     if (window.recaptchaVerifier) {
  //       try {
  //         window.recaptchaVerifier.clear();
  //         window.recaptchaVerifier = null;
  //       } catch (error) {
  //         console.warn("Error clearing existing reCAPTCHA:", error);
  //       }
  //     }

  //     // Get or create the container
  //     let container = document.getElementById("recaptcha-container");
  //     if (!container) {
  //       container = document.createElement("div");
  //       container.id = "recaptcha-container";
  //       document.body.appendChild(container);
  //     } else {
  //       container.innerHTML = ""; // Clear existing content
  //     }

  //     // Create new verifier
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       auth,
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: () => {
  //           console.log("reCAPTCHA verified");
  //         },
  //         "expired-callback": () => {
  //           console.log("reCAPTCHA expired");
  //           if (window.recaptchaVerifier) {
  //             window.recaptchaVerifier.clear();
  //             window.recaptchaVerifier = null;
  //           }
  //         },
  //       }
  //     );

  //     return window.recaptchaVerifier;
  //   } catch (error) {
  //     console.error("RecaptchaVerifier error:", error);
  //     throw error;
  //   }
  // };

  // Inside your AuthProvider.js

  const signInWithPhone = async (phoneNumber, appVerifier) => {
    console.log("useAuth: signInWithPhone called with verifier:", appVerifier);

    // ***** ADD THIS CHECK *****
    if (!appVerifier || appVerifier.destroyed) {
      // Check if null/undefined OR already destroyed
      console.error(
        "useAuth: signInWithPhone called with an invalid or destroyed appVerifier."
      );
      // You could try to re-initialize here, but it's complex.
      // It's better to signal back that verification needs to be retried.
      throw new Error(
        "RecaptchaVerifier instance is invalid or destroyed. Please try again."
      );
    }
    // *************************

    const formattedNumber = phoneNumber.startsWith("+")
      ? phoneNumber
      : `+${phoneNumber}`;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedNumber,
        appVerifier
      );
      console.log(
        "useAuth: signInWithPhoneNumber successful, returning confirmationResult."
      );
      setConfirmationResultState(confirmationResult);
      return confirmationResult;
    } catch (error) {
      console.error(
        "useAuth: Error calling firebaseSignInWithPhoneNumber:",
        error
      );
      // Don't clear the verifier here; let the component manage it.
      throw error;
    }
  };

  const verifyOTP = async (otp) => {
    console.log(
      "Attempting to verify OTP with state:",
      confirmationResultState
    ); // Add log

    // ***** USE STATE VARIABLE *****
    if (!confirmationResultState) {
      console.error("verifyOTP error: confirmationResultState is missing.");
      throw new Error(
        "OTP verification session not found or expired. Please send OTP again."
      );
    }
    // ****************************

    try {
      // ***** USE STATE VARIABLE *****
      const result = await confirmationResultState.confirm(otp);
      // ****************************

      console.log("OTP Verification Successful, User:", result.user);
      setCurrentUser(result.user); // Update the main user state
      setConfirmationResultState(null); // Clear confirmation state after success
      // You might fetch userRole here or let the main useEffect handle it
      return result.user; // Return the user object
    } catch (error) {
      console.error("Error confirming OTP with Firebase:", error);
      // Don't clear confirmationResultState here, user might want to retry OTP entry
      // Let the calling component decide if session needs reset based on error code
      throw error; // Re-throw the specific Firebase error (e.g., auth/invalid-verification-code)
    }
  };

  // New function to link Google provider
  const linkGoogleProvider = async (user) => {
    const googleProvider = new GoogleAuthProvider();
    try {
      await linkWithPopup(user, googleProvider);
      toast.success("Google account linked successfully!");
    } catch (error) {
      if (error.code === "auth/credential-already-in-use") {
        toast.error("That Google account is already linked to another user.");
      } else if (error.code === "auth/provider-already-linked") {
        toast.error("Google is already linked to your account.");
      } else {
        console.error("Error linking Google:", error);
        toast.error("Failed to link Google. Please try again.");
      }
      throw error;
    }
  };

  const linkEmailToPhoneAccount = async (
    user,
    email,
    name,
    phone,
    password
  ) => {
    try {
      if (!email) {
        throw new Error("Email cannot be empty.");
      }

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("No user currently logged in.");
      }

      // Create email credential
      const credential = EmailAuthProvider.credential(email, password);

      // Link the email credential to the phone auth user
      await linkWithCredential(currentUser, credential);

      // Update user document in Firestore
      await setDoc(doc(firestore, "users", currentUser.uid), {
        userId: currentUser.uid,
        name: name,
        email: email,
        phone: phone,
        role: "student",
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error linking email:", error);
      if (error.code === "auth/requires-recent-login") {
        toast.error("Please sign in again to complete this action.");
      } else if (error.code === "auth/email-already-in-use") {
        toast.error("This email is already associated with another account.");
      } else {
        toast.error("Failed to link email. Please try again.");
      }
      throw error;
    }
  };

  // Using Firestore query to check phone existence
  const checkPhoneNumberExists = async (phoneNumber) => {
    try {
      // Validate phone number format first
      if (!phoneNumber || !/^\+[1-9]\d{1,14}$/.test(phoneNumber)) {
        throw new Error("invalid-phone-format");
      }

      // Simplified query without orderBy
      const usersRef = collection(firestore, "users");
      const q = query(usersRef, where("phone", "==", phoneNumber), limit(1));

      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking phone:", error);
      if (error.message === "invalid-phone-format") {
        throw new Error("Please enter a valid phone number with country code");
      }
      if (error.code === "permission-denied") {
        throw new Error("Access denied. Please try again.");
      }
      throw new Error("Failed to check phone number. Please try again");
    }
  };
  const sendEmailVerificationLink = async (formdata) => {
    const actionCodeSettings = {
      url: "http://localhost:3000/email-verification", // Change to your deployed URL
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, formdata.email, actionCodeSettings);

      // ✅ Store both email and name for later verification
      localStorage.setItem("emailForSignIn", formdata.email);
      localStorage.setItem("nameForSignUp", formdata.name);

      toast.success(`Verification link sent to ${formdata.email}`);
    } catch (error) {
      console.error("Error sending email link:", error);
      toast.error("Failed to send verification link. Try again.");
    }
  };

  // ✅ 2️⃣ Verify Email Link & Sign In
  // const verifyEmailLink = async () => {
  //   if (isSignInWithEmailLink(auth, window.location.href)) {
  //     let email = localStorage.getItem("emailForSignIn");
  //     let name = localStorage.getItem("nameForSignUp"); // ✅ Get stored name

  //     if (!email) {
  //       console.error("No email found in localStorage.");
  //       toast.error("Verification failed. Try again.");
  //       return;
  //     }

  //     try {
  //       const result = await signInWithEmailLink(
  //         auth,
  //         email,
  //         window.location.href
  //       );
  //       localStorage.removeItem("emailForSignIn"); // Cleanup storage
  //       localStorage.removeItem("nameForSignUp"); // ✅ Cleanup name after use

  //       setCurrentUser(result.user); // Update user state

  //       // ✅ Create user in Firestore with stored name
  //       const userDocRef = doc(firestore, "users", result.user.uid);
  //       await setDoc(userDocRef, {
  //         userId: result.user.uid,
  //         name: name, // ✅ Use stored name
  //         email: result.user.email,
  //         phoneNumber: null, // Since email signup doesn't use phone
  //         role: "student",
  //         createdAt: serverTimestamp(),
  //       });

  //       toast.success("Email verified! Account created successfully.");
  //       return result.user;
  //     } catch (error) {
  //       console.error("Verification error:", error);
  //       toast.error("Failed to verify email. Try again.");
  //     }
  //   }
  // };

  // --- Watch authentication state ---
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const userDocRef = doc(firestore, "users", user.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUserData(data);
            setUserRole(data.role || null);
          } else {
            setUserData(null);
            setUserRole(null);
          }
          setLoading(false);
        });

        return () => unsubscribeUserDoc();
      } else {
        setCurrentUser(null);
        setUserData(null);
        setUserRole(null);
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (currentUser && userRole && userData) {
      const path = window.location.pathname;
      const isAdminRoute = path.startsWith("/business");
      const isStudentRoute = path.startsWith("/user-dashboard");

      if (
        (isAdminRoute && userRole !== "admin") ||
        (isStudentRoute && userRole !== "student")
      ) {
        logout();
      }
    }
  }, [currentUser, userRole, userData]);

  useEffect(() => {
    return () => {
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        } catch (error) {
          console.warn("Error cleaning up reCAPTCHA:", error);
        }
      }
    };
  }, []);
  // Everything we want to provide via context
  const value = {
    currentUser,
    userRole,
    userData,
    loading,

    // Auth functions
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword: signInWithEmailAndPasswordFn,
    signInWithGoogle,
    resetPassword,
    logout,
    signInWithPhone,
    verifyOTP,
    sendEmailVerificationLink, // Send verification link
    // verifyEmailLink, // Verify email link
    linkEmailToPhoneAccount,
    checkPhoneNumberExists,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <div className="flex items-center justify-center h-screen bg-gray-800">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={200}
            width={200}
          />
        </div>
      ) : (
        <>
          <div id="recaptcha-container"></div> {/* Needed for Phone Auth */}
          {children}
        </>
      )}
    </AuthContext.Provider>
  );
};
